<div *ngIf="active" class="spinner-container" [class.spinner-overlay]="overlay">
  <ng-content *ngIf="showContent"></ng-content>
  <mat-progress-spinner
    [diameter]="diameter"
    [strokeWidth]="strokeWidth"
    [color]="colour"
    class="spinner"
    [ngClass]="{ 'content': showContent }"
    mode="indeterminate"
  ></mat-progress-spinner>
</div>
