import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FormField } from '../../models/form-field';
import { InputType, OptionInputType } from '../../models/input-type.enum';
import { MonekColour } from '../../models/monek-colour.enum';
import { DatePipe } from '@angular/common';
import { FormOption } from '../../models/form-option';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'odin-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.scss'],
  providers: [],
})
export class DynamicInputComponent {
  @Input() public theme: MonekColour = MonekColour.Primary;
  @Input() public errorTheme: MonekColour = MonekColour.Warn;
  @Input() public formField?: FormField;
  @Input() public disabledInput: boolean = false;
  @Output() public valueChanged: EventEmitter<
    number | string | Date | boolean | null
  > = new EventEmitter<number | string | Date | boolean | null>();

  private cleanInput = true;
  public dateInput = new Date();

  constructor(private datePipe: DatePipe) {}

  public InputMode(): string {
    if (!this.formField) return 'text';
    if (this.formField.type == InputType.Number) return 'numeric';

    return 'text';
  }

  public SetValue(option: FormOption): void {
    if (this.formField === undefined) return;
    this.formField.value = option.value;
    this.CheckValidation();
    this.formField.onBlur(this.formField, option.value);
  }

  public DropdownChange(change: MatSelectChange): void {
    if (this.formField === undefined) return;
    this.formField.value = change.value;
    this.CheckValidation();
    this.formField.onBlur(this.formField, change.value);
  }

  public CheckValidation(): void {
    this.cleanInput = false;
    this.valueChanged.emit();
    if (!this.formField) return;
    this.formField.onChange(
      this.formField.value,
      this.formField.data,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data: any) => this.UpdateData(data),
      (val: string | number | boolean | Date | null) => this.UpdateVal(val),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private UpdateData(data: any): void {}

  private UpdateVal(data: string | number | boolean | Date | null): void {}

  public Validate(): boolean {
    if (this.cleanInput) return true;
    if (this.formField == null || typeof this.formField.value !== 'string')
      return false;
    const validationResult = this.formField.validation.Validate(
      this.formField.value,
    );
    return validationResult.validationOutcome;
  }

  public GetValidationErrors(): string {
    if (this.formField == null || typeof this.formField.value !== 'string')
      return '';
    const errors = this.formField.validation.Errors(this.formField.value);
    if (errors == null || errors.length == 0) return 'Invalid';
    return errors[0];
  }

  public isDropdown(inputType: InputType | OptionInputType): boolean {
    if (inputType == OptionInputType.Dropdown)
      return true;
    return false;
  }

  public isOptionInput(inputType: InputType | OptionInputType): boolean {
    if (
      inputType == OptionInputType.Checkbox ||
      inputType == OptionInputType.Dropdown ||
      inputType == OptionInputType.Radio
    )
      return true;
    return false;
  }

  public isDateInput(inputType: InputType | OptionInputType): boolean {
    if (
      inputType == InputType.Week ||
      inputType == InputType.Month ||
      inputType == InputType.MonthYear ||
      inputType == InputType.Date ||
      inputType == InputType.DateTimeLocal
    )
      return true;
    return false;
  }

  private dateFormat(inputType: InputType): string {
    switch (inputType) {
      case InputType.MonthYear:
        return 'MMM YYYY';

      default:
        return 'DD MM YYYY';
    }
  }

  // Custom DatePicker functionality
  chosenYearHandler(normalizedYear: Moment) {
    this.dateInput.setFullYear(moment(normalizedYear).year());
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>,
  ) {
    if (
      !this.formField ||
      this.formField.optionInput ||
      this.formField.type === OptionInputType.Radio ||
      this.formField.type === OptionInputType.Checkbox ||
      this.formField.type === OptionInputType.Dropdown
    ) {
      datepicker.close();
      return;
    }
    this.dateInput.setMonth(moment(normalizedMonth).month());
    this.formField.value = this.datePipe.transform(
      this.dateInput,
      this.dateFormat(this.formField.type),
    );
    this.CheckValidation();
    datepicker.close();
  }
}
