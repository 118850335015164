import { NgModule } from '@angular/core';
import { RelativeTimePipe } from './relative-time-helper';
import { CurrencySymbolPipe } from './currency-symbol/currency.symbol.pipe';
import { DividePipe } from './divide/divide.pipe';

@NgModule({
  imports: [],
  declarations: [DividePipe, CurrencySymbolPipe, RelativeTimePipe],
  exports: [DividePipe, CurrencySymbolPipe, RelativeTimePipe],
})
export class CorePipesModule {}
