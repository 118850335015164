import { createAction, props } from "@ngrx/store";
import { ISettlement, ITransactionSettled } from "../../transactions-layout/models";

export enum SettlementsActionTypes {
    LoadSettlements = '[Settlements] Load Daily Settlements',
    SettlementsLoadedSuccess = '[Settlements] Settlements Loaded Success',
    SettlementsLoadFail = '[Settlements] Settlements Load Failure',
    LoadMore = '[Settlements] Load More Settlements, Increase the Page',
    LoadMoreSuccess = '[Settlements] Load More Settlements Success',
    DailySettlementsPagination = '[Settlements] Daily Settlements Pagination',
    SettledDate = '[Settlements] Settled Date',
    LoadSettledTransactions = '[Settlements] Load Settled Transactions',
    LoadMoreSettledTransactions = '[Settlements] Load More Settled Transactions, Increase the Page',
    SettledTransactionsLoadedSuccess = '[Settlements] Settled Transactions Loaded Success',
    LoadMoreSettledTransactionsSuccess = '[Settlements] Load More Settled Transactions Success',
    SettledTransactionsLoadFailure = '[Settlements] Settled Transactions Load Failure',
    LoadMoreSettledTransactionsFailure = '[Settlements] More Settled Transactions Load Failure',
    SettledTransactionsPagination = '[Settlements] Settled Transactions Pagination',
    UpdateMerchantRef = '[Settlements] Update Merchant Ref',
    GetDailySettlementReport = '[Settlements] Get Daily Settlement Report',
    GetDailySettlementReportSuccess = '[Settlements] Get Daily Settlement Report - Success',
    GetDailySettlementReportFailure = '[Settlements] Get Daily Settlement Report - Failure',
    GetMonthlySettlementReport = '[Settlements] Get Monthly Settlement Report',
    GetMonthlySettlementReportSuccess = '[Settlements] Get Monthly Settlement Report - Success',
    GetMonthlySettlementReportFailure = '[Settlements] Get Monthly Settlement Report - Failure',
    GetMonthlySettlementReportCSV = '[Settlements] Get Monthly Settlement Report CSV',
    GetMonthlySettlementReportCSVSuccess = '[Settlements] Get Monthly Settlement Report CSV - Success',
    GetMonthlySettlementReportCSVFailure = '[Settlements] Get Monthly Settlement Report CSV - Failure',
    GetDailySettlementReportCSV = '[Settlements] Get Daily Settlement Report CSV',
    GetDailySettlementReportCSVSuccess = '[Settlements] Get Daily Settlement Report CSV - Success',
    GetDailySettlementReportCSVFailure = '[Settlements] Get Daily Settlement Report CSV - Failure',
    SettlementsScrollPosition = '[Settlements] Set Scroll Position'
};

export const loadSettlements = createAction(
    SettlementsActionTypes.LoadSettlements
);

export const loadMore = createAction(SettlementsActionTypes.LoadMore);

export const loadSettlementsSuccess = createAction(
    SettlementsActionTypes.SettlementsLoadedSuccess,
    props<{ settlements: ISettlement[] }>()
);

export const loadMoreSuccess = createAction(
    SettlementsActionTypes.LoadMoreSuccess,
    props<{ settlements: ISettlement[] }>()
);

export const loadSettlementsFailure = createAction(
    SettlementsActionTypes.SettlementsLoadFail,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

export const saveDailySettlementsPagination = createAction(
    SettlementsActionTypes.DailySettlementsPagination,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ pagination: any }>()
);

export const saveSettledDate = createAction(
    SettlementsActionTypes.SettledDate,
    props<{ settledDate: string }>()
);

export const loadSettledTransactions = createAction(
    SettlementsActionTypes.LoadSettledTransactions
);

export const loadMoreSettledTransactions = createAction(
    SettlementsActionTypes.LoadMoreSettledTransactions
);

export const loadSettledTransactionsSuccess = createAction(
    SettlementsActionTypes.SettledTransactionsLoadedSuccess,
    props<{ transactions: ITransactionSettled[] }>()
);

export const loadMoreSettledTransactionsSuccess = createAction(
    SettlementsActionTypes.LoadMoreSettledTransactionsSuccess,
    props<{ transactions: ITransactionSettled[] }>()
);

export const loadSettledTransactionsFailure = createAction(
    SettlementsActionTypes.SettledTransactionsLoadFailure,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

export const loadMoreSettledTransactionsFailure = createAction(
    SettlementsActionTypes.LoadMoreSettledTransactionsFailure,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

export const saveSettledTransactionsPagination = createAction(
    SettlementsActionTypes.SettledTransactionsPagination,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ pagination: any }>()
);

export const saveMerchantRef = createAction(
    SettlementsActionTypes.UpdateMerchantRef,
    props<{ merchantRef: string }>()
);

// Get Daily Settlements

export const getDailySettlementReports = createAction(
    SettlementsActionTypes.GetDailySettlementReport,
    props<{ date: string }>()
);

export const getDailySettlementReportSuccess = createAction(
    SettlementsActionTypes.GetDailySettlementReportSuccess
);
  
export const getDailySettlementReportFailure = createAction(
    SettlementsActionTypes.GetDailySettlementReportFailure,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

// Get Monthly Settlements

export const getMonthlySettlementReports = createAction(
    SettlementsActionTypes.GetMonthlySettlementReport,
    props<{ date: Date }>()
);

export const getMonthlySettlementReportSuccess = createAction(
    SettlementsActionTypes.GetMonthlySettlementReportSuccess
);
  
export const getMonthlySettlementReportFailure = createAction(
    SettlementsActionTypes.GetMonthlySettlementReportFailure,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

// Get Monthly Settlements CSV

export const getMonthlySettlementReportsCSV = createAction(
    SettlementsActionTypes.GetMonthlySettlementReportCSV,
    props<{ date: Date }>()
);

export const getMonthlySettlementReportCSVSuccess = createAction(
    SettlementsActionTypes.GetMonthlySettlementReportCSVSuccess
);
  
export const getMonthlySettlementReportCSVFailure = createAction(
    SettlementsActionTypes.GetMonthlySettlementReportCSVFailure,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

export const getDailySettlementReportsCSV = createAction(
    SettlementsActionTypes.GetDailySettlementReportCSV,
    props<{ date: Date }>()
);

export const getDailySettlementReportCSVSuccess = createAction(
    SettlementsActionTypes.GetDailySettlementReportCSVSuccess
);
  
export const getDailySettlementReportCSVFailure = createAction(
    SettlementsActionTypes.GetDailySettlementReportCSVFailure,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

// Settlements Scroll Position

export const settlementsScrollPosition = createAction(
    SettlementsActionTypes.SettlementsScrollPosition,
    props<{ scrollPosition: number }>()
)