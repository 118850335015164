import {
  ITransaction,
  TransactionFiltersInitial,
  TransactionFiltersParams,
} from '../../transactions-layout/models';

/* Initial transactions State */
const PAGE = 1;
const PER_PAGE = 50;

export interface TransactionState {
  merchantRef: string;
  params: TransactionFiltersParams;
  transactions: ITransaction[];
  moreTransactions: ITransaction[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  loaded: boolean;
  allowSelection: boolean;
  pagination: {
    hasNext: boolean; // is a next page?
    hasPrev: boolean; // is previous page?
    pageCount: number; // no of transactions from the last request
    pageSize: number; // maximum no of transactions per page
    currentPage: number; // current page number
    totalPages: number; // total no of pages
    totalCount: number; // total no of transactions matching the filters
  };
  csvReportLoading: boolean;
  csvReportError: string;
}

export const initialState: TransactionState = {
  merchantRef: '',
  params: new TransactionFiltersParams(
    PAGE,
    PER_PAGE,
    '',
    new TransactionFiltersInitial()
  ),
  transactions: [],
  moreTransactions: [],
  error: '',
  loaded: false,
  allowSelection: true,
  pagination: {
    hasNext: false,
    hasPrev: false,
    pageCount: 0,
    pageSize: PER_PAGE,
    currentPage: 0,
    totalPages: 0,
    totalCount: 0,
  },
  csvReportError: '',
  csvReportLoading: false
};
