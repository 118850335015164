<div class="py-3" *ngIf="config">
  <div class="px-2">
    <h1 class="mb-2">{{ config.title }}</h1>
    <p class="mb-0">{{ config.subHeading }}</p>
  </div>
  <div class="px-2 mt-2">
    <mat-form-field class="dynamic form-field w-100" appearance="outline">
      <input
        matInput
        [(ngModel)]="value"
        [placeholder]="config.placeholder"
        [readonly]="config.readonly"
      />
    </mat-form-field>
  </div>
  <div class="px-2 d-flex align-items-center justify-content-end gap-2">
    <button
      *ngIf="config.allowCopy"
      mat-stroked-button
      color="primary"
      (click)="copyText()"
    >
      Copy text
    </button>
    <button mat-stroked-button color="warn" [mat-dialog-close]>
      Close
    </button>
    <button
      mat-flat-button
      color="primary"
      cdkFocusInitialf
      (click)="Close()"
    >
      Continue
    </button>
  </div>
</div>
