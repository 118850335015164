import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  loadMore,
  loadMoreSettledTransactions,
  loadSettledTransactions,
  loadSettlements,
  saveMerchantRef,
  saveSettledDate,
  saveSettledTransactionsPagination,
  getDailySettlementReports,
  getMonthlySettlementReports,
  getMonthlySettlementReportsCSV,
  getDailySettlementReportsCSV,
  saveDailySettlementsPagination,
  settlementsScrollPosition
} from './settlements.actions';
import { SettlementState } from './settlements.init';
import { settlementsQuery } from './settlements.selectors';

@Injectable({ providedIn: 'root' })
export class SettlementsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */

  getMerchantRef$ = this.store.pipe(select(settlementsQuery.getMerchantRef));

  // Daily Settlements //

  getSettlementsLoader$ = this.store.pipe(
    select(settlementsQuery.getSettlementsLoader)
  );

  loadSettlements$ = this.store.pipe(select(settlementsQuery.getSettlements));

  loadMoreSettlements$ = this.store.pipe(
    select(settlementsQuery.getMoreSettlements)
  );

  getError$ = this.store.pipe(select(settlementsQuery.getSettlementsError));

  getAllowSelection$ = this.store.pipe(
    select(settlementsQuery.getSettlementsAllowSelection)
  );

  getHasNext$ = this.store.pipe(select(settlementsQuery.getHasNext));

  getHasPrev$ = this.store.pipe(select(settlementsQuery.getHasPrev));

  noMoreToLoad$ = this.store.pipe(select(settlementsQuery.noMoreToLoad));

  // return page and take
  getQueryParams$ = this.store.pipe(select(settlementsQuery.getQueryParams));

  // Settled Transactions //

  loadSettledTransactions$ = this.store.pipe(
    select(settlementsQuery.getSettledTransactions)
  );

  loadMoreSettledTransactions$ = this.store.pipe(
    select(settlementsQuery.getMoreSettledTransactions)
  );

  getSettledTransactionsError$ = this.store.pipe(
    select(settlementsQuery.getSettledTransactionsError)
  );

  getSettledTransactionsHasNext$ = this.store.pipe(
    select(settlementsQuery.getSettledTransactionsHasNext)
  );

  getSettledTransactionsHasPrev$ = this.store.pipe(
    select(settlementsQuery.geSettledTransactionsHasPrev)
  );

  noMoreSettledTransactionsToLoad$ = this.store.pipe(
    select(settlementsQuery.noMoreSettledTransactionsToLoad)
  );

  getSettledDate$ = this.store.pipe(select(settlementsQuery.getSettledDate));

  getSettledTransactionsParams$ = this.store.pipe(
    select(settlementsQuery.getSettledTransactionsParams)
  );

  getMonthlySettlementReportsLoader$ = this.store.pipe(select(settlementsQuery.getMonthlySettlementReportsLoader));

  getDailySettlementReportsLoader$ = this.store.pipe(select(settlementsQuery.getDailySettlementReportsLoader));
  
  getScrollPosition$ = this.store.pipe(select(settlementsQuery.getScrollPosition))

  constructor(private readonly store: Store<SettlementState>) {}

  updateMerchantRef(mid: string, refreshData: boolean) {
    this.store.dispatch(saveMerchantRef({ merchantRef: mid }));

    // refresh settlements only if user is on settlements page
    if (refreshData) this.store.dispatch(loadSettlements());
  }

  // dispatch an action that calls the service
  getSettlements() {
    this.store.dispatch(loadSettlements());
  }

  // dispatch an action that calls the service
  loadMoreSettlements() {
    this.store.dispatch(loadMore());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveDailyPagination(pagination: any) {
    this.store.dispatch(saveDailySettlementsPagination({ pagination }));
  }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveSettledTransactionsPagination(pagination: any) {
    this.store.dispatch(saveSettledTransactionsPagination({ pagination }));
  }

  saveSelectedSettledDate(settledDate: string) {
    this.store.dispatch(saveSettledDate({ settledDate }));
  }

  getSettledTransactions() {
    this.store.dispatch(loadSettledTransactions());
  }

  loadMoreSettledTransactions() {
    this.store.dispatch(loadMoreSettledTransactions());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveSettledTransactionsPaginationValues(pagination: any) {
    this.store.dispatch(saveSettledTransactionsPagination({ pagination }));
  }

  // Get The Daily Settlement Report From S3 //
  getDailySettlementReport(date: string) {
    this.store.dispatch(getDailySettlementReports({ date }))
  }

  // Get The Monthly Settlement Report From S3 //
  getMonthlySettlementReport(date: Date) {
    this.store.dispatch(getMonthlySettlementReports({ date }))
  }

  getMonthlySettlementReportCSV(date: Date) {
    this.store.dispatch(getMonthlySettlementReportsCSV({ date }))
  }

  getDailySettlementReportCSV(date: Date) {
    this.store.dispatch(getDailySettlementReportsCSV({ date }))
  }

  settlementsScrollPosition(scrollPosition: number): void {
    this.store.dispatch(settlementsScrollPosition({ scrollPosition}))
  }
   
}
