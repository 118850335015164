<mat-drawer-container [hasBackdrop]="tabletQuery.matches" class="page-height">
  <mat-drawer
    #drawer
    [mode]="tabletQuery.matches ? 'over' : 'side'"
    [position]="'end'"
    [opened]="!tabletQuery.matches"
    [disableClose]="!tabletQuery.matches"
  ></mat-drawer>
  <mat-drawer-content
    class="overflow-hidden ml-0 bg-panel page-height d-flex justify-content-between align-items-center flex-column p-md-2"
  >
    <!-- Settlements list header -->
    <mat-divider></mat-divider>
    <div
      class="d-flex justify-content-between align-items-center flex-row py-3 px-4 w-100 shadow main-panel"
      style="z-index: 10"
    >
      <odin-settlements-toggle
        (updateSettlementSelection)="onChangeSettlementType($event)"
      ></odin-settlements-toggle>
    </div>

    <!-- Settlements list -->
    <div class="flex-fill w-100">
      <div
        *ngIf="error$ | async; else settlements_content"
        class="error-settlements"
      >
        Unable to load settlements
        <!-- TODO: Better error message? -->
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<ng-template #settlements_content>
  <div class="h-100" *ngIf="settlements$ | async as settlements" @fadeAnimation>
    <ng-container *ngIf="monthlySettlementSelected">
      <odin-settlements-monthly-list
        (newMonthlySettlement)="onNewMonthlySettlementReport($event)"
        (newMonthlySettlementCSV)="onNewMonthlySettlementReportCSV($event)"
      ></odin-settlements-monthly-list>
    </ng-container>

    <ng-container *ngIf="settlements.length > 0 && dailySettlementSelected">
      <odin-settlements-list
        class="d-block h-100"
        [settlements]="settlements"
        [allowSelection]="allowSelection$ | async"
        [hasNext]="hasNext$ | async"
        [hasPrev]="hasPrev$ | async"
        [loading]="loading$ | async"
        (navigateToDetails)="navigateToDetails($event)"
        (scrollDirection)="loadSettlementsOnScroll($event)"
      >
        <ng-container *ngIf="settlements.length === 0 && (loading$ | async) === false">
          <p class="settlements-bottom">No statements found!</p>
        </ng-container>
        <ng-container *ngIf="noMoreToLoad$ | async">
          <div class="">
            <div style="max-width: 300px" class="w-50 mx-auto p-1">
              <p class="mb-0 w-100 text-center">No more settlements to load.</p>
            </div>
          </div>
        </ng-container>
      </odin-settlements-list>
    </ng-container>
    <ng-container *ngIf="settlements.length === 0 && (loading$ | async) === false">
      <p>No statements found!</p>
    </ng-container>
    <ng-container *ngIf="loading$ | async">
      <odin-loading-spinner
        class="mt-2"
        [active]="true"
        [diameter]="40"
        [colour]="colour"
      ></odin-loading-spinner>
    </ng-container>
  </div>
</ng-template>
