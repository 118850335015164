export class TransactionFilters {
  minDate?: Date;
  maxDate?: Date;
  minAmount?: number;
  maxAmount?: number;
  channel?: string;
  status?: string;

  private defaultDecimals = 2;

  constructor(
    minDate?: Date,
    maxDate?: Date,
    minAmount?: number,
    maxAmount?: number,
    channel?: string,
    status?: string
  ) {
    this.minAmount = minAmount;
    this.maxAmount = maxAmount;
    this.minDate = minDate;
    this.maxDate = maxDate;
    this.channel = channel;
    this.status = status;
  }

  convertToMajorCurrency(decimals?: number) {
    if (decimals) this.defaultDecimals = this.checkRange(decimals);

    if (this.minAmount)
      this.minAmount = this.minAmount / Math.pow(10, this.defaultDecimals);
    if (this.maxAmount)
      this.maxAmount = this.maxAmount / Math.pow(10, this.defaultDecimals);
  }

  convertToMinorCurrency(decimals?: number) {
    if (decimals) this.defaultDecimals = this.checkRange(decimals);

    if (this.minAmount)
      this.minAmount = parseFloat(
        (this.minAmount * Math.pow(10, this.defaultDecimals)).toFixed(0)
      );
    if (this.maxAmount)
      this.maxAmount = parseFloat(
        (this.maxAmount * Math.pow(10, this.defaultDecimals)).toFixed(0)
      );
  }

  private checkRange(x: number) {
    const values = [0, 1, 2, 3];

    if (values.includes(x)) return x;

    return this.defaultDecimals;
  }
}
