import { Injectable } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

@Injectable({ providedIn: 'root' })
export class MatchMediaService {
  constructor(private media: MediaMatcher) {}

  public matchMedia(width: string): MediaQueryList {
    return this.media.matchMedia(`(max-width: ${width})`);
  }

  public isTouch(): boolean {
    return this.media.matchMedia('(hover: none), (pointer: coarse)').matches;
  }
}
