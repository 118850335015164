import { createAction, props } from '@ngrx/store';
import {
  CsvReportRequest,
  ITransaction,
  TransactionFiltersInitial,
  TransactionFiltersParams,
} from '../../transactions-layout/models';

export enum TransactionsActionTypes {
  LoadTransactions = '[Transactions] Load Transactions',
  TransactionsLoadedSuccess = '[Transactions] Transactions Loaded Success',
  TransactionsLoadFail = '[Transactions] Transactions Load Failure',
  TransactionsFilters = '[Transactions] Filters',
  TransactionsSearchValue = '[Transactions] Search Value',
  TransactionsParams = '[Transactions] params',
  TransactionsAllowSelection = '[Transactions] Allow Selection',
  LoadMore = '[Transactions] Load More Transactions, Increase the Page',
  LoadMoreSuccess = '[Transactions] Load More Transactions Success',
  Pagination = '[Transactions] Pagination',
  UpdateMerchantRef = '[Transactions] Update Merchant Ref',
  DownloadCsvReport = '[Transactions] Download CSV Report',
  DownloadCsvReportSuccess = '[Transactions] Download CSV Report Success',
  DownloadCsvReportFailure = '[Transactions] Download CSV Report Failure',
  ClearCsvReportError = '[Transactions] Clear Download CSV Report Error'
}

export const transactionsFilters = createAction(
  TransactionsActionTypes.TransactionsFilters,
  props<{ filters: TransactionFiltersInitial }>()
);

export const transactionsSearchValue = createAction(
  TransactionsActionTypes.TransactionsSearchValue,
  props<{ searchValue: string }>()
);

export const transactionsParams = createAction(
  TransactionsActionTypes.TransactionsParams,
  props<{ params: TransactionFiltersParams }>()
);

export const loadTransactions = createAction(
  TransactionsActionTypes.LoadTransactions
);

export const loadMore = createAction(TransactionsActionTypes.LoadMore);

export const loadTransactionsSuccess = createAction(
  TransactionsActionTypes.TransactionsLoadedSuccess,
  props<{ transactions: ITransaction[] }>()
);

export const loadMoreSuccess = createAction(
  TransactionsActionTypes.LoadMoreSuccess,
  props<{ transactions: ITransaction[] }>()
);

export const loadTransactionsFailure = createAction(
  TransactionsActionTypes.TransactionsLoadFail,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>()
);

export const transactionsAllowSelection = createAction(
  TransactionsActionTypes.TransactionsAllowSelection,
  props<{ allowSelection: boolean }>()
);

export const savePagination = createAction(
  TransactionsActionTypes.Pagination,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ pagination: any }>()
);

export const saveMerchantRef = createAction(
  TransactionsActionTypes.UpdateMerchantRef,
  props<{ merchantRef: string }>()
)

export const downloadCsvReport = createAction(
  TransactionsActionTypes.DownloadCsvReport,
  props<{ csvReportRequest: CsvReportRequest }>()
);

export const downloadCsvReportSuccess = createAction(
  TransactionsActionTypes.DownloadCsvReportSuccess
);

export const downloadCsvReportFailure = createAction(
  TransactionsActionTypes.DownloadCsvReportFailure,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>()
);

export const clearCsvReportError = createAction(
  TransactionsActionTypes.ClearCsvReportError
);