import { Component, Output, OnInit } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { SettlementsFacade } from '../../../+state/settlements/settlements.facade';

@Component({
  selector: 'odin-settlement-daily-report-download',
  templateUrl: './settlement-daily-report-download.component.html',
  styleUrls: ['./settlement-daily-report-download.component.scss'],
})
export class SettlementDailyReportDownloadComponent implements OnInit {
  @Output() newDailySettlement = new EventEmitter();
  @Output() newDailySettlementCSV = new EventEmitter();

  loading$!: Observable<boolean>;

  constructor(private facade: SettlementsFacade) {}

  ngOnInit() { 
    this.loading$ = this.facade.getDailySettlementReportsLoader$;
  }

  downloadDailySettlementReport() {
    this.newDailySettlement.emit();
  }

  downloadDailySettlementReportCSV() {
    this.newDailySettlementCSV.emit();
  }
}
