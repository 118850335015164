import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { TransactionsLayoutRoutingModule } from './transactions-layout-routing.module';
import { TransactionsLayoutComponent } from './transactions-layout.component';
import { TransactionsListComponent } from './components/transactions-list/transactions-list.component';
import { TransactionDetailsComponent } from './components/transaction-details/transaction-details.component';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import {
  RouterExtService,
  SettlementsService,
  TransactionFormatterService,
  TransactionsService,
} from './services';
import { TransactionsListPageComponent } from './transactions-list-page/transactions-list-page.component';
import { MatStepperModule } from '@angular/material/stepper';
import { TransactionsFiltersModule } from './components/filters/filters.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  CorePipesModule,
  NotificationService,
  OdinCoreModule,
} from '@odin/odin-core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TransactionsEffects } from '../+state/transactions/transactions.effects';
import * as fromTransactions from '../+state/transactions/transactions.reducer';
import * as fromSettlements from '../+state/settlements/settlements.reducer';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TimelineComponent } from './components/timeline/timeline.component';
import { BackToTopComponent } from './components/back-to-top/back-to-top.component';
import { ChipStatusComponent } from './components/chip-status/chip-status.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CsvReportsDialogComponent } from './components/csv-reports-dialog/csv-reports-dialog.component';
import { SettlementListPageComponent } from './settlement-list-page/settlement-list-page.component';
import { SettlementsListComponent } from './components/settlements-list/settlements-list.component';
import { SettlementsEffects } from '../+state/settlements/settlements.effects';
import { SettlementDailyPageComponent } from './settlement-daily-page/settlement-daily-page.component';
import { SettlementDailyListComponent } from './components/settlement-daily-list/settlement-daily-list.component';
import { MatCardModule } from '@angular/material/card';
import { SettlementDailyReportDownloadComponent } from './components/settlement-daily-report-download/settlement-daily-report-download.component';
import { SettlementReportsService } from './services/settlement-reports.service';
import { SettlementsToggleComponent } from './components/settlements-toggle/settlements-toggle.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SettlementsMonthlyListComponent } from './components/settlements-monthly-list/settlements-monthly-list.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { AccessGuardService } from 'libs/odin-authentication/src/lib/services/access-guard.service';
import { TransactionsFacade } from '../+state/transactions/transactions.facade';
import { PrintReceiptComponent } from '../print-receipt/print-receipt.component';
import { PeblTrackingPageComponent } from './pebl-tracking-page/pebl-tracking-page.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    TransactionsLayoutComponent,
    TransactionsListComponent,
    TransactionDetailsComponent,
    TransactionsListPageComponent,
    PeblTrackingPageComponent,
    SettlementListPageComponent,
    TimelineComponent,
    BackToTopComponent,
    ChipStatusComponent,
    CsvReportsDialogComponent,
    SettlementListPageComponent,
    SettlementsListComponent,
    SettlementDailyPageComponent,
    SettlementDailyListComponent,
    SettlementDailyReportDownloadComponent,
    SettlementsToggleComponent,
    SettlementsMonthlyListComponent,
    PrintReceiptComponent,
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    TransactionsLayoutRoutingModule,
    MatListModule,
    MatStepperModule,
    MatSidenavModule,
    MatCardModule,
    InfiniteScrollModule,
    TransactionsFiltersModule,
    OdinCoreModule,
    MatChipsModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    StoreModule.forFeature(
      fromTransactions.TRANSACTIONS_FEATURE_KEY,
      fromTransactions.reducer,
    ),
    StoreModule.forFeature(
      fromSettlements.SETTLEMENTS_FEATURE_KEY,
      fromSettlements.reducer,
    ),
    EffectsModule.forFeature([TransactionsEffects, SettlementsEffects]),
    CorePipesModule,
  ],
  exports: [
    PeblTrackingPageComponent,
    TransactionsLayoutComponent,
    TransactionsFiltersModule,
    ChipStatusComponent,
    SettlementDailyReportDownloadComponent,
    PrintReceiptComponent,
  ],
  providers: [
    TransactionsService,
    SettlementsService,
    RouterExtService,
    CurrencyPipe,
    TitleCasePipe,
    SettlementReportsService,
    AccessGuardService,
    NotificationService,
    TransactionFormatterService,
    TransactionsFacade,
  ],
})
export class TransactionsLayoutModule {}
