import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'odin-chip-status',
  templateUrl: './chip-status.component.html',
  styleUrls: ['./chip-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipStatusComponent implements OnInit {
  @Input() icon: string | undefined = undefined;
  @Input() status = '-';
  @Input() chipClass = '';
  @Input() paid = false;

  ngOnInit(): void {
    this.status = !['completed', 'declined', 'reversed'].includes(this.status.toLowerCase())
      ? 'Processing'
      : this.status;
  }

  public getIcon(): string {
    if (this.icon !== undefined) return this.icon;
    if (this.status === 'Completed') return 'check_circle';
    if (this.status === 'Reversed') return 'restore';
    if (this.status === 'Completed') return 'check_circle';
    if (this.status === '-') return 'help_outline';
    return 'cancel';
  }
}
