import {
  faCircleExclamation,
  faInfoCircle,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { IAnnoucement } from '@odin/odin-core';

enum IAnnoucementType {
  MESSAGE = 'Message',
  WARNING = 'Warning',
  ERROR = 'Error',
}

const MessageIcon = faInfoCircle;
const WarningIcon = faWarning;
const ErrorIcon = faCircleExclamation;

export const messageArray: IAnnoucement[] = [
  // {
  //   id: 0,
  //   icon: MessageIcon,
  //   type: IAnnoucementType.MESSAGE,
  //   date: new Date('10/26/22'),
  //   message: 'Welcome to Monek Payment portal.',
  // },
  // { id: 0, icon: WarningIcon, type: IAnnoucementType.WARNING, date: new Date('10/12/22'), message: "We have noticed a large spike in refunds on your account." },
  // {
  //   id: 0,
  //   icon: ErrorIcon,
  //   type: IAnnoucementType.ERROR,
  //   date: new Date('10/12/23 15:00'),
  //   message:
  //     'Payment Processing Notice: We are aware of the issue and actively working to fix it. Thank you for your patience!',
  // },
];
