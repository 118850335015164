<div class="h-100 flex-grow-1">
  <cdk-virtual-scroll-viewport [itemSize]="rowSize" class="h-100">
    <div *cdkVirtualFor="let settlement of settlements; let i = index">
      <div
        (click)="select(settlement)"
        [attr.data-test]="'settlement-item-' + i"
        class="main-panel px-3 py-3 d-flex flex-row justify-content-between align-items-center gap-2"
        [class.opacity-25]="settlement.grossAmount === 0"
        [class.interactive]="settlement.grossAmount !== 0"
      >
        <div class="align-self-stretch flex-shrink-0 rounded">
          <div class="d-flex align-items-center justify-content-center h-100">
            <mat-icon>savings</mat-icon>
          </div>
        </div>

        <span
          data-test="transaction-created-date"
          class="flex-grow-1 flex-shrink-0 d-block flex-fill"
          >{{ settlement.settledDay | date: 'ccc, MMM dd, yyyy' }}</span
        >

        <div
          class="w-100 d-flex align-items-center justify-content-end gap-4"
          *ngIf="settlement.grossAmount !== 0"
        >
          <div class="d-none d-lg-block">
            <span
              data-test="settlement-gross-title"
              class="settlement-amount-title"
              >Gross</span
            >
            <span data-test="settlement-gross">
              {{
                settlement.grossAmount | currency: settlement.currencyCountry
              }}
            </span>
          </div>
          <div class="d-none d-lg-block">
            <span
              data-test="settlement-fees-title"
              class="settlement-amount-title"
              matTooltip="{{ settlement.totalFeeAmount | number:'1.6-6' }}"
            >
              <span class="d-none d-sm-inline">Fees </span>{{
                settlement.totalFeeAmount
                  | currency: settlement.currencyCountry:'symbol':'1.2-2'
              }}
            </span>
          </div>
          <div>
            <span
              data-test="settlement-net-title"
              class="settlement-amount-title"
              >Paid</span
            >
            <span data-test="settlement-net">
              {{ settlement.netAmount | currency: settlement.currencyCountry }}
            </span>
          </div>
        </div>

        <div
          class="flex-grow-1 d-block text-end"
          *ngIf="settlement.grossAmount === 0"
        >
          <div class="d-none d-lg-block">
            No statements have been processed for this day
          </div>
          <div class="d-block d-lg-none">No statements</div>
        </div>

        <mat-icon matListItemIcon *ngIf="allowSelection" class="flex-shrink-0"
          >chevron_right</mat-icon
        >
      </div>
      <mat-divider></mat-divider>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
