import { Injectable } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import {
  faCheckCircle,
  faPlayCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { CardHelperService } from 'libs/odin-core/src/lib/services/card-helper.service';
import * as moment from 'moment';
import {
  IFormattedTransaction,
  ITransaction,
  ITransactionIcon,
  TransactionChannel,
  TransactionStatus,
  TransactionType,
} from '../models';
import { StaticHelpers } from '@odin/odin-core';
import CountryConvertService from '../../data/country-convert';

const VTOriginID = 'f3fff7e1-d9e3-4e59-a6f0-85682f96cd6e';
const PEBLOriginID = '1f5839a1-4d95-4de5-974d-6529f0aba976';

@Injectable()
export class TransactionFormatterService {
  constructor(
    private cardHelperService: CardHelperService,
    private countryMapper: CountryConvertService,
  ) {}

  formatTransaction(transaction: ITransaction): IFormattedTransaction {
    transaction.transactionType = this.getTransactionType(transaction);
    return {
      ...transaction,
      ...{ transactionType: transaction.transactionType },
      ...{ isReversal: transaction.transactionType === 'reversal' },
      ...{ formattedChannel: this.tranasctionTypeToChannel(transaction) },
      ...{ formattedType: this.getFormattedValue(transaction.transactionType) },
      ...{
        currencyCountry:
          this.countryMapper.GetCountryByCurrencyNumericCode(
            transaction.currencyCode,
          )?.currencyCode || 'CAD',
      },
      ...this.getTransactionIcon(transaction),
      ...{
        formattedStatus: this.getFormattedValue(
          transaction.isReversed ? 'reversed' : transaction.status,
        ),
      },
      ...{
        operatorId: StaticHelpers.isNullOrEmpty(transaction.operatorId)
          ? undefined
          : transaction.operatorId,
        operatorName: transaction.operatorName,
      },
    };
  }

  private tranasctionTypeToChannel(transaction: ITransaction): string {
    if (transaction.transactionType === TransactionType.VirtualTerminal)
      return 'Virtual Terminal';
    if (transaction.transactionType === TransactionType.PEBL)
      return 'Pay-By-Link';
    return this.getFormattedValue(transaction.channel);
  }

  private getTransactionType(transaction: ITransaction): TransactionType {
    if (transaction.transactionType === TransactionType.Sale) {
      if (transaction.originId == VTOriginID)
        return TransactionType.VirtualTerminal;
      if (transaction.originId == PEBLOriginID) return TransactionType.PEBL;
    }
    return transaction.transactionType;
  }

  private getTransactionIcon(transaction: ITransaction): ITransactionIcon {
    const transactionStatus = transaction.status;
    const cardType = this.cardHelperService.GetCardType(transaction.maskedCard);
    const cardIcon = this.cardHelperService.GetCardIcon(cardType);

    switch (transactionStatus) {
      case TransactionStatus.Succeeded:
        return {
          statusIcon: faCheckCircle,
          statusIconClass: transaction.isReversed
            ? 'transaction-reversed'
            : 'transaction-succeeded',
          cardType: cardType,
          cardIcon: cardIcon,
        };
      case TransactionStatus.Reversed:
        return {
          statusIcon: faCheckCircle,
          statusIconClass: 'transaction-reversed',
          cardType: cardType,
          cardIcon: cardIcon,
        };
      case TransactionStatus.AwaitingPaymentMethod:
      case TransactionStatus.AwaitingCapture:
      case TransactionStatus.Processing:
        return {
          statusIcon: faPlayCircle,
          statusIconClass: 'transaction-in-progress',
          cardType: cardType,
          cardIcon: cardIcon,
        };
      case TransactionStatus.Cancelled:
        return {
          statusIcon: faTimesCircle,
          statusIconClass: 'transaction-canceled',
          cardType: cardType,
          cardIcon: cardIcon,
        };
      default:
        return {
          statusIcon: faPlayCircle,
          statusIconClass: 'transaction-in-progress',
          cardType: cardType,
          cardIcon: cardIcon,
        };
    }
  }

  public getFormattedValue(value: string): string {
    return value
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  getStatusArray(): string[] {
    return Object.values(TransactionStatus).map((status) =>
      this.getFormattedValue(status),
    );
  }

  getChannelArray(): string[] {
    return Object.values(TransactionChannel).map((channel) =>
      this.getFormattedValue(channel),
    );
  }

  getTransactionBadgeClass(transactionStatus: TransactionStatus): string {
    switch (transactionStatus) {
      case TransactionStatus.Succeeded:
        return 'badge_succeeded';
      case TransactionStatus.Reversed:
        return 'badge_succeeded';
      case TransactionStatus.AwaitingPaymentMethod:
      case TransactionStatus.Processing:
      case TransactionStatus.AwaitingCapture:
        return 'badge_info';
      case TransactionStatus.Cancelled:
        return 'badge_cancelled';
    }
  }

  getTransactionTimelineColour(
    transactionStatus: TransactionStatus,
  ): ThemePalette {
    switch (transactionStatus) {
      case TransactionStatus.Cancelled:
        return 'warn';
      default:
        return 'primary';
    }
  }

  getStepperIconColour(transactionStatus: TransactionStatus) {
    switch (transactionStatus) {
      case TransactionStatus.Cancelled:
        return 'cancelled';
      case TransactionStatus.Succeeded:
        return 'succeeded';
      default:
        return 'processing';
    }
  }

  convertToDate(date: moment.Moment | Date): Date {
    return date ? (moment.isMoment(date) ? date?.toDate() : date) : date;
  }
}
