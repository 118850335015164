/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { HTTPCONSTANTS } from '@odin/odin-core';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const intRequest = request.clone({
      url: request.url
        .replace(HTTPCONSTANTS.protocol, env.protocol)
        .replace(HTTPCONSTANTS.odinBaseAddress, env.odinBaseAddress)
        .replace(HTTPCONSTANTS.baseAddress, env.baseAddress),
    });
    return next.handle(intRequest);
  }
}
