/* eslint-disable no-console */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
/*
import * as Sentry from '@sentry/angular-ivy';

Sentry.init({
  dsn: environment.sentryKey,
  environment: environment.name,
  release: environment.releaseVersion,
  attachStacktrace: true,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'https://api.monek.com',
        'https://api-dev.monek.com',
        'https://api-staging.monek.com',
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay({
      maskAllText: environment.sentryMaskData, // set this explicitly from environment to be sure its not missed/unset
      // TODO: Find a way to test this has been set, e2e / githubg action
    }),
  ],
  tracesSampleRate: environment.sentrySampleRate,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
*/

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {})
  .catch((err) => console.error(err));
