import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { TransactionsService } from '../../transactions-layout/services/transactions.service';
import { TransactionsActionTypes } from './transactions.actions';
import { TransactionsFacade } from './transactions.facade';
import * as TransactionsAction from './transactions.actions';
import { AccessGuardService } from '@odin/odin-authentication';
import { MerchantService } from '@odin/odin-core';

@Injectable({ providedIn: 'root' })
export class TransactionsEffects {
  loadTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionsActionTypes.LoadTransactions),
      withLatestFrom(
        this.transactionsFacade.getParams$,
        this.transactionsFacade.getMerchantRef$,
      ),
      mergeMap(([, params, mid]) =>
        this.transactionsService.getTransactions(params, mid).pipe(
          map((transactions) => {
            return TransactionsAction.loadTransactionsSuccess({ transactions });
          }),
          catchError((error) =>
            of(TransactionsAction.loadTransactionsFailure({ error })),
          ),
        ),
      ),
    ),
  );

  loadMoreTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionsActionTypes.LoadMore),
      withLatestFrom(
        this.transactionsFacade.getParams$,
        this.transactionsFacade.getMerchantRef$,
      ),
      mergeMap(([, params, mid]) =>
        this.transactionsService.getTransactions(params, mid).pipe(
          map((transactions) => {
            return TransactionsAction.loadMoreSuccess({ transactions });
          }),
          catchError((error) =>
            of(TransactionsAction.loadTransactionsFailure({ error })),
          ),
        ),
      ),
    ),
  );

  downloadCsvReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionsActionTypes.DownloadCsvReport),
      withLatestFrom(this.transactionsFacade.getMerchantRef$),
      mergeMap(([action, mid]) =>
        this.transactionsService
          .downloadCsvReport(action['csvReportRequest'], mid)
          .pipe(
            map((link) => {
              setTimeout(() => {
                window.open(link.s3reportsurl, '_blank');
              });
              return TransactionsAction.downloadCsvReportSuccess();
            }),
            catchError((error) =>
              of(TransactionsAction.downloadCsvReportFailure({ error })),
            ),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly transactionsFacade: TransactionsFacade,
    private transactionsService: TransactionsService,
    private merchantService: MerchantService,
  ) {
    this.transactionsFacade.updateMerchantRef(
      this.merchantService.getMerchantRef(),
      false,
    );
  }
}
