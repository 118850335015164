import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '@odin/odin-settings';
import { CognitoService } from '../services/cognito-service';

@Component({
  selector: 'odin-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  public username = '';
  public password = '';
  public loggingIn = false;

  constructor(
    private router: Router,
    private settingsService: SettingsService,
    private cognitoService: CognitoService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    if (!this.cognitoService.userLoggedIn()) {
      this.requiresSignIn();
      return;
    }
    const lastRoute = this.settingsService.GetNotNullSetting('lastRoute', '/dashboard');
    this.router.navigate([lastRoute.value]);
  }

  private requiresSignIn(): void {
    this.route.queryParams
      .subscribe(params => {
        if (params['auto'] == 'true') {
          this.Login();
        }
      }
    );
  }

  Login() {
    this.loggingIn = true;
    this.cognitoService.login();
  }

  Logout() {
    this.cognitoService.logout();
  }
}
