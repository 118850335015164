import { AbstractControl, ValidationErrors } from '@angular/forms';
import { DATE_MIN } from '../filters.const.values';

const DATE_MIN_VALUE = DATE_MIN;
const DATE_MAX_VALUE = new Date();

/************************/
// Custom Validator to check if
// two dates are valid
// it overrides the Angular Material Min and Max date validators
/************************/

export class DateValidator {
  static confirmed = (date_min: string, date_max: string) => {
    return (control: AbstractControl): ValidationErrors | null => {
      const input_min = control.get(date_min);
      const input_max = control.get(date_max);

      // both dates are null
      if (input_min === null && input_max === null) {
        return null;
      }

      // date min has error
      if (
        input_min &&
        input_min.value !== null &&
        (input_min.value < DATE_MIN_VALUE || input_min.value > DATE_MAX_VALUE)
      ) {
        input_min.setErrors({ dateInvalid: true });

        return { dateInvalid: true };
      }

      // date max has error
      if (
        input_max &&
        input_max.value !== null &&
        (input_max.value < DATE_MIN_VALUE || input_max.value > DATE_MAX_VALUE)
      ) {
        input_max.setErrors({ dateInvalid: true });

        return { dateInvalid: true };
      }

      // date min greater than date max
      if (input_min !== null && input_max !== null) {
        if (
          input_min.value !== null &&
          input_max.value !== null &&
          input_min.value > input_max.value
        ) {
          input_max.setErrors({ dateInvalid: true });

          return { dateInvalid: true };
        } else {
          input_max.setErrors(null);

          return null;
        }
      }

      return null;
    };
  };
}
