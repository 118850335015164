import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CognitoService } from './cognito-service';

@Injectable()
export class MonekAdminGuardService implements CanActivate {
  constructor(
    private router: Router,
    private cognitoService: CognitoService
  ) {}

  private getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map((v) => v.url.map((segment) => segment.toString()).join('/'))
      .join('/');
  }

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    if (this.cognitoService.isMonekAdmin()) return true;

    this.router.navigate(['/dashboard']);

    return false;
  }
}
