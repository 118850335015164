import { FormField, FormFieldType } from './form-field';
import { Size } from './size.enum';
import { MonekColour } from './monek-colour.enum';
import { Alignment } from './alignment.enum';
import { InputType, OptionInputType } from './input-type.enum';
import { FormValidation } from './form-validation';
import { FormOption } from './form-option';

export class FormData<T> {
  public submitButton = true;
  public submitText = 'Submit';
  public submitSize: Size = Size.Half;
  public submitAlignment: Alignment = Alignment.Center;
  public formFields: FormField[] = [];
  public formError: string = '';
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public submitCallback: (data: T) => void = () => {};
  public onChange: (data: T, valid: boolean) => void = () => {};

  constructor(
    public theme: MonekColour,
    public formName: string,
  ) {}

  public AddSection(sectionName: string): void {
    const sectionField = new FormField(FormFieldType.Section);
    sectionField.sectionName = sectionName;
    this.formFields.push(sectionField);
  }

  public AddDetail(detailText: string): void {
    const detailField = new FormField(FormFieldType.Detail);
    detailField.sectionName = detailText;
    this.formFields.push(detailField);
  }

  public AddField(formField: FormField): void {
    this.RegisterField(formField);
    this.formFields.push(formField);
  }

  public AddRawField(
    id: string,
    viewName: string,
    type: InputType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    size: Size,
    validation: FormValidation,
    ownLine: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dataCallback: (data: any) => void,
      valCallback: (val: string | number | boolean | Date | null) => void,
    ) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onBlur: (formField: FormField, value: any) => void = () => {},
  ): void {
    const formField = new FormField(FormFieldType.Field).Build(
      id,
      value,
      viewName,
      type,
      size,
      validation,
      ownLine,
      data,
      onChange,
      onBlur,
    );
    this.RegisterField(formField);
    this.formFields.push(formField);
  }

  public AddOptionField(
    id: string,
    viewName: string,
    type: OptionInputType,
    options: FormOption[],
    value: string | number | boolean | null,
    size: Size,
    validation: FormValidation,
    ownLine: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dataCallback: (data: any) => void,
      valCallback: (val: string | number | boolean | Date | null) => void,
    ) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onBlur: (formField: FormField, value: any) => void = () => {},
  ): void {
    const formField = new FormField(FormFieldType.Option).BuildOption(
      id,
      value,
      viewName,
      type,
      options,
      size,
      validation,
      ownLine,
      data,
      onChange,
      onBlur,
    );
    this.RegisterField(formField);
    this.formFields.push(formField);
  }

  public Validate(): {
    valid: boolean;
    errorCount: number;
    errorMessages: string[];
  } {
    let valid = true;
    let errorCount = 0;
    const errors: string[] = [];
    this.formFields.forEach((formField: FormField) => {
      if (formField.value === undefined) formField.value = null;
      const validationResp = formField.validation.Validate(formField.value);
      if (!validationResp.validationOutcome) {
        errors.push(...validationResp.errors);
        valid = false;
        errorCount++;
      }
    });
    return { valid: valid, errorCount: errorCount, errorMessages: errors };
  }

  public ShowError(error: string): void {
    this.formError = error;
  }

  public HideError(): void {
    this.formError = '';
  }

  public toJSON(): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const JSONobj: any = {};
    this.formFields.forEach((formField: FormField) => {
      if (formField.formFieldType == FormFieldType.Field) {
        JSONobj[formField.id] = formField.value;
      }
    });
    return JSON.stringify(JSONobj);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public toObj(): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const JSONobj: any = {};
    this.formFields.forEach((formField: FormField) => {
      if (
        formField.formFieldType == FormFieldType.Field ||
        formField.formFieldType == FormFieldType.Option
      ) {
        JSONobj[formField.id] = this.GetFieldValue(formField);
      }
    });
    return JSONobj;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private GetFieldValue(formField: FormField): any {
    if (formField.value === null) return formField.value;

    if (
      formField.type.toString() == InputType.Number &&
      typeof formField.value === 'string'
    ) {
      if (formField.value.toString().indexOf('.') > -1)
        return Number.parseFloat(formField.value);
      return Number.parseInt(formField.value);
    }
    if (
      formField.type.toString() == InputType.Month &&
      (typeof formField.value === 'object' ||
        typeof formField.value === 'number' ||
        typeof formField.value === 'string')
    )
      return new Date(formField.value);
    if (formField.type.toString() == InputType.Text) {
      return formField.value.toString();
    }
    return formField.value;
  }

  private RegisterField(formField: FormField) {
    //formField.valueChanged.subscribe((data) => {});
  }
}
