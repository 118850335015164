<div class="h-100 flex-grow-1">
  <cdk-virtual-scroll-viewport [itemSize]="rowSize" class="h-100">
    <div
      *cdkVirtualFor="let transaction of formattedTransactions; let i = index"
    >
      <div
        (click)="select(transaction)"
        [attr.data-test]="'transaction-item-' + i"
        class="interactive main-panel px-3 py-3 d-flex flex-row justify-content-between align-items-center gap-4"
      >
        <div
          class="align-self-stretch flex-shrink-0 d-none d-lg-inline px-2 rounded"
          style="background-color: #fff; color: black"
        >
          <div class="d-flex align-items-center justify-content-center h-100">
            <div style="width: 24px">
              <mat-icon
                class="h-100 d-block"
                *ngIf="transaction.cardIcon.iconType === 'ICON'"
                >{{ transaction.cardIcon.value }}</mat-icon
              >
              <img
                class="w-100 h-100 d-block"
                *ngIf="transaction.cardIcon.iconType !== 'ICON'"
                [src]="transaction.cardIcon.value"
              />
            </div>
          </div>
        </div>

        <div class="flex-shrink-0">
          <div
            style="width: 24px"
            class="mx-auto"
            [matTooltip]="
              transaction.transactionType === 'refund'
                ? transaction.formattedType
                : transaction.formattedChannel
            "
            matTooltipPosition="right"
          >
            <fa-icon
              class="d-block mx-auto"
              [icon]="transactionIcon(transaction)"
              size="lg"
            ></fa-icon>
          </div>
        </div>

        <odin-chip-status
          mat-list-icon
          [status]="transaction.formattedStatus"
          [chipClass]="transaction.statusIconClass"
        ></odin-chip-status>

        <span
          data-test="transaction-created-date"
          class="flex-grow-1 flex-shrink-0 d-block"
          >{{ transaction.createdOn | date: 'dd/MM/yy HH:mm' }}</span
        >

        <div class="d-none d-lg-inline flex-grow-1 flex-shrink-1 w-100">
          <span
            data-test="transaction-cardholder-name"
            class="d-block w-100 text-truncate"
            style="max-width: 140px"
            >{{ transaction.cardHolderDetails.name || '-' }}</span
          >
        </div>

        <div class="flex-grow-1 flex-shrink-1 w-100">
          <span
            class="d-none d-xl-inline"
            data-test="transaction-masked-card"
            ß
            >{{ transaction.maskedCard || '-' }}</span
          >
        </div>

        <span
          class="flex-grow-1 flex-shrink-1 w-100 text-end"
          data-test="transaction-amount"
          >{{
            transaction.amount | currency: transaction.currencyCountry
          }}</span
        >

        <mat-icon matListItemIcon *ngIf="allowSelection" class="flex-shrink-0"
          >chevron_right</mat-icon
        >
      </div>
      <mat-divider></mat-divider>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
