import { IChartData } from './ichartdata';

export class LineChartSeriesItem {
  constructor(public name: string, public value: number) {}
}

export class LineChartNode {
  constructor(public name: string, public series: LineChartSeriesItem[]) {}
}

export class LineChartData implements IChartData {
  public data: LineChartNode[] = [];
}
