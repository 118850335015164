<mat-card appearance="outlined">
  <div class="d-flex align-items-center mb-2">
    <div class="error-container me-3">
      <fa-icon class="error-icon" [icon]="errorIcon" size="3x"></fa-icon>
    </div>
    <div>
      <mat-card-title class="m-0">Session Expired</mat-card-title>
      <mat-card-subtitle class="m-0"
        >Your session has expired, please log in to continue</mat-card-subtitle
      >
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <button mat-flat-button mat-dialog-close cdkFocusInitial color="warn">
      Close
    </button>
  </div>
</mat-card>
