// Example data
// [
//     {
//       "key": "Ecommerce",
//       "value": [
//         {
//           "name": "01/09/21",
//           "value": 5800
//         },
//         {
//           "name": "02/09/21",
//           "value": 8940
//         }
//       ]
//     }
//]

export class MonekChartData {
  private _data: MonekChartNode[] = [];
  public get data(): MonekChartNode[] {
    return this._data;
  }

  public AddNode(
    keyName: string,
    series: MonekChartDataPoint[]
  ): MonekChartData {
    this._data.push(new MonekChartNode(keyName, series));
    return this;
  }
}

export class MonekChartNode {
  constructor(public key: string, public series: MonekChartDataPoint[]) {}
}

export class MonekChartDataPoint {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public name: string, public value: any) {}
}
