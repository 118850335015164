/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SettlementsFacade } from '../+state/settlements/settlements.facade';
import { TransactionsFacade } from '../+state/transactions/transactions.facade';

@Injectable()
export class PaginationInterceptor implements HttpInterceptor {
  paginationHeader = 'monek-pagination';

  constructor(private facade: TransactionsFacade, private settlementFacade: SettlementsFacade) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone();
    return next.handle(request).pipe(
      tap((resp: HttpEvent<any>) => {
        if (resp instanceof HttpResponse) {
          const pagination = resp.headers.get(this.paginationHeader);
          if (pagination) {

            switch (this.getRouteValue(resp.url)) {
              case 'settlements-daily':
                this.settlementFacade.saveDailyPagination(JSON.parse(pagination));
                break;
              case 'settlements-date':
                this.settlementFacade.saveSettledTransactionsPagination(JSON.parse(pagination));
                break;
              case 'transactions':
                this.facade.savePaginationValues(JSON.parse(pagination));
                break;
              default:
                break;
            }

          }
        }
      })
    );
  }

  private getRouteValue(route: string | null) : string {
    if (route) {
      if (route.indexOf('/settlements') > -1 && route.indexOf('/daily') > -1) return 'settlements-daily';
      if (route.indexOf('/settlements') > -1 && route.indexOf('/date') > -1) return 'settlements-date';
      if (route.indexOf('/transactions') > -1) return 'transactions';
    }

    return '';
  }
}