import { Component } from '@angular/core';
import {
  ChartBuildData,
  IAnnoucement,
  ChartData,
  ChartBuilder,
  MonekChartDataPoint,
  MonekChartData,
  ChartType,
  ChartSize,
} from '@odin/odin-core';
import { messageArray } from './annoucement-messages';
import { environment } from '../../environments/environment';

@Component({
  selector: 'odin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  messages: IAnnoucement[] = messageArray;
  public grossChartData: ChartBuildData = this.BuildTodayChart();

  private BuildTodayChart(): ChartBuildData {
    const chartData = new MonekChartData().AddNode('Sales', [
      new MonekChartDataPoint('08:00', 0),
      new MonekChartDataPoint('09:00', 110),
      new MonekChartDataPoint('10:00', 170),
      new MonekChartDataPoint('11:00', 210),
      new MonekChartDataPoint('12:00', 284),
      new MonekChartDataPoint('13:00', 347),
      new MonekChartDataPoint('14:00', 575),
      new MonekChartDataPoint('15:00', 642),
      new MonekChartDataPoint('16:00', 780),
      new MonekChartDataPoint('17:00', 850),
    ]);

    return new ChartBuilder('Todays Sales')
      .SetChartType(ChartType.LineChart)
      .SetAxisLabels('Time', '(£) Total Sales')
      .SetShowLegend(false)
      .SetChartSize(ChartSize.Full)
      .SetChartHeight(270)
      .SetData(new ChartData(chartData))
      .Build();
  }

  private BuildGrossChart(): ChartBuildData {
    const chartData = new MonekChartData()
      .AddNode('E-Commerce', [new MonekChartDataPoint('Sales', 2800)])
      .AddNode('In Store', [new MonekChartDataPoint('Sales', 6100)])
      .AddNode('MOTO', [new MonekChartDataPoint('Sales', 1800)]);

    return new ChartBuilder('Weekly Gross Volume')
      .SetChartTypeOptions([
        ChartType.HorizontalBarChart,
        ChartType.AdvancedPieChart,
      ])
      .SetAxisLabels('(£) Sale Amount', 'Payment Type')
      .SetShowLegend(false)
      .SetChartSize(ChartSize.Full)
      .SetChartHeight(270)
      .SetData(new ChartData(chartData))
      .Build();
  }

  public pciEnabled(): boolean {
    return environment.featureToggles['pci-widget'];
  }
}
