import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faDownload,
  faMoneyCheck,
} from '@fortawesome/free-solid-svg-icons';
import { LOADED } from '@odin/odin-core';
import * as moment from 'moment';
import { concat, fromEvent, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettlementsFacade } from '../../+state/settlements/settlements.facade';
import { fadeAnimation } from '../animations';
import { ITransactionSettled, ScrollDirection } from '../models';
import { SettlementsService } from '../services';
import * as GLOBALS from '../settlements-global.const.values';

const loaded$ = new Subject<void>();

@Component({
  selector: 'odin-settlement-daily-page',
  templateUrl: './settlement-daily-page.component.html',
  styleUrls: ['./settlement-daily-page.component.scss'],
  animations: [fadeAnimation],
  providers: [
    {
      provide: LOADED,
      useValue: loaded$.asObservable(),
    },
  ],
})
export class SettlementDailyPageComponent implements OnInit {
  public totalSettledAmount = 0;
  public totalFees = 0;
  public totalGrossAmount = 0;

  faTrnsactionSettled: IconDefinition = faMoneyCheck;

  error$!: Observable<string>;
  settledTransactions$!: Observable<ITransactionSettled[] | undefined>;
  loading$!: Observable<boolean>;

  allowSelection$!: Observable<boolean>;
  hasNext$!: Observable<boolean>;
  hasPrev$!: Observable<boolean>;
  noMoreToLoad$!: Observable<boolean>;
  scroll$!: Observable<boolean>;

  // create a component for this
  backIcon: IconDefinition = faChevronLeft;
  downloadIcon: IconDefinition = faDownload;
  colour = 'primary';

  private url_date = '';

  constructor(
    private router: Router,
    private facade: SettlementsFacade,
    private activeRoute: ActivatedRoute,
    private settlementService: SettlementsService,
  ) {}

  ngOnInit(): void {
    const content = document.querySelector(GLOBALS.SCROLLED_CONTAINER);

    if (content) {
      this.scroll$ = fromEvent(content, 'scroll').pipe(
        map(() => content.scrollTop > GLOBALS.SCROLLED_TOP),
      );
    }

    this.init();
  }

  private init(): void {
    this.getRouterInfo();

    // save the settlement date into the store
    this.facade.saveSelectedSettledDate(this.url_date);

    this.facade.getSettledTransactions();
    this.getTotalForDay();

    // catch error if any
    this.error$ = this.facade.getSettledTransactionsError$;

    this.settledTransactions$ = this.facade.loadSettledTransactions$;

    this.loading$ = this.facade.getSettlementsLoader$;

    // get allow selection
    this.allowSelection$ = this.facade.getAllowSelection$;

    // get pagination values
    this.hasNext$ = this.facade.getSettledTransactionsHasNext$;
    this.hasPrev$ = this.facade.getSettledTransactionsHasPrev$;
    this.noMoreToLoad$ = this.facade.noMoreSettledTransactionsToLoad$;
  }

  goBackToSettlementsPage() {
    this.router.navigate(['/transactions/settlements'], {
      relativeTo: this.activeRoute.parent,
    });
  }

  private getRouterInfo() {
    this.url_date = this.activeRoute.snapshot.paramMap.get('date') ?? '';
  }

  // refresh settlements
  refreshSettlements() {
    this.facade.getSettlements();

    // complete the swipe down loader
    loaded$.next();
  }

  loadTransactionsOnScroll(direction: ScrollDirection) {
    if (direction === ScrollDirection.Down) {
      this.loadMoreTransactions();
    }
  }

  public loadMoreTransactions() {
    // eslint-disable-next-line no-var
    var sub = this.loading$.subscribe((_) => {
      if (sub) sub.unsubscribe();
      if (!sub && !_) {
        // eslint-disable-next-line no-var
        var _sub = this.noMoreToLoad$.subscribe((nomoreToLoad: boolean) => {
          if (_sub) _sub.unsubscribe();
          if (nomoreToLoad) return;
          this.facade.loadMoreSettledTransactions();
          const moreTransactions$ = this.facade.loadMoreSettledTransactions$;
          this.settledTransactions$ = concat(
            this.settledTransactions$,
            moreTransactions$,
          );
        });
      }
    });
  }

  public getTotalForDay(): void {
    const settledDate = this.url_date;
    const x = this.settlementService.getSelectedSummary();
    if (x == null || settledDate == null) {
      this.goBackToSettlementsPage();
      return;
    }
    const shortDate = moment(x.settledDay).format('YYYY-MM-DD');
    if (shortDate == settledDate) {
      this.totalFees = x.totalFeeAmount;
      this.totalGrossAmount = x.grossAmount;
      this.totalSettledAmount = x.netAmount;
    }
  }
  onNewDailySettlementReport() {
    const reportDate = this.activeRoute.snapshot.paramMap.get('date') ?? '';
    this.facade.getDailySettlementReport(reportDate);
  }

  onNewDailySettlementReportCSV() {
    const dailyDate = this.activeRoute.snapshot.paramMap.get('date') ?? '';
    const reportDate = new Date(dailyDate);
    this.facade.getDailySettlementReportCSV(reportDate);
  }
}
