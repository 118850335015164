
<ng-container >
<button mat-button [matMenuTriggerFor]="menu" class="daily-settlements-button" [disabled]="loading$ | async">Download Report
    <mat-icon matListItemIcon class="flex-shrink-0" class="daily-settlements-icon">download</mat-icon>
</button>

<mat-menu #menu="matMenu" xPosition="before">
    <button mat-menu-item color="primary" class="daily-settlements-button-pdf" (click)="downloadDailySettlementReport()">
        <mat-icon matListItemIcon class="flex-shrink-0" class="daily-settlements-icon-pdf">picture_as_pdf</mat-icon>
        <div class="daily-settlements-text-pdf">Download PDF</div>
    </button>
    
    <button mat-menu-item color="primary" class="daily-settlements-button-csv" (click)="downloadDailySettlementReportCSV()">
        <mat-icon matListItemIcon class="flex-shrink-0" class="daily-settlements-icon-csv">download</mat-icon>
        <div class="daily-settlements-text-csv">Download CSV</div>
    </button>
</mat-menu>

<odin-loading-spinner *ngIf="loading$ | async"
class="loading-spinner pt-2"
[active]="true"
[diameter]="20"
[strokeWidth]="2"
[colour]="'primary'">
</odin-loading-spinner>
</ng-container>