import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '@odin/odin-settings';
import { CognitoService } from '../services/cognito-service';

@Component({
  selector: 'odin-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  constructor(
    private cognitoService: CognitoService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    this.validateToken();
  }

  private validateToken(): void {
    const minCompleteTime = new Date().getTime() + 1000;
    this.route.queryParams.subscribe((params) => {
      const code = params['code'];
      if (code == '' || code == null) {
        this.router.navigate(['/authentication/login']).then(() => {
          this.cognitoService.loginChanged.emit(false);
        });
        return;
      }
      this.cognitoService.getTokenFromCode(code, (success: boolean) => {
        if (!success) {
          this.router.navigate(['/authentication/login']).then(() => {
            this.cognitoService.loginChanged.emit(false);
          });
        } else {
          this.progressSession(minCompleteTime);
        }
      });
    });
  }

  private progressSession(minCompleteTime: number): void {
    let remainingTime = minCompleteTime - new Date().getTime();
    if (remainingTime < 0) remainingTime = 10;
    setTimeout(() => {
      const lastRoute = this.settingsService.GetNotNullSetting(
        'lastRoute',
        '/dashboard',
      );
      this.cognitoService.CompleteLogin();
      this.router.navigate([lastRoute.value]);
    }, remainingTime);
  }
}
