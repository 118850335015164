export enum InputType {
  Colour = 'color',
  Time = 'time',
  Email = 'email',
  Month = 'month',
  Week = 'week',
  MonthYear = 'month-year',
  Date = 'date',
  DateTimeLocal = 'datetime-local',
  Number = 'number',
  Password = 'password',
  Search = 'search',
  Phone = 'tel',
  Text = 'text',
  Url = 'url',
  Hidden = 'hidden',
}

export enum OptionInputType {
  Radio = 'radio',
  Dropdown = 'select',
  Checkbox = 'checkbox'
}
