import { TransactionFiltersInitial } from "./transaction-filters-initial";

export class TransactionFiltersParams {
  page: number;
  take?: number;
  query?: string;
  filters: TransactionFiltersInitial;

  constructor(
    page?: number,
    take?: number,
    query?: string,
    filters?: TransactionFiltersInitial
  ) {
    this.page = page || 1;
    this.take = take;
    this.query = query;
    this.filters = filters || new TransactionFiltersInitial();
  }

  toString(): string {
    return [this.pageParam, this.takeParam, this.queryParam, this.filterParam]
      .filter((p) => p.length)
      .join('')
      .slice(0, -1);
  }

  private get pageParam(): string {
    return this.constructParam('page', this.page?.toString());
  }
  private get takeParam(): string {
    return this.constructParam('take', this.take?.toString());
  }
  private get queryParam(): string {
    return this.constructParam('query', this.query);
  }
  private get filterParam(): string {
    let param = '';

    if (this.filters) {
      param =
        this.constructParam('minDate', this.filters.minDate?.toDateString()) +
        this.constructParam('maxDate', this.filters.maxDate?.toDateString()) +
        this.constructParam('minAmount', this.filters.minAmount?.toString()) +
        this.constructParam('maxAmount', this.filters.maxAmount?.toString()) +
        this.constructParam('channel', this.filters.channel) +
        this.constructParam('status', this.filters.status);
    }

    return param;
  }

  private constructParam(paramName: string, paramValue?: string): string {
    return paramValue ? `${paramName}=${paramValue}&` : '';
  }
}
