import { EventEmitter, Injectable } from '@angular/core';

export class Setting {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public key: string, public value: any) {}
}

@Injectable({ providedIn: 'root' })
export class SettingsService {
  public settingChanged: EventEmitter<Setting> = new EventEmitter<Setting>();

  public GetSetting(key: string): Setting | null {
    const setting = this.GetSettingByKey(key);
    return setting;
  }

  public GetNotNullSetting<T>(key: string, defaultValue: T): Setting {
    const setting = this.GetSetting(key);
    if (setting == null || setting.value == null || setting.value == '') {
      return new Setting(key, defaultValue);
    }
    return setting;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public SetSetting(key: string, value: any): void {
    const settings = this.LoadSettings();
    let updated = false;
    settings.map((s: Setting) => {
      if (s.key == key) {
        s.value = value;
        updated = true;
        this.settingChanged.emit(s);
      }
    });
    if (!updated) {
      const s = new Setting(key, value);
      settings.push(s);
      this.settingChanged.emit(s);
    }
    localStorage.setItem(key, value);
    this.SaveSettings(settings);
  }

  public RemoveSetting(key: string): void {
    const settings = this.LoadSettings();
    const existingSetting = settings.filter((s: Setting) => s.key == key);
    existingSetting
    localStorage.removeItem(key);
  }

  // Replace w/ database
  private GetSettingByKey(key: string): Setting | null {
    const settings = this.LoadSettings();
    const existingSetting = settings.filter((s: Setting) => s.key == key);
    if (existingSetting.length > 0) {
      return existingSetting[0];
    } else {
      return null;
    }
  }

  private LoadSettings(): Setting[] {
    const settings = localStorage.getItem('settings');
    if (settings == null) return [];
    return JSON.parse(settings);
  }

  private SaveSettings(settings: Setting[]) {
    localStorage.setItem('settings', JSON.stringify(settings));
  }
}
