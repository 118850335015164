import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTPCONSTANTS } from '@odin/odin-core';
import { SettlementReports } from '../models/settlement-daily-report';

@Injectable({ providedIn: 'root' })
export class SettlementReportsService {
  private readonly baseUrl: string = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.odinBaseAddress}`;

  constructor(private readonly http: HttpClient) {}

  public getDailySettlementReport(reportDate: string, merchantRef: string) {
    const url = `${this.baseUrl}/transactions/v1/report/settlement/${merchantRef}/?date=${reportDate}`;
    return this.http.get<SettlementReports>(url);
  }

  public getMonthlySettlementReport(reportDate: Date, merchantRef: string) {
    const url = `${
      this.baseUrl
    }/transactions/v1/report/settlement/${merchantRef}/?date=${reportDate.toISOString()}&isMonthly=true`;
    return this.http.get<SettlementReports>(url);
  }

  public getMonthlySettlementReportCSV(reportDate: Date, merchantRef: string) {
    const url = `${this.baseUrl}/transactions/v1/report/settlement/${merchantRef}/csv`;
    const body = {
      date: reportDate.toISOString(),
      isMonthly: true,
    };
    return this.http.post<SettlementReports>(url, body);
  }

  public getDailySettlementReportCSV(reportDate: Date, merchantRef: string) {
    const url = `${this.baseUrl}/transactions/v1/Report/settlement/${merchantRef}/csv`;
    const body = {
      date: reportDate.toISOString(),
      isMonthly: false,
    };
    return this.http.post<SettlementReports>(url, body);
  }
}
