import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SettlementsFacade } from '../../+state/settlements/settlements.facade';
import { TransactionsFacade } from '../../+state/transactions/transactions.facade';
import { MerchantChangeService } from '@odin/odin-core';

@Injectable({ providedIn: 'root' })
export class MerchantUpdateService {
  constructor(
    private merchantChangeService: MerchantChangeService,
    private tFacade: TransactionsFacade,
    private sFacade: SettlementsFacade,
    private router: Router,
  ) {
    this.merchantChangeService.merchantChangeEvent.subscribe(
      (merchantId: string) => {
        this.setActiveMerchant(merchantId);
      },
    );
  }

  setActiveMerchant(merchantId: string): void {
    const includeTrans = this.router.url.includes('/transactions');
    const includeSettle = this.router.url.includes('/transactions/settlements');

    this.tFacade.updateMerchantRef(merchantId, includeTrans && !includeSettle);
    this.sFacade.updateMerchantRef(merchantId, includeSettle);
  }
}
