import { Injectable } from '@angular/core';
import { NotificationService, MerchantService } from '@odin/odin-core';
import { UserAccessLevel } from '../models/user-access-level';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AccessGuardService {
  constructor(
    private router: Router,
    private merchantService: MerchantService,
    private notificationService: NotificationService,
  ) {}

  public boot(reason: string): void {
    this.router.navigate(['/dashboard']);
    this.notificationService.AlertDialogWindow(reason);
  }

  hasAccessLevel(accessLevel: UserAccessLevel): boolean {
    const profile = this.merchantService.getUserprofile();
    if (profile === null) return false;
    return profile.accessLevel == accessLevel;
  }

  isMinLevel(accessLevel: UserAccessLevel): boolean {
    const profile = this.merchantService.getUserprofile();
    if (profile === null) return false;
    return profile.accessLevel >= accessLevel;
  }

  hasGrant(grantName: string): boolean {
    const profile = this.merchantService.getUserprofile();
    if (profile === null) return false;
    const matchingGrants = profile.grants.filter((g) => {
      if (g.action != grantName) return false;
      if (g.resource != this.merchantService.getMerchantRef()) return false;
      return true;
    });
    return matchingGrants.length > 0;
  }
}
