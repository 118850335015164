import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import { faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import * as GLOBALS from '../../transactions-global.const.values';

@Component({
  selector: 'odin-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackToTopComponent {
  @Input() windowScrolled: boolean | null = false;

  arrowUp: IconDefinition = faChevronUp;

  scrollToTop() {
    const container = document.documentElement.querySelector(
      GLOBALS.SCROLLED_CONTAINER
    );
    container?.scroll({
      top: 0,
      behavior: 'smooth', // Not working in Safari!
    });
  }
}
