import { TransactionFilters } from "./transaction-filters";

export class TransactionFiltersInitial extends TransactionFilters {
  currencyCode: string;

  constructor(
    minDate?: Date,
    maxDate?: Date,
    minAmount?: number,
    maxAmount?: number,
    channel?: string,
    status?: string,
    currecnyCode: string = '826'
  ) {
    super(minDate, maxDate, minAmount, maxAmount, channel, status);

    this.currencyCode = currecnyCode;
  }
}
