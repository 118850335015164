import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OdinSettingsRoutingModule } from './odin-settings/odin-settings-routing.module';
import { OdinSettingsPageComponent } from './odin-settings/odin-settings-page/odin-settings-page.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatInputModule,
    MatSelectModule,
    FontAwesomeModule,
    MatCardModule,
  ],
  declarations: [OdinSettingsPageComponent],
  exports: [OdinSettingsPageComponent, OdinSettingsRoutingModule],
})
export class OdinSettingsModule {}
