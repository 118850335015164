import { LegendPosition } from '@swimlane/ngx-charts';
import { ChartSize, ChartType } from './chart-options';

export class ChartBuildData {
  public name = '';
  public containerWidth = 0;
  public size: ChartSize = ChartSize.Full;
  public height = 400;
  public chartType: ChartType = ChartType.VerticalBarChart;
  public chartTypeOptions: ChartType[] = [];
  public viewData: number[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public data: any = null;
  public xAxisLabel = '';
  public yAxisLabel = '';
  public showLegend = false;
  public legendPosition: LegendPosition = LegendPosition.Below;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public colourScheme: any = {
    domain: ['#7CC2F6', '#AF81E4', '#E784BA', '#F9C1A0', '#B7F6AF'],
  };
}
