<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p>{{ data.body }}</p>
</div>
<div mat-dialog-actions class="d-flex justify-content-end align-items-center">
  <button mat-stroked-button [color]="data.declineColour" (click)="decline()">
    {{ data.declineText }}
  </button>
  <button
    mat-flat-button
    [color]="data.confirmColour"
    (click)="confirm()"
    cdkFocusInitial
  >
    {{ data.confirmText }}
  </button>
</div>
