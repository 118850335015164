import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

/** A router wrapper, adding extra functions. */
@Injectable()
export class RouterExtService {
  private previousUrl = '';
  private currentUrl = '';

  constructor(private router: Router) {
    this.currentUrl = this.router.url;

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.urlAfterRedirects;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public getCurrentUrl() {
    return this.currentUrl;
  }

  public redirectFromSettlementDetails(): boolean {
    // Settlement Details page: Example transactions/settlements/YYYY-MM-DD
    const regex = /\/transactions\/settlements\/\d{4}-\d{2}-\d{2}/;
    const isPreviousUrlMatching = regex.test(this.previousUrl);
    return isPreviousUrlMatching;
  }
}
