import { Action, createReducer, on } from "@ngrx/store";
import { initialState, SettlementState } from "./settlements.init";
import * as SettlementsActions from "./settlements.actions";

export const SETTLEMENTS_FEATURE_KEY = 'settlements_key';

export function reducer(state: SettlementState | undefined, action: Action) {
  return settlementReducer(state, action);
}

const settlementReducer = createReducer<SettlementState>(
    initialState,
    on(SettlementsActions.loadSettlements, (state) => {
        return {
            ...state,
            settlements: [],
            moreSettlements: [],
            error: '',
            loaded: true,
            page: 1
        }
    }),
    on(SettlementsActions.loadSettlementsSuccess, (state, action) => {
        return {
            ...state,
            settlements: action.settlements,
            error: '',
            loaded: false,
        };
    }),
    on(SettlementsActions.loadSettlementsFailure, (state, action) => {
        return {
            ...state,
            settlements: [],
            error: action.error,
            loaded: false,
            pagination: {
                hasNext: false,
                hasPrev: false,
                pageCount: 0,
                pageSize: state.pagination.pageSize,
                currentPage: 0,
                totalPages: 0,
                totalCount: 0
            }
        };
    }),
    on(SettlementsActions.loadMore, (state) => {
        return {
          ...state,
          loaded: true,
          page: state.pagination.hasNext
            ? state.pagination.currentPage + 1
            : state.pagination.currentPage
        };
      }),
    on(SettlementsActions.loadMoreSuccess, (state, action) => {
        return {
          ...state,
          settlements: state.settlements.concat(action.settlements),
          moreSettlements: action.settlements,
          loaded: false,
        };
      }),
    on(SettlementsActions.saveDailySettlementsPagination, (state, action) => {
        return {
          ...state,
          pagination: {
            hasNext: action.pagination.HasNext,
            hasPrev: action.pagination.HasPrev,
            pageCount: action.pagination.PageCount,
            pageSize: action.pagination.PageSize,
            currentPage: action.pagination.CurrentPage,
            totalPages: action.pagination.TotalPages,
            totalCount: action.pagination.TotalCount,
          },
        };
    }),
    // settled transactions (drill-down)
    on(SettlementsActions.loadSettledTransactions, (state) => {
      return {
          ...state,
          loaded: true,
          settledTransactions: {
            ...state.settledTransactions,
            transactions: [],
            moreTransactions: [],
            error: '',
            page: 1
          }
      }
  }),
  on(SettlementsActions.loadSettledTransactionsSuccess, (state, action) => {
      return {
          ...state,
          loaded: false,
          settledTransactions: {
            ...state.settledTransactions,
            transactions: action.transactions,
            error: ''
          }
      };
  }),
  on(SettlementsActions.loadSettledTransactionsFailure, (state, action) => {
      return {
          ...state,
          loaded: false,
          settledTransactions: {
            ...state.settledTransactions,
            transactions: [],
            error: action.error,
            pagination: {
              hasNext: false,
              hasPrev: false,
              pageCount: 0,
              pageSize: state.settledTransactions.pagination.pageSize,
              currentPage: 0,
              totalPages: 0,
              totalCount: 0
            }
          }
      };
  }),
  on(SettlementsActions.loadMoreSettledTransactions, (state) => {
    return {
      ...state,
      loaded: true,
      settledTransactions: {
        ...state.settledTransactions,
        page: state.settledTransactions.pagination.hasNext
        ? state.settledTransactions.pagination.currentPage + 1
        : state.settledTransactions.pagination.currentPage
      }
    };
  }),
  on(SettlementsActions.loadMoreSettledTransactionsSuccess, (state, action) => {
    return {
      ...state,
      loaded: false,
      settledTransactions: {
        ...state.settledTransactions,
        transactions: state.settledTransactions.transactions.concat(action.transactions),
        moreTransactions: action.transactions
      }
    };
  }),
  on(SettlementsActions.loadMoreSettledTransactionsFailure, (state, action) => {
    return {
        ...state,
        loaded: false,
        settledTransactions: {
          ...state.settledTransactions,
          moreTransactions: [],
          error: action.error,
          pagination: {
            hasNext: false,
            hasPrev: false,
            pageCount: 0,
            pageSize: state.settledTransactions.pagination.pageSize,
            currentPage: 0,
            totalPages: 0,
            totalCount: 0
          }
        }
    };
  }),
  on(SettlementsActions.saveSettledDate, (state, action) => {
    return {
      ...state,
      settledTransactions: {
        ...state.settledTransactions,
        settledDate: action.settledDate
      }
    };
  }),
  on(SettlementsActions.saveSettledTransactionsPagination, (state, action) => {
    return {
      ...state,
      settledTransactions: {
        ...state.settledTransactions,
        pagination: {
          hasNext: action.pagination.HasNext,
          hasPrev: action.pagination.HasPrev,
          pageCount: action.pagination.PageCount,
          pageSize: action.pagination.PageSize,
          currentPage: action.pagination.CurrentPage,
          totalPages: action.pagination.TotalPages,
          totalCount: action.pagination.TotalCount
        }
      }
    };
  }),
  on(SettlementsActions.saveMerchantRef, (state, action) => {
    return {
      ...state,
      merchantRef: action.merchantRef
    };
  }),

  on(SettlementsActions.getMonthlySettlementReports, (state) => {
    return {
      ...state,
      monthlySettlementReportsLoading: true,
    };
  }),
  on(SettlementsActions.getMonthlySettlementReportSuccess, (state) => {
    return {
      ...state,
      monthlySettlementReportsLoading: false,
    };
  }),
  on(SettlementsActions.getMonthlySettlementReportFailure, (state) => {
    return {
      ...state,
      monthlySettlementReportsLoading: false
    };
  }),

  on(SettlementsActions.getMonthlySettlementReportsCSV, (state) => {
    return {
      ...state,
      monthlySettlementReportsLoading: true,
    };
  }),
  on(SettlementsActions.getMonthlySettlementReportCSVSuccess, (state) => {
    return {
      ...state,
      monthlySettlementReportsLoading: false,
    };
  }),
  on(SettlementsActions.getMonthlySettlementReportCSVFailure, (state) => {
    return {
      ...state,
      monthlySettlementReportsLoading: false
    };
  }),
  on(SettlementsActions.getDailySettlementReportsCSV, (state) => {
    return {
      ...state,
      dailySettlementReportsLoading: true,
    };
  }),
  on(SettlementsActions.getDailySettlementReportCSVSuccess, (state) => {
    return {
      ...state,
      dailySettlementReportsLoading: false,
    };
  }),
  on(SettlementsActions.getDailySettlementReportCSVFailure, (state) => {
    return {
      ...state,
      dailySettlementReportsLoading: false
    };
  }),
  on(SettlementsActions.getDailySettlementReports, (state) => {
    return {
      ...state,
      dailySettlementReportsLoading: true,
    };
  }),
  on(SettlementsActions.getDailySettlementReportSuccess, (state) => {
    return {
      ...state,
      dailySettlementReportsLoading: false,
    };
  }),
  on(SettlementsActions.getDailySettlementReportFailure, (state) => {
    return {
      ...state,
      dailySettlementReportsLoading: false
    };
  }),
  // Settlement Scroll Position 
  on(SettlementsActions.settlementsScrollPosition, (state, action) => {
    return {
      ...state,
      scrollPosition: action.scrollPosition
    };
  }),
);