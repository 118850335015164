<div class="py-3 px-2 w-100">
  <!-- HEADER -->
  <div class="d-flex align-items-center justify-content-between">
    <h1 class="mb-0">Recharge Customer</h1>
    <div class="flex-fill"></div>
    <button mat-icon-button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- BODY -->
  <div *ngIf="rechargeForm">
    <p class="d-block w-100 mb-4">Please enter an amount you wish to charge.</p>

    <div>
      <odin-dynamic-form [formData]="rechargeForm"></odin-dynamic-form>
      <span class="d-block mt-1 text-muted"
        >💡 Tip: This amount will be charged immediately on completion. If this
        transaction declines the customers card may have been removed from
        file.</span
      >
    </div>
  </div>

  <odin-loading-spinner
    [active]="processing"
    [overlay]="true"
  ></odin-loading-spinner>
</div>
