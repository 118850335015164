export class TransactionReverse {
    constructor(public amount: number, //Major
    public merchantId: string,
    public countryCode: string = "826",
    public currencyCode: string = "826",
    public dispatch: string = "NOW",
    public messageType: string = "REVERSAL_KEYED",
    public responseAction: string = "JSON",
    public crossReference: string = "",
    public validityId: string = "",
    public operatorId: string = "",
    public operatorName: string = "") {}
}
