export enum ChartType {
  VerticalBarChart,
  StackedVerticalBarChart,
  GroupedVerticalBarChart,
  HorizontalBarChart,
  PieChart,
  AdvancedPieChart,
  PieChartGrid,
  LineChart,
  NormalizedAreaLineChart,
  AreaLineChart,
  StackedAreaChart,
}

export enum ChartSize {
  Full = '100%',
  ThreeQuater = '75%',
  TwoThird = '66%',
  Half = '50%',
  Third = '33%',
  Quater = '25%',
  Responsive = 'auto',
}
