<div class="py-3" *ngIf="trackedLink">
  <!-- Transaction Header -->
  <div class="px-3">
    <h1 class="mb-0">Payment Link Details</h1>
    <h2 class="mb-2">
      {{ trackedLink.amount / 100 | currency: trackedLink.currencyCodeIso }}
    </h2>
  </div>

  <div>
    <table class="table table-striped" style="table-layout: fixed">
      <tbody>
        <tr>
          <td>Created</td>
          <td>
            <strong>{{
              trackedLink.linkCreated | date: 'dd/MM/YY HH:mm'
            }}</strong>
          </td>
        </tr>

        <tr *ngIf="trackedLink.status !== 'Authorised'">
          <td>Expiry Time</td>
          <td class="d-flex align-items-center justify-content-start">
            <mat-icon class="outline {{ deliveryExpiryClass() }}">{{
              deliveryExpiryIcon()
            }}</mat-icon>
            <strong class="ms-1">
              <span *ngIf="hasExpired()"
                >Expired:
                {{
                  (trackedLink.linkExpiration | date: 'dd/MM/YY HH:mm a') ||
                    'N/A'
                }}</span
              >
              <span *ngIf="!hasExpired()"
                >{{ trackedLink.linkExpiration | relativeTime | titlecase }} ({{
                  trackedLink.linkExpiration | date: 'dd/MM/YY HH:mm a'
                }})</span
              ></strong
            >
          </td>
        </tr>

        <tr>
          <td>Link Type</td>
          <td class="d-flex align-items-center justify-content-start">
            <mat-icon class="outline">{{ deliveryIcon() }}</mat-icon>
            <strong class="ms-2">{{ deliveryName() }}</strong>
          </td>
        </tr>

        <tr *ngIf="trackedLink.delivery !== 'manual'">
          <td>Link Send Details</td>
          <td>
            <strong>{{
              trackedLink.delivery === 'email'
                ? trackedLink.emailAddress
                : trackedLink.phoneNumber
            }}</strong>
          </td>
        </tr>

        <tr>
          <td>Description</td>
          <td>
            <strong>{{ trackedLink.paymentDetail }}</strong>
          </td>
        </tr>

        <tr>
          <td>Customer Name</td>
          <td>
            <strong>{{ trackedLink.name || '-' }}</strong>
          </td>
        </tr>

        <tr>
          <td>Status</td>
          <td>
            <strong>{{ statusText() }}</strong>
          </td>
        </tr>

        <tr *ngIf="trackedLink.status === 'Cancelled'">
          <td>Cancellation Reason</td>
          <td>
            <strong>{{ trackedLink.cancellationReason || '-' }}</strong>
          </td>
        </tr>

        <tr>
          <td>Payment Link</td>
          <td class="d-flex align-items-center justify-content-start">
            <mat-icon
              [matTooltip]="'Copy Link'"
              matTooltipPosition="right"
              (click)="copyLink()"
              class="outline interactable interactable-text"
              >content_copy</mat-icon
            >
            <strong class="ms-2 d-block w-100 text-truncate">{{
              trackedLink.link
            }}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- PEBL ACTIONS -->
  <div class="px-3 d-flex align-items-center justify-content-end gap-2">
    <button
      [matTooltip]="''"
      mat-stroked-button
      class="me-1 mon-bg-danger mon-border-danger border mon-text-danger"
      [disabled]="actionButtonLoading"
      (click)="CancelLink()"
      *ngIf="trackedLink.status !== 'Authorised' && !hasExpired()"
    >
      Cancel Link
    </button>
    <button
      [matTooltip]="''"
      mat-stroked-button
      class="me-1 mon-bg-primary mon-border-primary border mon-text-primary"
      [disabled]="actionButtonLoading"
      (click)="ReinstateLink()"
      *ngIf="trackedLink.status !== 'Authorised' && hasExpired()"
    >
      Reinstate Link
    </button>
    <div class="flex-fill"></div>
    <button
      mat-flat-button
      class="me-1 bg-white-always mon-border-primary border mon-text-primary"
      [mat-dialog-close]
      cdkFocusInitialf
    >
      Close
    </button>
  </div>
</div>

<odin-loading-spinner
  [active]="actionButtonLoading"
  [overlay]="true"
></odin-loading-spinner>
