/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UnauthorisedComponent } from '../unauthorised/unauthorised.component';
import { CognitoService } from '../services/cognito-service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public authenticatedEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private router: Router,
    private cognitoService: CognitoService,
    @Inject('apiRoot') private _apiRoot: string,
  ) {}

  InjectToken(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.cognitoService.getAccessToken();
    const headers = req.headers.set('Authorization', `Bearer ${token}`);
    const authedReq = req.clone({ headers });
    return next.handle(authedReq);
  }

  RetryRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    err: HttpErrorResponse,
  ): Observable<HttpEvent<any>> {
    if (err.status == 401) {
      if (this.cognitoService.userLoggedIn()) {
        this.HandleUnauth();
      } else {
        this.logoutandroute();
      }
    }

    if (err.status === 403) {
      this.snackbar.open(
        'You are not authorised to access this resource',
        'Close',
        {
          duration: 3000,
        },
      );
    }
    return throwError(() => err);
  }

  HandleFail(
    req: HttpRequest<any>,
    next: HttpHandler,
    err: HttpErrorResponse,
  ): Observable<HttpEvent<any>> {
    return next.handle(req);
  }

  intercept(
    req: HttpRequest<any>,
    _next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // cant attach token to authorise.monek.com endpoint
    if (
      req.url.indexOf(`.monek.com`) > -1 &&
      req.url.indexOf('authorise.monek.com') == -1
    ) {
      if (req.headers.get('DONTRETRY') !== null) {
        return from(this.InjectToken(req, _next)).pipe(
          catchError((err: HttpErrorResponse) =>
            this.HandleFail(req, _next, err),
          ),
        );
      } else {
        return from(this.InjectToken(req, _next)).pipe(
          catchError((err: HttpErrorResponse) =>
            this.RetryRequest(req, _next, err),
          ),
        );
      }
    } else {
      return _next.handle(req);
    }
  }

  private HandleUnauth(): void {
    this.dialog
      .open(UnauthorisedComponent, {
        data: {},
        panelClass: 'no-padding',
      })
      .afterClosed()
      .subscribe(() => {
        // TODO: refresh token?
        this.logoutandroute();
      });
  }

  private logoutandroute(): void {
    this.cognitoService.logout();
    this.router.navigate(['/authentication/login']);
  }
}
