import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { InputDialogComponent } from '../input-dialog/input-dialog.component';
import { DialogAlertComponent } from '../dialog-alert/dialog-alert.component';
import { InputDialogConfig } from '../models/input-dialog-config';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private activeSnackbar?: MatSnackBarRef<TextOnlySnackBar>;

  constructor(
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  public InputDialog(
    detailConfig: InputDialogConfig,
    afterClosed: (data: string) => void,
    config?: MatDialogConfig,
  ): void {
    const dialogRef = this.dialog.open(InputDialogComponent, {
      data: detailConfig,
      panelClass: 'main-panel',
      ...config,
    });
    if (afterClosed != null)
      dialogRef.afterClosed().subscribe((data: string) => afterClosed(data));
  }

  public SmallDialog(
    message: string,
    action: string = 'Close',
    delay: number = 60000,
  ): void {
    this.ClearSmallDialog();
    this.activeSnackbar = this.snackbar.open(message, action, {
      duration: delay,
    });
  }

  public DialogWindow<T, R>(
    component: ComponentType<unknown>,
    dialogData: T,
    afterClosed: (data: R) => void,
    config?: MatDialogConfig,
  ): void {
    const dialogRef = this.dialog.open(component, {
      data: dialogData,
      panelClass: 'main-panel',
      ...config,
      autoFocus: false,
    });
    if (afterClosed != null)
      dialogRef.afterClosed().subscribe((data: R) => afterClosed(data));
  }

  public AlertDialogWindow<T, R>(reason: string): void {
    this.DialogWindow(DialogAlertComponent, { reason: reason }, () => {});
  }

  public ClearSmallDialog(): void {
    this.activeSnackbar?.dismiss();
  }
}
