<div>
<div class="py-3" *ngIf="peblFormData">
  <div class="px-2">
    <h1 class="mb-2">Payment Link Details</h1>
  </div>
  <div>
    <table class="table table-striped" style="table-layout: fixed">
      <tbody>
        <tr>
          <td>Amount</td>
          <td>
            <strong>{{ peblFormData.amount | currency: 'GBP' }}</strong>
          </td>
        </tr>

        <tr>
          <td>Description</td>
          <td>
            <strong>{{ peblFormData.paymentDetail }}</strong>
          </td>
        </tr>

        <tr>
          <td>Name</td>
          <td>
            <strong>{{ peblFormData.name }}</strong>
          </td>
        </tr>

        <tr>
          <td>Link Expires in</td>
          <td>
            <strong>{{
              LinkExpiryMapper(peblFormData.preparedPaymentMinutes)
            }}</strong>
          </td>
        </tr>

        <tr>
          <td>Send Link</td>
          <td>
            <strong>{{ SendTypeMapper(peblFormData.method) }}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="px-3 d-flex align-items-center justify-content-end gap-2">
    <button
      mat-stroked-button
      color="warn"
      [disabled]="processing"
      [mat-dialog-close]
      cdkFocusInitialf
    >
      Cancel
    </button>

    <button mat-flat-button color="primary" [disabled]="processing" (click)="MakePEBLRequest()">
      Submit
    </button>
  </div>
</div>
  <odin-loading-spinner
    [active]="processing"
    [overlay]="true"
  ></odin-loading-spinner>
</div>