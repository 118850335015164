import { Component, Inject } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITransaction } from '../../models';
import {
  Alignment,
  ConfirmationService,
  FormBuilder,
  FormData,
  InputType,
  MerchantService,
  MonekColour,
  NotificationService,
  Size,
  VTService,
} from '@odin/odin-core';
import { VTPaymentResponse } from '../../models/vt-payment';
import { CurrencyPipe } from '@angular/common';
import { TransactionReverse } from '../../models/transaction-reverse';

export class ReverseModalData {
  constructor(public transaction: ITransaction) {}
}

/** Error when invalid control is dirty, touched, or submitted. */
export class AmountErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'odin-reverse-transaction-modal',
  templateUrl: './reverse-transaction-modal.component.html',
  styleUrls: ['./reverse-transaction-modal.component.scss'],
})
export class ReverseTransactionModalComponent {
  public reverseForm: FormData<TransactionReverse> = this.BuildForm();
  public amountMatcher = new AmountErrorStateMatcher();
  public processing: boolean = false;

  constructor(
    private merchantService: MerchantService,
    public dialogRef: MatDialogRef<ReverseTransactionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReverseModalData,
    private VTService: VTService,
    private notifcationService: NotificationService,
    private confirmationService: ConfirmationService,
    private currencyPipe: CurrencyPipe,
  ) {
    this.reverseForm = this.BuildForm();
  }

  private BuildForm(): FormData<TransactionReverse> {
    const userProfile = this.merchantService.getUserprofile();
    const formData = new FormBuilder<TransactionReverse>(MonekColour.Primary)
      .ToggleSubmitButton(true)
      .AddFormField('OperatorId', '', InputType.Hidden, userProfile?.userId || '', Size.Full)
      .AddFormField('OperatorName', '', InputType.Hidden, userProfile?.email || '', Size.Full)
      .SubmitButtonConfig('Complete', Size.Full, Alignment.Center)
      .AddFormField(
        'PaymentChannel',
        '',
        InputType.Hidden,
        this.data.transaction.channel,
        Size.Auto,
      )
      .AddFormField('Amount', '', InputType.Hidden, this.data.transaction.amount, Size.Auto)
      .AddFormField('AuxiliaryData', '', InputType.Hidden, '', Size.Auto)
      .AddFormField('MerchantID', '', InputType.Hidden, '', Size.Auto)
      .AddFormField('CountryCode', '', InputType.Hidden, '826', Size.Auto)
      .AddFormField('CurrencyCode', '', InputType.Hidden, '826', Size.Auto)
      .AddFormField('ValidityID', '', InputType.Hidden, null, Size.Auto)
      .AddFormField(
        'CrossReference',
        '',
        InputType.Hidden,
        this.data.transaction.crossReference,
        Size.Auto,
      )
      .Build((data: TransactionReverse) => {
        this.makeReverse(data);
      });
    return formData;
  }

  private makeReverse(data: TransactionReverse): void {
    this.processing = true;

    this.confirmationService
      .ConfirmDecline(
        'Are you sure to process this reversal?',
        'By selecting “Yes” the reversal will be completed and the original customer will not be charged, please select to confirm.',
        'Yes',
        'Cancel',
        'primary',
        'warn',
      )
      .subscribe((resp: boolean) => {
        if (resp) this.executeReverse(data);
        else this.processing = false;
      });
  }
  private executeReverse(data: TransactionReverse): void {
    this.VTService.SubmitReverseRequest(data).subscribe(
      (_resp: VTPaymentResponse) => {
        if (_resp.status) {
          this.dialogRef.close(null);
          this.notifcationService.SmallDialog(
            'Reverse completed',
            'Close',
            60000,
          );
        } else {
          this.reverseForm.ShowError(`${_resp.traceId} - ${_resp.message}`);
        }
        this.processing = false;
      },
      () => {
        this.reverseForm.ShowError('Something went wrong.');
        this.processing = false;
      },
    );
  }

  // close without saving
  public cancel(): void {
    this.dialogRef.close(null);
  }
}
