import { Component, OnInit } from '@angular/core';
import { CognitoService } from '@odin/odin-authentication';
import { Setting, SettingsService } from '@odin/odin-settings';
import { MerchantUpdateService } from '@odin/odin-transactions';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment as env } from 'apps/odin-ui/src/environments/environment';

@Component({
  selector: 'odin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public envName: string = env.name;
  public verNumber: string = env.releaseVersion;

  get themeName(): string {
    const storedTheme = this.settingsService.GetNotNullSetting<string>(
      'themeName',
      'light',
    ).value;
    return storedTheme;
  }

  constructor(
    private cognitoService: CognitoService,
    private settingsService: SettingsService,
    private merchantChangeService: MerchantUpdateService,
  ) {
    document.body.classList.add(`monek-${this.themeName}-theme`);
    this.settingsService.settingChanged.subscribe((setting: Setting) => {
      if (setting.key == 'themeName') {
        document.body.classList.forEach((c) => {
          if (c.indexOf('-theme') > -1) {
            document.body.classList.remove(c);
          }
        });
        document.body.classList.add(`monek-${setting.value}-theme`);
      }
    });
  }

  ngOnInit(): void {
    this.cognitoService.refreshToken();
  }
}
