export class VTPaymentRequest {
  MerchantID: string = '';
  CountryCode: string = '';
  ResponseAction: string = '';
  CAType: string = '';
  AcceptCof: string = '';
  QAProducts: string = '';
  QAName: string = '';
  MessageType: string = '';
  Dispatch: string = '';
  CurrencyCode: string = '';
  Amount: number = 0;
  PaymentDetail: string = '';
  CardNumber: string = '';
  CV2: string = '';
  CardExpiryMonth: string = '';
  CardExpiryYear: string = '';
  CardExpiry: string = '';
  IdempotencyToken: string = '';
}

export class VTPaymentResponse {
  status: boolean = false;
  message: string = '';
  idempotencyToken: string = '';
  time: Date = new Date();
  traceId: string = '';
}
