<div class="py-3 px-2 w-100">
  <!-- HEADER -->
  <div class="d-flex align-items-center justify-content-between">
    <h1 class="mb-0">Reverse Transaction</h1>
    <div class="flex-fill"></div>
    <button mat-icon-button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- BODY -->
  <div *ngIf="reverseForm">
    <p class="d-block w-100 text-center mb-4">Are you sure you want to reverse this transaction?</p>
    <div>
      <odin-dynamic-form [formData]="reverseForm"></odin-dynamic-form>
      <span class="d-block mt-1 text-muted"
        >💡 Tip: By selecting complete, the full amount of this transaction will be voided and will <strong class="text-decoration-underline">not</strong> be included in your next settlement.</span
      >
    </div>
  </div>

  <odin-loading-spinner
    [active]="processing"
    [overlay]="true"
  ></odin-loading-spinner>
</div>
