/**
 * Class to represent confirm dialog model.
 * It has been kept here to keep it as part of shared component.
 */

export class ReportsDialogComponentModel {

  constructor(
    public csvMinDate: Date,
    public csvMaxDate: Date) { }
}
