<div class="form-container" *ngIf="formData">
  <ng-container *ngFor="let formField of formData.formFields">
    <ng-container
      *ngIf="isFormField(formField.formFieldType); else sectionHeader"
    >
      <odin-dynamic-input
        [formField]="formField"
        [disabledInput]="disabledInput"
        (valueChanged)="ValueChanged()"
      ></odin-dynamic-input>
    </ng-container>
    <ng-template #sectionHeader>
      <h1 *ngIf="formField.formFieldType === 'SECTION'" class="sectionHeader">{{ formField.sectionName }}</h1>
      <p *ngIf="formField.formFieldType === 'DETAIL'" class="sectionHeader text-center">{{ formField.sectionName }}</p>
    </ng-template>
  </ng-container>
  <div
    *ngIf="formData.submitButton"
    [ngStyle]="{ 'text-align': formData.submitAlignment }"
  >
    <div [ngStyle]="{ width: formData.submitSize, margin: SubmitAlignment() }" style="padding: 5px;">
      <button
        mat-flat-button
        [disabled]="!formValid"
        (click)="OnSubmit()"
        [color]="formData.theme"
        style="padding: 5px 20px; width: 100%"
        class="mon-bg-success mon-border-success border mon-text-success"
      >
        {{ formData.submitText }}
      </button>
    </div>
    <div *ngIf="errorCount > 0">
      <p style="margin: 1.34375em 0; text-align: center; color: red">
        There are one or more errors in your form, please fix these and try
        again.
      </p>
    </div>
  </div>

  <div *ngIf="formData.formError !== ''" class="mt-2">
    <p class="alert alert-danger text-center">{{ formData.formError }}</p>
  </div>
</div>
