<div
  *ngIf="formField && formField.type !== 'hidden'"
  class="field-container"
  [ngStyle]="{ width: formField.size }"
  [style.display]="formField.ownLine ? 'block' : 'inline-block'"
>
  <ng-container *ngIf="isOptionInput(formField.type) && !isDropdown(formField.type)">
    <div
      class="d-flex align-items-center justify-content-center gap-md-5 gap-2 flex-wrap"
    >
      <ng-container *ngFor="let option of formField.options">
        <button
          (click)="SetValue(option)"
          mat-flat-button
          class="m-0 py-2 px-3 rounded border-1 {{ formField.value?.toString() === option.value ? 'mon-bg-primary mon-text-primary' : 'mon-bg-transparent text-black' }} mon-border-primary border"
        >
          {{ option.viewName }}
        </button>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="!isOptionInput(formField.type)">
    <mat-form-field
      *ngIf="!isDateInput(formField.type); else datePicker"
      class="dynamic form-field"
      appearance="outline"
      [color]="Validate() ? theme : errorTheme"
    >
      <mat-label [class.error]="!Validate()">{{
        formField.viewName
      }}</mat-label>
      <span
        class="input-prefix-suffix"
        *ngIf="formField.data.prefixIcon"
        matPrefix
      >
        <ng-container [ngSwitch]="formField.data.prefixIcon.iconType">
          <mat-icon *ngSwitchCase="'ICON'">{{
            formField.data.prefixIcon.value
          }}</mat-icon>
          <img
            *ngSwitchCase="'IMAGE'"
            src="{{ formField.data.prefixIcon.value }}"
          />
        </ng-container>
      </span>
      <input
        [disabled]="disabledInput"
        matInput
        class="{{ formField.data.class }}"
        [attr.data-test]="formField.id"
        type="{{ formField.type === 'number' ? 'text' : formField.type }}"
        [attr.inputmode]="InputMode()"
        [(ngModel)]="formField.value"
        (ngModelChange)="CheckValidation()"
        (blur)="formField.onBlur(formField, $any($event.target).value)"
      />
      <span *ngIf="formField.data.sufixIcon" matSuffix>
        <mat-icon>{{ formField.data.sufixIcon }}</mat-icon>
      </span>
      <mat-hint class="error" *ngIf="!Validate()">{{
        GetValidationErrors()
      }}</mat-hint>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="isOptionInput(formField.type) && isDropdown(formField.type)">
    <mat-form-field
    appearance="outline"
    class="dynamic form-field"
    [color]="Validate() ? theme : errorTheme"
  >
    <mat-label [class.error]="!Validate()">{{
      formField.viewName
    }}</mat-label>
    <mat-select (selectionChange)="DropdownChange($event)">
      <ng-container *ngFor="let option of formField.options">
        <mat-option [value]="option.value">
          {{ option.viewName }}
        </mat-option>
      </ng-container>
    </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-template #datePicker>
    <mat-form-field
      appearance="outline"
      class="dynamic form-field"
      [color]="Validate() ? theme : errorTheme"
    >
      <mat-label [class.error]="!Validate()">{{
        formField.viewName
      }}</mat-label>
      <input
        matInput
        [attr.data-test]="formField.id"
        [(ngModel)]="formField.value"
      />
      <input
        matInput
        [matDatepicker]="picker"
        style="display: none"
        (dateChange)="CheckValidation()"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="picker"
        [attr.data-test]="formField.id"
      >
      </mat-datepicker-toggle>
      <mat-datepicker
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event)"
        (monthSelected)="chosenMonthHandler($event, picker)"
        #picker
      >
      </mat-datepicker>
      <mat-hint class="error" *ngIf="!Validate()">{{
        GetValidationErrors()
      }}</mat-hint>
    </mat-form-field>
  </ng-template>
</div>
