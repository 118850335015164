<div class="p-3 h-100 overflow-auto">
  <!-- PRE-REQ SCREEN -->
  <ng-container *ngIf="status === 'pre-req' && paymentMethod === undefined">
    <!-- TAKE QUIZ -->
    <mat-card appearance="outlined" class="main-panel p-2 p-md-4 mb-3">
      <div>
        <div
          class="w-100 mx-auto d-flex align-items-center justify-content-center gap-2 fs-4 mb-4"
        >
          <mat-icon class="text-danger flex-shrink-0">warning</mat-icon
          ><span>{{ merchantName() }} is currently not registered for PCI</span>
        </div>

        <p class="mb-1 text-decoration-underline">What is PCI compliance?</p>
        <p class="mb-1">
          PCI compliance is a set of security standards designed to protect
          credit card data.
        </p>
        <p class="mb-3">
          For further information on pci:dss and your responsibilities please
          refer to our document site by clicking
          <a
            target="_blank"
            href="https://documentation.monek.com/documentation/pcidss-for-merchants/"
            >here</a
          >.
        </p>

        <p class="mb-1 text-decoration-underline">
          How do I become PCI compliant?
        </p>
        <p class="mb-4">
          To become PCI compliant is really simple and will only take you 5
          minutes to answer a few questions. This must be done annually to help
          protect you and your customers from fraud.
        </p>
      </div>
      <div class="d-flex align-items-center justify-content-between w-100">
        <div>
          <button
            class="w-100 d-block btn btn-outline-primary"
            (click)="StartMethod()"
          >
            <div class="d-flex align-items-center justify-content-center gap-2">
              <span>Lets Get Started</span>
              <mat-icon>draw</mat-icon>
            </div>
          </button>
        </div>
        <img
          class="w-100 d-block"
          style="max-width: 75px"
          src="/assets/images/pci-dss.png"
        />
      </div>
    </mat-card>

    <!-- UPLOAD SPACE -->
    <mat-card appearance="outlined" class="main-panel p-2 p-md-4 mb-3">
      <div>
        <div class="fs-5 mb-2">
          <span>Do you already have a PCI DSS form ready to upload?</span>
        </div>

        <p class="mb-4">Please upload your PCI DSS form here:</p>
      </div>
      <div class="d-flex align-items-center justify-content-between w-100">
        <div
          class="d-flex align-items-center justify-content-start gap-3 w-100"
        >
          <div>
            <input
              type="file"
              (change)="pciFileChange($event)"
              required
              accept=".pdf"
              id="pci_doc"
              class="text-center m-auto d-none"
            />
            <label class="btn btn-outline-primary" for="pci_doc">
              <div
                class="d-flex align-items-center justify-content-center gap-2"
              >
                <span>{{
                  pciFile !== undefined ? 'Change File' : 'Select File'
                }}</span>
                <mat-icon>{{
                  pciFile !== undefined ? 'restore_page' : 'note_add'
                }}</mat-icon>
              </div>
            </label>

            <p class="mb-0 text-muted text-center">
              {{ pciFile !== undefined ? pciFile.name : 'No file selected' }}
            </p>
          </div>

          <div *ngIf="pciFile !== undefined" class="align-self-start">
            <button
              class="w-100 d-block btn btn-outline-primary align-self-start"
              (click)="UploadDoc()"
            >
              <div
                class="d-flex align-items-center justify-content-center gap-2"
              >
                <span>Upload</span>
                <mat-icon>upload_file</mat-icon>
              </div>
            </button>
          </div>
        </div>
        <img
          class="w-100 d-block"
          style="max-width: 75px"
          src="/assets/images/pci-dss.png"
        />
      </div>
    </mat-card>
  </ng-container>

  <!-- METHOD SELECT -->
  <ng-container *ngIf="status === 'incomplete'">
    <mat-card appearance="outlined" class="main-panel p-2 p-md-4 mb-3">
      <div
        class="mx-auto d-flex align-items-center justify-content-center gap-2 fs-4 mb-4 text-center"
      >
        <span>Please select the method in which you collect payments.</span>
      </div>

      <div>
        <ng-container *ngFor="let pcipm of paymentMethods">
          <div
            class="d-flex align-items-center justify-content-start gap-3 p-2 my-2 rounded interactable {{
              paymentMethod === pcipm.name ? 'bg-selected' : ''
            }}"
            (click)="SelectPaymentMethod(pcipm.name)"
          >
            <mat-icon class="fs-1">{{ pcipm.icon }}</mat-icon>
            <p class="mb-0 fs-5">{{ pcipm.viewName }}</p>

            <div class="flex-fill"></div>

            <mat-icon class="flex-shrink-0">{{
              paymentMethod === pcipm.name
                ? 'radio_button_checked'
                : 'radio_button_unchecked'
            }}</mat-icon>
          </div>
        </ng-container>
      </div>

      <div
        class="d-flex align-items-center justify-content-end mt-3 border-top pt-3"
      >
        <button (click)="ExitQuiz()" class="btn btn-outline-danger">
          Back
        </button>

        <div class="flex-fill w-100">
          <small
            *ngIf="paymentMethod === undefined"
            class="text-muted text-center mx-auto d-block"
          >
            Select an option to continue
          </small>
        </div>

        <button
          (click)="FinaliseMethod()"
          [disabled]="paymentMethod === undefined"
          class="btn btn-outline-primary"
        >
          Continue
        </button>
      </div>
    </mat-card>
  </ng-container>

  <!-- QUESTIONNAIRE -->
  <ng-container *ngIf="status === 'in-progress'">
    <mat-card appearance="outlined" class="main-panel p-2 p-md-4 mb-3">
      <ng-container
        *ngTemplateOutlet="question; context: { question: currentQuestion }"
      ></ng-container>
    </mat-card>
  </ng-container>

  <!-- FINAL STEP DOWNLOAD -->
  <ng-container *ngIf="status === 'complete'">
    <mat-card appearance="outlined" class="main-panel p-2 p-md-4 mb-3">
      <div
        class="w-100 mx-auto d-flex align-items-center justify-content-center gap-2 fs-4 mb-4"
      >
        <mat-icon class="fs-1 text-success flex-shrink-0">check</mat-icon
        ><span>Thank you for completing the questions.</span>
      </div>

      <div>
        <p class="text-center">
          Please download the file below to fill out and upload once completed.
        </p>

        <div class="my-3">
          <div
            class="my-2 d-flex align-items-center justify-content-start gap-3"
          >
            <div
              style="width: 24px; height: 24px"
              class="rounded-circle bg-dark p-2 text-white d-flex align-items-center justify-content-center"
            >
              <span>1</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-start gap-3 p-2 rounded flex-fill"
            >
              <mat-icon class="fs-1">file_download</mat-icon>
              <p class="mb-0 fs-5">Download the PCI document Below</p>

              <div class="flex-fill"></div>
            </div>
          </div>

          <div
            class="my-2 d-flex align-items-center justify-content-start gap-3"
          >
            <div
              style="width: 24px; height: 24px"
              class="rounded-circle bg-dark p-2 text-white d-flex align-items-center justify-content-center"
            >
              <span>2</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-start gap-3 p-2 rounded flex-fill"
            >
              <mat-icon class="fs-1">edit_document</mat-icon>
              <p class="mb-0 fs-5">
                Fill in your company details (Part 1a. Assessed merchant) and
                sign the document within the editable box's (3b. Merchant
                attestation)
              </p>

              <div class="flex-fill"></div>
            </div>
          </div>

          <div
            class="my-2 d-flex align-items-center justify-content-start gap-3"
          >
            <div
              style="width: 24px; height: 24px"
              class="rounded-circle bg-dark p-2 text-white d-flex align-items-center justify-content-center"
            >
              <span>3</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-start gap-3 p-2 rounded flex-fill"
            >
              <mat-icon class="fs-1">save</mat-icon>
              <p class="mb-0 fs-5">Save the document.</p>

              <div class="flex-fill"></div>
            </div>
          </div>

          <div
            class="my-2 d-flex align-items-center justify-content-start gap-3"
          >
            <div
              style="width: 24px; height: 24px"
              class="rounded-circle bg-dark p-2 text-white d-flex align-items-center justify-content-center"
            >
              <span>4</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-start gap-3 p-2 rounded flex-fill"
            >
              <mat-icon class="fs-1">upload_file</mat-icon>
              <p class="mb-0 fs-5">
                Upload the document in the PCI management page
              </p>

              <div class="flex-fill"></div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center gap-3">
          <div class="w-33">
            <button class="btn btn-primary" (click)="DownloadEndPCIDoc()">
              <div
                class="d-flex align-items-center justify-content-center gap-2"
              >
                <span>Download</span>
                <mat-icon>file_download</mat-icon>
              </div>
            </button>
          </div>
        </div>
        <div>
          <a class="text-muted" href="/dashboard/pci">Go Back</a>
        </div>
      </div>
    </mat-card>
  </ng-container>

  <!-- FAILED STEP DOWNLOAD -->
  <ng-container *ngIf="status === 'failed'">
    <mat-card appearance="outlined" class="main-panel p-2 p-md-4 mb-3">
      <div
        class="w-100 mx-auto d-flex align-items-center justify-content-center gap-2 fs-4 mb-4"
      >
        <mat-icon class="fs-1 text-danger flex-shrink-0">warning</mat-icon
        ><span
          >Due to the answers you have provided we can not automatically
          complete your PCI document</span
        >
      </div>

      <div>
        <p class="text-center">
          Please download the file below to fill out and upload once completed.
        </p>

        <div class="my-3">
          <div
            class="my-2 d-flex align-items-center justify-content-start gap-3"
          >
            <div
              style="width: 24px; height: 24px"
              class="rounded-circle bg-dark p-2 text-white d-flex align-items-center justify-content-center"
            >
              <span>1</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-start gap-3 p-2 rounded flex-fill"
            >
              <mat-icon class="fs-1">file_download</mat-icon>
              <p class="mb-0 fs-5">Download the PCI document Below</p>

              <div class="flex-fill"></div>
            </div>
          </div>

          <div
            class="my-2 d-flex align-items-center justify-content-start gap-3"
          >
            <div
              style="width: 24px; height: 24px"
              class="rounded-circle bg-dark p-2 text-white d-flex align-items-center justify-content-center"
            >
              <span>2</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-start gap-3 p-2 rounded flex-fill"
            >
              <mat-icon class="fs-1">print</mat-icon>
              <p class="mb-0 fs-5">
                Print and complete the PCI document in full.
              </p>

              <div class="flex-fill"></div>
            </div>
          </div>

          <div
            class="my-2 d-flex align-items-center justify-content-start gap-3"
          >
            <div
              style="width: 24px; height: 24px"
              class="rounded-circle bg-dark p-2 text-white d-flex align-items-center justify-content-center"
            >
              <span>3</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-start gap-3 p-2 rounded flex-fill"
            >
              <mat-icon class="fs-1">document_scanner</mat-icon>
              <p class="mb-0 fs-5">Scan the PCI document to your PC</p>

              <div class="flex-fill"></div>
            </div>
          </div>

          <div
            class="my-2 d-flex align-items-center justify-content-start gap-3"
          >
            <div
              style="width: 24px; height: 24px"
              class="rounded-circle bg-dark p-2 text-white d-flex align-items-center justify-content-center"
            >
              <span>4</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-start gap-3 p-2 rounded flex-fill"
            >
              <mat-icon class="fs-1">upload_file</mat-icon>
              <p class="mb-0 fs-5">
                Upload the document as a PDF in your PCI management page
              </p>

              <div class="flex-fill"></div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center gap-3">
          <div class="w-33">
            <button class="btn btn-primary" (click)="DownloadEndPCIDoc(true)">
              <div
                class="d-flex align-items-center justify-content-center gap-2"
              >
                <span>Download</span>
                <mat-icon>file_download</mat-icon>
              </div>
            </button>
          </div>
        </div>
        <div>
          <a class="text-muted" href="/dashboard/pci">Go Back</a>
        </div>
      </div>
    </mat-card>
  </ng-container>
</div>

<ng-template #question let-question="question">
  <div class="mt-4" *ngIf="question !== null && question !== undefined">
    <div
      class="mx-auto d-flex align-items-center justify-content-center gap-2 fs-4 mb-4 text-center"
    >
      <span class="lh-base">{{ question.question }}</span>
    </div>

    <div>
      <div
        class="d-flex align-items-center justify-content-start gap-3 p-2 my-2 rounded interactable {{
          question.stagedAnswer !== undefined && question.stagedAnswer === true
            ? 'bg-selected'
            : ''
        }}"
        (click)="StageAnswer(question, true)"
      >
        <mat-icon class="fs-1 text-success">check_circle</mat-icon>
        <p class="mb-0 fs-5">Yes</p>
        <div class="flex-fill"></div>
        <mat-icon>{{
          question.stagedAnswer !== undefined && question.stagedAnswer === true
            ? 'radio_button_checked'
            : 'radio_button_unchecked'
        }}</mat-icon>
      </div>

      <div
        class="d-flex align-items-center justify-content-start gap-3 p-2 my-2 rounded interactable {{
          question.stagedAnswer !== undefined && question.stagedAnswer === false
            ? 'bg-selected'
            : ''
        }}"
        (click)="StageAnswer(question, false)"
      >
        <mat-icon class="fs-1 text-danger">cancel</mat-icon>
        <p class="mb-0 fs-5">No</p>
        <div class="flex-fill"></div>
        <mat-icon>{{
          question.stagedAnswer !== undefined && question.stagedAnswer === false
            ? 'radio_button_checked'
            : 'radio_button_unchecked'
        }}</mat-icon>
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-end mt-3 border-top pt-3"
    >
      <button
        (click)="PreviousQuestion(question)"
        class="btn btn-outline-danger"
      >
        Back
      </button>

      <div class="flex-fill w-100">
        <small
          *ngIf="question.stagedAnswer === undefined"
          class="text-muted text-center mx-auto d-block"
        >
          Select an option to continue
        </small>
      </div>

      <button
        (click)="AnswerQuestion(question, question.stagedAnswer)"
        [disabled]="question.stagedAnswer === undefined"
        class="btn btn-outline-primary"
      >
        Continue
      </button>
    </div>
  </div>
</ng-template>
