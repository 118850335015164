import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TransactionState } from './transactions.init';
import { TRANSACTIONS_FEATURE_KEY } from './transactions.reducer';

export const getTransactionsState = createFeatureSelector<TransactionState>(
  TRANSACTIONS_FEATURE_KEY
);

const getTransactionsError = createSelector(
  getTransactionsState,
  (state: TransactionState) => state.error
);
const getTransactionsLoader = createSelector(
  getTransactionsState,
  (state: TransactionState) => state.loaded
);

const getTransactions = createSelector(
  getTransactionsState,
  (state: TransactionState) => {
    return state.transactions;
  }
);

const getMoreTransactions = createSelector(
  getTransactionsState,
  getTransactionsLoader,
  (state: TransactionState, isLoading) => {
    return isLoading ? [] : state.moreTransactions;
  }
);

const getTransactionsParams = createSelector(
  getTransactionsState,
  (state: TransactionState) => state.params
);
const getTransactionsAllowSelection = createSelector(
  getTransactionsState,
  (state: TransactionState) => state.allowSelection
);

const getHasNext = createSelector(
  getTransactionsState,
  (state: TransactionState) => state.pagination.hasNext
);
const getHasPrev = createSelector(
  getTransactionsState,
  (state: TransactionState) => state.pagination.hasPrev
);
const noMoreToLoad = createSelector(
  getTransactionsState,
  (state: TransactionState) =>
    state.transactions.length !== 0 &&
    !state.pagination.hasNext &&
    !state.loaded
);

const getMerchantRef = createSelector(
  getTransactionsState,
  (state: TransactionState) => state.merchantRef
)

const getCsvReportsError = createSelector(
  getTransactionsState,
  (state: TransactionState) => state.csvReportError
);
const getCsvReportsLoader = createSelector(
  getTransactionsState,
  (state: TransactionState) => state.csvReportLoading
);

export const transactionQuery = {
  getTransactionsError,
  getTransactionsLoader,
  getTransactions,
  getTransactionsParams,
  getTransactionsAllowSelection,
  getHasNext,
  getHasPrev,
  getMoreTransactions,
  noMoreToLoad,
  getMerchantRef,
  getCsvReportsError,
  getCsvReportsLoader
};
