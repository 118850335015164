<div class="py-3" *ngIf="formattedTransaction">
  <!-- Transaction Header -->
  <div class="px-3">
    <h1 class="mb-0">{{ transactionType }} Details</h1>
    <h2 class="mb-2 transaction-amount" data-test="transaction-details-amount">
      {{
        formattedTransaction().amount
          | currency: formattedTransaction().currencyCountry
      }}
    </h2>
  </div>
  <!-- Transaction Details -->
  <div>
    <table class="table table-striped" style="table-layout: fixed">
      <tbody>
        <tr *ngIf="formattedTransaction().operatorId !== undefined">
          <td>{{ transactionType }} Completed By</td>
          <td>
            <strong [attr.data-user-id]="formattedTransaction().operatorId">{{
              formattedTransaction().operatorName
            }}</strong>
          </td>
        </tr>

        <tr>
          <td>{{ transactionType }} Date</td>
          <td>
            <strong>{{
              formattedTransaction().createdOn | date: 'dd/MM/YY HH:mm'
            }}</strong>
          </td>
        </tr>

        <tr *ngIf="formattedTransaction().updatedOn !== null">
          <td>Last Updated</td>
          <td>
            <strong>{{
              formattedTransaction().updatedOn | date: 'dd/MM/YY HH:mm'
            }}</strong>
          </td>
        </tr>

        <tr>
          <td>{{ transactionType }} Status</td>
          <td class="d-flex align-items-center justify-content-start">
            <odin-chip-status
              [icon]="formattedTransaction().isReversed ? 'restore' : undefined"
              [status]="formattedTransaction().formattedStatus"
              [chipClass]="formattedTransaction().statusIconClass"
            ></odin-chip-status>
            <strong class="ms-2">{{
              formattedTransaction().formattedStatus
            }}</strong>
          </td>
        </tr>

        <tr>
          <td>{{ transactionType }} Type</td>
          <td class="d-flex align-items-center justify-content-start">
            <div style="width: 24px">
              <fa-icon
                class="d-block mx-auto"
                [icon]="transactionIcon(formattedTransaction())"
                size="lg"
              ></fa-icon>
            </div>
            <strong class="ms-2">{{
              formattedTransaction().formattedChannel
            }}</strong>
          </td>
        </tr>

        <tr>
          <td>Payment Description</td>
          <td *ngIf="showPaymentDescription()">
            <div
              class="d-flex align-items-center justify-content-start interactive"
            >
              <span>{{ formattedTransaction().paymentDetail }}</span>
            </div>
          </td>
          <td *ngIf="!showPaymentDescription()">
            <span>No description provided</span>
          </td>
        </tr>

        <tr>
          <td>Currency</td>
          <td>
            <strong>{{ formattedTransaction().currencyCountry }}</strong>
          </td>
        </tr>

        <tr>
          <td>Amount</td>
          <td>
            <strong>{{
              formattedTransaction().amount
                | currency: formattedTransaction().currencyCountry
            }}</strong>
          </td>
        </tr>

        <tr
          *ngIf="
            canFetchRelation() &&
            showRefundAmount() &&
            formattedTransaction().transactionType !== 'refund'
          "
        >
          <td>Amount Refunded</td>
          <td class="d-flex align-items-center justify-content-start">
            <mat-icon
              [matTooltip]="
                'Value of accepted refunds against this transaction.'
              "
              matTooltipPosition="right"
              >info</mat-icon
            >
            <strong class="ms-2">{{
              formattedTransaction().amountRefunded || 0
                | currency: formattedTransaction().currencyCountry
            }}</strong>
          </td>
        </tr>

        <tr
          *ngIf="
            canFetchRelation() &&
            formattedTransaction().remainingValue !==
              formattedTransaction().amount &&
            formattedTransaction().transactionType !== 'refund' &&
            formattedTransaction().amountRefunded !== undefined
          "
        >
          <td>Remaining Value</td>
          <td>
            <strong>{{
              formattedTransaction().remainingValue
                | currency: formattedTransaction().currencyCountry
            }}</strong>
          </td>
        </tr>

        <tr>
          <td>Card Number</td>
          <td>
            <strong>{{ formattedTransaction().maskedCard }}</strong>
          </td>
        </tr>

        <tr>
          <td>Card Type</td>
          <td class="d-flex align-items-center justify-content-start">
            <div
              class="d-flex align-items-center justify-content-center h-100 px-2 rounded"
              style="background-color: #fff; color: black"
            >
              <div style="width: 24px; height: 24px">
                <mat-icon
                  class="h-100 d-block"
                  *ngIf="formattedTransaction().cardIcon.iconType === 'ICON'"
                  >{{ formattedTransaction().cardIcon.value }}</mat-icon
                >
                <img
                  class="w-100 h-100 d-block"
                  *ngIf="formattedTransaction().cardIcon.iconType !== 'ICON'"
                  [src]="formattedTransaction().cardIcon.value"
                />
              </div>
            </div>
            <strong class="ms-2">{{ formattedTransaction().cardType }}</strong>
          </td>
        </tr>

        <tr>
          <td>Cardholder Name</td>
          <td>
            <strong>{{
              formattedTransaction().cardHolderDetails.name || '-'
            }}</strong>
          </td>
        </tr>

        <tr>
          <td>Identifier</td>
          <td>
            <strong>{{ formattedTransaction().crossReference }}</strong>
          </td>
        </tr>

        <tr *ngIf="canFetchRelation()">
          <td>Related transactions</td>
          <td *ngIf="relatedLoading()">
            <span>Loading...</span>
          </td>
          <td *ngIf="!relatedLoading() && relatedTransactions().length > 0">
            <ng-container *ngFor="let r_transaction of relatedTransactions()">
              <div
                *ngIf="canShowRelation(r_transaction.crossReference)"
                class="d-flex align-items-center justify-content-start interactive"
                (click)="ViewRelatedTransaction(r_transaction)"
              >
                <odin-chip-status
                  [status]="r_transaction.formattedStatus"
                  [chipClass]="r_transaction.statusIconClass"
                ></odin-chip-status>
                <span
                  >{{ r_transaction.crossReference }} ({{
                    r_transaction.formattedType
                  }})</span
                >
              </div>
            </ng-container>
          </td>
          <td *ngIf="!relatedLoading() && relatedTransactions().length === 0">
            <span>No related transactions</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <odin-print-receipt
    *ngIf="formattedTransaction() !== undefined"
    [receiptDetail]="createReceipt()"
  ></odin-print-receipt>

  <!-- TRANSACTION ACTIONS -->
  <div class="px-3 d-flex align-items-center justify-content-start gap-2">
    <div
      [matTooltip]="
        formattedTransaction() === null ||
        formattedTransaction().remainingValue === 0
          ? 'Unable to refund. Maximum refund amount against this transaction has been met.'
          : ''
      "
    >
      <button
        *ngIf="canRefund()"
        [disabled]="
          formattedTransaction() === null ||
          formattedTransaction().remainingValue === 0
        "
        mat-flat-button
        (click)="startRefundRequest()"
        class="d-flex align-items-center justify-content-between mon-text-danger mon-bg-danger mon-border-danger border"
      >
        <span>Refund</span>
        <mat-icon>undo</mat-icon>
      </button>
    </div>

    <div
      [matTooltip]="
        formattedTransaction() === null
          ? 'You can only reverse a transaction made on the same day'
          : ''
      "
    >
      <button
        *ngIf="canReverse()"
        [disabled]="formattedTransaction() === null"
        mat-flat-button
        (click)="startReverseRequest()"
        class="d-flex align-items-center justify-content-between mon-text-warning mon-bg-warning mon-border-warning border"
      >
        <span>Reverse</span>
        <mat-icon>restore</mat-icon>
      </button>
    </div>

    <div
      [matTooltip]="
        formattedTransaction() === null ? 'Unable to recharge.' : ''
      "
    >
      <button
        *ngIf="canReCharge()"
        [disabled]="formattedTransaction() === null"
        mat-flat-button
        (click)="startRechargeRequest()"
        class="d-flex align-items-center justify-content-between mon-text-success mon-bg-success mon-border-success border"
      >
        <span>Recharge</span>
        <mat-icon>autorenew</mat-icon>
      </button>
    </div>
    <div class="flex-fill"></div>

    <div *ngIf="canPrint()">
      <button
        mat-flat-button
        (click)="printReceipt()"
        class="d-flex align-items-center justify-content-between mon-text-primary mon-bg-primary mon-border-primary border"
      >
        <span>Print</span>
        <mat-icon>print</mat-icon>
      </button>
    </div>
    <button
      mat-stroked-button
      class="border mon-border-primary text-dark"
      [mat-dialog-close]
      cdkFocusInitialf
    >
      Close
    </button>
  </div>
</div>
