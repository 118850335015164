<div class="h-100 flex-grow-1">
  <cdk-virtual-scroll-viewport [itemSize]="rowSize" class="h-100">
    <div *cdkVirtualFor="let transaction of transactions; let i = index">
      <div
        [attr.data-test]="'transaction-item-' + i"
        [style.height.px]="rowSize"
        class="main-panel px-3 py-3 d-flex flex-row justify-content-between align-items-center gap-4"
      >
        <div
          class="align-self-stretch flex-shrink-0 d-none d-lg-inline px-2 rounded"
          style="background-color: #fff; color: black"
        >
          <div class="d-flex align-items-center justify-content-center h-100">
            <div style="width: 24px">
              <mat-icon>credit_score</mat-icon>
            </div>
          </div>
        </div>

        <span
          data-test="transaction-created-date"
          class="flex-grow-1 flex-shrink-0 d-none d-md-inline"
          >{{ transaction.createdOn | date: 'MMM dd, yyyy HH:mm:ss' }}</span
        >

        <span
          data-test="transaction-created-date"
          class="flex-grow-1 flex-shrink-0 d-inline d-md-none"
          >{{ transaction.createdOn | date: 'MMM dd HH:mm' }}</span
        >

        <div class="">
          <span data-test="transaction-cardholder-name" class="text-truncate">
            <span class="d-none d-sm-inline">Gross: </span
            >{{ transaction.amount | currency: transaction.currencyCountry }}
          </span>
        </div>

        <div class="">
          <span
            data-test="transaction-masked-card"
            matTooltip="{{
              transaction.settlement.totalFeeAmount | number: '1.6-6'
            }}"
          >
            <span class="d-none d-sm-inline">Fees: </span
            >{{
              transaction.settlement.totalFeeAmount
                | currency: transaction.currencyCountry : 'symbol' : '1.2-2'
            }}
          </span>
        </div>
        <span class="text-end" data-test="transaction-amount"
          ><span class="d-none d-sm-inline">Paid: </span
          >{{
            transaction.settlement.totalSettlementAmount
              | currency: transaction.currencyCountry
          }}</span
        >
      </div>
      <mat-divider></mat-divider>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
