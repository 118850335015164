import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OdinAuthenticationComponent } from './odin-authentication/odin-authentication.component';
import { OdinAuthenticationRoutingModule } from './odin-authentication/odin-authentication-routing.module';
import { LoginPageComponent } from './login-page/login-page.component';
import { OdinCoreModule } from '@odin/odin-core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { MatCardModule } from '@angular/material/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDialogModule,
    OdinCoreModule,
    MatCardModule,
    FontAwesomeModule,
    MatIconModule,
  ],
  declarations: [
    OdinAuthenticationComponent,
    LoginPageComponent,
    UnauthorisedComponent,
    AuthCallbackComponent,
  ],
  exports: [
    OdinAuthenticationComponent,
    OdinAuthenticationRoutingModule,
    LoginPageComponent,
    UnauthorisedComponent,
    AuthCallbackComponent,
  ],
  providers: [],
})
export class OdinAuthenticationModule {}
