<mat-drawer-container [hasBackdrop]="tabletQuery.matches" class="page-height">
  <mat-drawer
    #drawer
    [mode]="tabletQuery.matches ? 'over' : 'side'"
    [position]="'end'"
    [opened]="!tabletQuery.matches"
    [disableClose]="!tabletQuery.matches"
  >
    <!-- Actions in sidenav -->
    <div class="h-100">
      <div>
        <div class="p-2">
          <mat-form-field color="primary" appearance="outline" class="d-block">
            <mat-label>Start date</mat-label>
            <input
              matInput
              formControlName="date_min"
              [matDatepicker]="picker_start"
              data-test="filters-date-min"
              [value]="peblFilters.startDate"
              (dateChange)="startDateChange($event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker_start"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #picker_start
              data-test="date-picker-start"
            ></mat-datepicker>
            <mat-error data-test="filters-date-min-error">
              Invalid date
            </mat-error>
          </mat-form-field>

          <mat-form-field color="primary" appearance="outline" class="d-block">
            <mat-label>End date</mat-label>
            <input
              matInput
              formControlName="date_max"
              [matDatepicker]="picker_end"
              data-test="filters-date-max"
              [value]="peblFilters.endDate"
              (dateChange)="endDateChange($event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker_end"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #picker_end
              data-test="date-picker-end"
            ></mat-datepicker>
            <mat-error data-test="filters-date-max-error">
              Invalid date
            </mat-error>
          </mat-form-field>

          <mat-form-field class="d-block w-100">
            <mat-label>Link Status</mat-label>
            <mat-select
              [(value)]="peblFilters.status"
              class="d-block w-100"
              (selectionChange)="filterChange()"
            >
              <mat-option>All</mat-option>
              <mat-option value="Sent">Sent</mat-option>
              <mat-option value="Authorised">Complete</mat-option>
            </mat-select>
          </mat-form-field>

          <button
            mat-stroked-button
            class="bg-white-always mon-border-primary border mon-text-primary"
            data-test="filters-clear-btn"
            (click)="clearFilters()"
          >
            Clear Filters
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content
    class="ml-0 bg-panel page-height d-flex justify-content-between align-items-center flex-column p-md-2"
  >
    <!-- Transaction list header -->
    <mat-divider></mat-divider>
    <div
      class="d-flex justify-content-between align-items-center flex-row py-3 px-4 w-100 shadow main-panel"
      style="z-index: 10"
    >
      <button
        mat-icon-button
        class="bg-white-always mon-border-primary border mon-text-primary"
        (click)="RefreshLinks()"
        data-test="transactions-refresh-btn"
      >
        <mat-icon>refresh</mat-icon>
      </button>

      <div class="flex-fill"></div>

      <button
        class=""
        mat-icon-button
        class="bg-white-always mon-border-primary border mon-text-primary"
        (click)="drawer.open()"
        [disabled]="!tabletQuery.matches"
        data-test="transactions-filters-btn"
      >
        <mat-icon>filter_alt</mat-icon>
      </button>
    </div>

    <div class="w-100 h-100 flex-fill">
      <cdk-virtual-scroll-viewport [itemSize]="rowSize" class="h-100">
        <div *cdkVirtualFor="let trackedLink of trackingLinks; let i = index">
          <div
            class="interactive main-panel px-3 py-3 d-flex flex-row justify-content-between align-items-center gap-4"
            (click)="OpenDetails(trackedLink)"
          >
            <div class="flex-shrink-0">
              <mat-icon
                class="outline"
                [matTooltip]="rowDeliveryTooltip(trackedLink)"
                matTooltipPosition="right"
                >{{ rowDeliveryIcon(trackedLink) }}</mat-icon
              >
            </div>

            <span class="flex-grow-1 flex-shrink-0 d-none d-lg-block">{{
              trackedLink.linkCreated | date: 'dd/MM/yy'
            }}</span>

            <div
              class="d-flex justify-content-start align-items-center flex-grow-1 flex-shrink-0 w-100"
              style="max-width: 140px"
              [matTooltip]="
                (trackedLink.linkExpiration | date: 'd LLL yyyy HH:mm a') ||
                'N/A'
              "
            >
              <mat-icon
                *ngIf="trackedLink.status !== 'Authorised'"
                class="me-1 {{ rowDeliveryExpiryClass(trackedLink) }}"
                >{{ rowDeliveryExpiryIcon(trackedLink) }}</mat-icon
              >

              <mat-icon
                *ngIf="trackedLink.status === 'Authorised'"
                class="me-1 {{ rowDeliveryExpiryClass(trackedLink) }} outline"
                >credit_score</mat-icon
              >
              <span
                *ngIf="
                  trackedLink.status !== 'Authorised' &&
                  !hasExpired(trackedLink)
                "
                class="d-block"
                >{{
                  trackedLink.linkExpiration | relativeTime | titlecase
                }}</span
              >
              <span
                *ngIf="
                  trackedLink.status !== 'Authorised' && hasExpired(trackedLink)
                "
                >Expired</span
              >
              <span *ngIf="trackedLink.status === 'Authorised'">Paid</span>
            </div>

            <div class="d-none d-lg-inline flex-grow-1 flex-shrink-1 w-100">
              <span
                class="d-block w-100 text-truncate"
                style="min-width: 200px; max-width: 200px"
                [matTooltip]="trackedLink.name || '-'"
                >{{ trackedLink.name || '-' }}</span
              >
            </div>

            <div
              class="d-none d-xl-inline flex-grow-1 flex-shrink-1 w-100"
              [matTooltip]="trackedLink.paymentDetail"
            >
              <span class="d-block w-100 text-truncate" style="max-width: 140px"
                >Ref: {{ trackedLink.paymentDetail }}</span
              >
            </div>

            <span class="flex-grow-1 flex-shrink-1 w-100 text-end">{{
              trackedLink.amount / 100 | currency: trackedLink.currencyCodeIso
            }}</span>

            <div
              class="d-flex justify-content-center align-items-center rounded-circle d-lg-none {{
                rowDeliveryPillClass(trackedLink, false)
              }}"
            >
              <mat-icon class="m-0 outline">{{
                rowDeliveryPillIcon(trackedLink)
              }}</mat-icon>
            </div>
            <div
              class="flex-shrink-0 w-100 px-1 py-1 rounded-pill d-none d-lg-block {{
                rowDeliveryPillClass(trackedLink, true)
              }}"
              style="max-width: 100px"
            >
              <span class="d-block text-center">{{
                rowDeliveryPillText(trackedLink)
              }}</span>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>

        <div class="">
          <div class="p-2 end-0 w-100" style="z-index: 1000" *ngIf="loading">
            <odin-loading-spinner
              class="mt-2"
              [active]="true"
              [diameter]="40"
              [colour]="'primary'"
            ></odin-loading-spinner>
            <p class="m-0 text-center w-100">Loading...</p>
          </div>
          <div
            class="p-2 w-100"
            style="z-index: 1000"
            *ngIf="
              !loading &&
              !moreContent &&
              trackingLinks !== undefined &&
              trackingLinks.length > 0
            "
          >
            <p class="m-0 text-center w-100">No more links to load.</p>
          </div>
          <div
            class="p-2"
            *ngIf="
              !loading &&
              fetched &&
              trackingLinks !== undefined &&
              trackingLinks.length === 0
            "
          >
            <p class="m-0 mx-auto w-100 text-center">No links found!</p>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
