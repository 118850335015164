/* eslint-disable no-console */

import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { AccessGuardService } from '@odin/odin-authentication';
import {
  Alignment,
  DynamicFormComponent,
  FormBuilder,
  FormData,
  FormField,
  FormOption,
  FormValidation,
  InputIconType,
  InputPrefixSuffixType,
  InputType,
  MerchantChangeService,
  MerchantService,
  MonekColour,
  NotificationService,
  OptionInputType,
  Size,
  ValidationPattern,
} from '@odin/odin-core';
import {
  PEBLFormData,
  PEBLPaymentResponse,
  PeblSendType,
} from '@odin/odin-transactions';
import { PeblConfirmationDialogComponent } from '../pebl-confirmation-dialog/pebl-confirmation-dialog.component';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';

enum PeblFormStage {
  PAYMENT_DETAILS = 0,
  SENDER_METHOD = 1,
  SENDER_INFO = 2,
}

class PeblSendMethod {
  constructor(
    public sendType: PeblSendType,
    public viewName: string,
    public icon: string,
  ) {}
}

@Component({
  selector: 'odin-pebl-page',
  templateUrl: './pebl-page.component.html',
  styleUrls: ['./pebl-page.component.css'],
})
export class PeblPageComponent implements OnDestroy {
  public page: 'payment' | 'payment-success' = 'payment';
  public formStage: PeblFormStage = PeblFormStage.PAYMENT_DETAILS;
  public sendMethod: PeblSendType = PeblSendType.NONE;
  public linkResponse: PEBLPaymentResponse | undefined;
  public formSubmit: EventEmitter<void> = new EventEmitter<void>();
  public refreshFormState: EventEmitter<void> = new EventEmitter<void>();

  public peblForm: FormData<PEBLFormData> = this.BuildForm();
  public peblSendForm: FormData<{ sendAddress: string }> = this.BuildSendForm();
  private sendDetails: { sendAddress: string };
  public formsActive: boolean = true;

  public sendMethods: PeblSendMethod[] = [
    new PeblSendMethod(PeblSendType.EMAIL, 'E-mail', 'email'),
    new PeblSendMethod(PeblSendType.LINK, 'Copy Link', 'file_download'),
    new PeblSendMethod(PeblSendType.SMS, 'SMS', 'phone_iphone'),
  ];

  private merchantChangeSub: Subscription;

  constructor(
    private merchantService: MerchantService,
    private accessService: AccessGuardService,
    private notificationService: NotificationService,
    private merchantChangeService: MerchantChangeService,
  ) {
    this.merchantChangeSub =
      this.merchantChangeService.merchantChangeEvent.subscribe(() => {
        this.ResetPage();
        if (!this.GrantChecker())
          this.accessService.boot(
            'User does not have access to Pay By Link. Please contact an administrator',
          );
      });
  }

  ngOnDestroy(): void {
    if (this.merchantChangeSub !== undefined)
      this.merchantChangeSub.unsubscribe();
  }

  public GrantChecker(): boolean {
    return (
      this.accessService.hasGrant('txn:CreatePebl') &&
      environment.featureToggles['pebl-page']
    );
  }

  private SubmitForm(data: PEBLFormData): void {
    // update model
    data.method = this.sendMethod;

    if (data.method == PeblSendType.SMS)
      data.phoneNumber = this.sendDetails.sendAddress;
    else if (data.method == PeblSendType.EMAIL)
      data.emailAddress = this.sendDetails.sendAddress;

    this.notificationService.DialogWindow<
      PEBLFormData,
      { status: boolean; resp: PEBLPaymentResponse; errors: string[] }
    >(
      PeblConfirmationDialogComponent,
      data,
      (resp: {
        status: boolean;
        resp: PEBLPaymentResponse;
        errors: string[];
      }) => {
        if (resp !== undefined && resp !== null && resp.status) {
          this.page = 'payment-success';
          this.linkResponse = resp.resp;
        }
      },
      {
        maxWidth: '500px',
      },
    );
  }

  public copyRespText(): void {
    if (!this.linkResponse) return;
    navigator.clipboard.writeText(this.linkResponse.token);
    this.notificationService.SmallDialog('Copied to clipboard', 'Close', 3000);
  }

  public ResetPage(): void {
    this.formsActive = false;
    this.linkResponse = undefined;
    this.peblForm = this.BuildForm();
    this.peblSendForm = this.BuildSendForm();
    this.sendDetails = { sendAddress: '' };
    this.sendMethod = PeblSendType.NONE;
    this.formStage = PeblFormStage.PAYMENT_DETAILS;
    this.page = 'payment';
    this.formSubmit = new EventEmitter<void>();
    this.refreshFormState = new EventEmitter<void>();
    setTimeout(() => {
      this.formsActive = true;
    }, 100);
  }

  private BuildForm(): FormData<PEBLFormData> {
    const userProfile = this.merchantService.getUserprofile();
    const form = new FormBuilder<PEBLFormData>(
      MonekColour.Primary,
      'Link Details',
    )
      .ToggleSubmitButton(false)
      //.SubmitButtonConfig('Confirm', Size.Full, Alignment.Left)
      .AddFormField(
        'OperatorId',
        '',
        InputType.Hidden,
        userProfile?.userId || '',
        Size.Full,
      )
      .AddFormField(
        'OperatorName',
        '',
        InputType.Hidden,
        userProfile?.email || '',
        Size.Full,
      )
      .AddFormField('merchantId', '', InputType.Hidden, '', Size.Full)
      .AddFormField('settle', '', InputType.Hidden, 'NOW', Size.Full)
      .AddSection('Payment Details') // PAYMENT DETAIL SECTION HEADER
      .AddFormField(
        'amount',
        'Transaction Amount (£0.00)',
        InputType.Number,
        null,
        Size.Full,
        new FormValidation()
          .Required()
          .MinValue(0.01, 'Minimum transaction value is £0.01')
          .MaxValue(999999.99, 'Maxmimum transaction value is £999,999.99')
          .PatternMatch(ValidationPattern.Decimal, 'Amount must be a number'),
        false,
        {
          prefixIcon: new InputPrefixSuffixType(
            InputIconType.ICON,
            'currency_pound',
          ),
        },
        undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formfield: FormField, value: any) => {
          if (value !== '') {
            const numericValue = parseFloat(value);
            formfield.value = numericValue.toFixed(2);
            if (isNaN(value)) formfield.value = '0.00';
          }
        },
      )
      .AddFormField(
        'paymentDetail',
        'Reference or Description',
        InputType.Text,
        null,
        Size.Full,
        new FormValidation()
          .MaxLength(60, 'Detail can not exceed 60 characters')
          .Required(),
        false,
        {
          prefixIcon: new InputPrefixSuffixType(InputIconType.ICON, 'sell'),
        },
      )
      .AddFormField(
        'name',
        'Customer Name',
        InputType.Text,
        null,
        Size.Full,
        new FormValidation().Required().MaxLength(60),
        false,
        {
          prefixIcon: new InputPrefixSuffixType(InputIconType.ICON, 'face'),
        },
      )
      .AddDetail(
        'Please choose how long you want this payment link to be valid',
      )
      .AddOptionField(
        `preparedPaymentMinutes`,
        'Expiry Time',
        OptionInputType.Radio,
        '1440',
        [
          new FormOption('60', '1 hour'),
          new FormOption('1440', '24 Hours'),
          new FormOption('10080', '1 Week'),
          new FormOption('43800', '1 Month'),
        ],
        Size.Full,
        new FormValidation().Required(),
        true,
      )
      .OnChange((data: PEBLFormData, valid: boolean) => {
        if (valid) {
          this.sendMethod = PeblSendType.NONE;
          this.formStage = PeblFormStage.SENDER_METHOD;
        } else {
          this.sendMethod = PeblSendType.NONE;
          this.formStage = PeblFormStage.PAYMENT_DETAILS;
        }
      })
      .Build((data: PEBLFormData) => {
        this.SubmitForm(data);
      });

    return form;
  }

  private BuildSendForm(): FormData<{ sendAddress: string }> {
    let icon = new InputPrefixSuffixType(InputIconType.ICON, '');

    let validation = new FormValidation()
      .RequiredIf(!this.isLink(this.sendMethod))
      .PatternMatch(
        this.sendMethod === PeblSendType.EMAIL
          ? ValidationPattern.Email
          : this.sendMethod === PeblSendType.SMS
          ? ValidationPattern.UK_PHONE
          : ValidationPattern.AlphaNumeric,
      );

    if (
      this.sendMethod !== null &&
      this.sendMethod !== PeblSendType.NONE &&
      this.sendMethods !== undefined
    ) {
      const selectedSendMethod = this.sendMethods.filter(
        (sm) => sm.sendType == this.sendMethod,
      );
      if (selectedSendMethod.length !== 0) {
        icon = new InputPrefixSuffixType(
          InputIconType.ICON,
          selectedSendMethod[0].icon,
        );
      }

      if (this.sendMethod === PeblSendType.LINK)
        validation = new FormValidation();
      else if (this.sendMethod === PeblSendType.SMS)
        validation = validation.StartsWith(
          ['01', '02', '03', '07', '+441', '+442', '+443', '+447'],
          'Non valid UK Phone number format.',
        );
    }

    const form = new FormBuilder<{ sendAddress: string }>(
      MonekColour.Primary,
      'Send Details',
    )
      .ToggleSubmitButton(true)
      .SubmitButtonConfig('Confirm', Size.Half, Alignment.Center)
      .AddFormField(
        'sendAddress',
        this.customerDetailPlaceholder(this.sendMethod),
        InputType.Text,
        null,
        Size.Full,
        validation,
        false,
        {
          prefixIcon: icon,
        },
      )
      .OnChange((data: { sendAddress: string }, valid: boolean) => {})
      .Build((data: { sendAddress: string }) => {
        this.sendDetails = data;
        this.formSubmit.emit();
      });

    this.refreshFormState.emit();

    return form;
  }

  public SelectSendMethod(type: PeblSendType): void {
    this.sendMethod = type;
    this.peblSendForm = this.BuildSendForm();
    if (
      this.sendMethod !== PeblSendType.NONE &&
      this.formStage === PeblFormStage.SENDER_METHOD
    ) {
      this.formStage = PeblFormStage.SENDER_INFO;
    }
  }

  public isLink(type: PeblSendType): boolean {
    return type == PeblSendType.LINK;
  }

  public customerDetailPlaceholder(type: PeblSendType): string {
    switch (type) {
      case PeblSendType.EMAIL:
        return 'Recipient Email';
      case PeblSendType.SMS:
        return 'Recipient Phone Number (+44 **** ******)';
      case PeblSendType.LINK:
        return 'Link will be generated when you click confirm';
    }

    return '';
  }
}
