import { AbstractControl, ValidationErrors } from '@angular/forms';

/************************/
// Custom Amount Validator
// amount_max should be greater or equal with the amount_min
// only if amount_min is defined
/************************/

export class AmountValidator {
  static confirmed = (amount_min: string, amount_max: string) => {
    return (control: AbstractControl): ValidationErrors | null => {
      const input_min = control.get(amount_min);
      const input_max = control.get(amount_max);

      if (input_min?.value === null && input_max && !hasErrors(input_max)) {
        input_max?.setErrors(null);

        return null;
      }

      if (input_max?.value === null) {
        input_max?.setErrors(null);

        return null;
      }

      if (
        input_min &&
        input_min.value !== null &&
        input_max &&
        input_max.value !== null &&
        !hasErrors(input_max)
      ) {
        if (input_min.value > input_max.value) {
          input_max.setErrors({ amountInvalid: true });

          return { amountInvalid: true };
        } else {
          input_max.setErrors(null);

          return null;
        }
      }

      return null;
    };
  };
}

function hasErrors(control: AbstractControl) {
  return (
    control?.hasError('min') ||
    control?.hasError('max') ||
    control?.hasError('pattern')
  );
}
