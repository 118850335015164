export enum PeblSendType {
  NONE = 'NONE',
  EMAIL = 'email',
  LINK = 'manual',
  SMS = 'sms',
}

export class PEBLPaymentResponse {
  public message: string = '';
  public token: string = '';

  public Build(_token: string): PEBLPaymentResponse {
    this.token = _token;
    return this;
  }
}

export class PEBLFormData {
  public name: string = '';
  public monekId: string = '';
  public buid: string = '';
  public amount: number = 0; // major
  public paymentDetail: string = '';
  public currencyCode: string = '826';
  public countryCode: string = '826';
  public settle: string = '';
  public cof: boolean = false;

  public method: PeblSendType = PeblSendType.LINK;
  public preparedPaymentMinutes: string = '';
  public phoneNumber: string = '';
  public emailAddress: string = '';

  public OperatorId: string;
  public OperatorName: string;
}

export class PEBLPaymentRequest {
  public name: string = '';
  public monekId: string = '';
  public buid: string = '';
  public amount: number = 0; // major
  public paymentDetail: string = '';
  public currencyCode: string = '826';
  public countryCode: string = '826';
  public settle: string = '';
  public cof: boolean = false;
  public method: PaymentMethod | null = null;
  public OperatorId: string;
  public OperatorName: string;

  public build(data: PEBLFormData): PEBLPaymentRequest {
    this.name = data.name;
    this.monekId = data.monekId;
    this.buid = data.buid;
    this.amount = Math.round(data.amount * 100); // need to send in minor currency
    this.paymentDetail = data.paymentDetail;
    this.currencyCode = data.currencyCode;
    this.countryCode = data.countryCode;
    this.settle = data.settle;
    this.cof = data.cof;
    this.OperatorId = data.OperatorId;
    this.OperatorName = data.OperatorName;
    this.method = new PaymentMethod(
      Number.parseInt(data.preparedPaymentMinutes),
      data.method,
      data.phoneNumber,
      data.emailAddress,
    );
    return this;
  }

  public copy(existingLink: PEBLPaymentLink): PEBLPaymentRequest {
    const diff = Math.abs(
      new Date(existingLink.linkCreated).getTime() -
        new Date(existingLink.linkExpiration).getTime(),
    );
    const minutes = Math.floor(diff / 60000);

    this.name = existingLink.name;
    this.amount = existingLink.amount;
    this.paymentDetail = existingLink.paymentDetail;
    this.currencyCode = existingLink.currencyCode;
    this.countryCode = existingLink.countryCode;
    this.settle = existingLink.settle;
    this.OperatorId = existingLink.OperatorId;
    this.OperatorName = existingLink.OperatorName;
    this.method = new PaymentMethod(
      minutes,
      existingLink.delivery,
      existingLink.phoneNumber,
      existingLink.emailAddress,
    );

    return this;
  }
}

export class PaymentMethod {
  constructor(
    public preparedPaymentMinutes: number,
    public delivery: string = '',
    public phoneNumber: string = '',
    public emailAddress: string = '',
    public status: string = '',
    public type: string = '',
    public paidDate?: Date,
  ) {}
}

export class PEBLPaymentLink {
  public link: string = '';
  public amount: number = 0;
  public linkCreated: Date = new Date();
  public linkExpiration: Date = new Date();
  public currencyCode: string = '';
  public currencyCodeIso: string = '';
  public countryCode: string = '';
  public settle: string = '';
  public status: 'Sent' | 'Cancelled' | 'Expired' | 'Authorised';
  public delivery: 'email' | 'sms' | 'manual';
  public name: string = '';
  public phoneNumber: string = '';
  public emailAddress: string = '';
  public paymentReference: string = '';
  public paymentDetail: string = '';
  public storeCredentials: boolean = false;
  public debtRepayment: boolean = false;
  public paymentChannel: string = '';
  public cancellationReason: string;
  public OperatorId: string;
  public OperatorName: string;
}

export class PaymentLinkList {
  public lastEvaluatedKey: string = '';
  public paymentRequests: PEBLPaymentLink[] = [];
}
