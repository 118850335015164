// Combines 'loading' with data for a given http response, saves having a check for if the response came back and we have data
export class HttpResult<T> {
    constructor(public loading: boolean, public loaded: boolean, public data?: T |  null, public error?: string | null) {}

    public isComplete(): boolean {
        return this.loading === false && this.loaded === true;
    }

    public hasData(): boolean {
        return this.data !== null && this.data !== undefined;
    }

    public reset(): void {
        this.loading = true;
        this.loaded = false;
        this.error = null;
        this.data = null;
    }

    public requestComplete(data: T): void {
        this.loading = false;
        this.loaded = true;
        this.data = data;
    }

    public requestFailed(error?: string): void {
        this.loading = false;
        this.loaded = true;
        this.data = null;
        this.error = error;
    }
}