import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import MerchantPCI from '../models/merchant-pci';
import { HTTPCONSTANTS } from '../http/http-constants';
import { Injectable } from '@angular/core';
import { MerchantService } from './merchant.service';

@Injectable({ providedIn: 'root' })
export class MerchantPCIService {
  constructor(
    private http: HttpClient,
    private merchantService: MerchantService,
  ) {}

  public GetPCIDoc(
    paymentMethod: "ecom" | "vt" | "ip" | "multiple",
    doctype: 'BLANK' | 'HP',
  ): Observable<{ url: string }> {
    const url = `${HTTPCONSTANTS.protocol}${
      HTTPCONSTANTS.odinBaseAddress
    }/transactions/v1/merchants/${this.merchantService.getMerchantRef()}/pci-doc/${paymentMethod.toUpperCase()}/${doctype}`;
    return this.http.get<{ url: string }>(url);
  }

  public GetPCIForMerchant(): Observable<MerchantPCI> {
    const url = `${HTTPCONSTANTS.protocol}${
      HTTPCONSTANTS.odinBaseAddress
    }/transactions/v1/merchants/${this.merchantService.getMerchantRef()}/pci-status`;
    return this.http.get<MerchantPCI>(url);
  }

  public UploadPCIForm(pcifile: File): Observable<MerchantPCI> {
    const url = `${HTTPCONSTANTS.protocol}${
      HTTPCONSTANTS.odinBaseAddress
    }/transactions/v1/merchants/${this.merchantService.getMerchantRef()}/pci-doc`;
    const formdata: FormData = new FormData();
    formdata.append('pciDoc', pcifile, pcifile.name);
    return this.http.post<MerchantPCI>(url, formdata);
  }
}
