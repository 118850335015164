<mat-drawer-container class="full-height">
  <mat-drawer
    #drawer
    [mode]="'over'"
    [position]="'end'"
    [opened]="false"
    [disableClose]="true"
  >
  </mat-drawer>
  <mat-drawer-content>
    <!-- Swipe down container -->
    <odin-pull-to-refresh [isLoading]="loading$ | async">
      <div class="back-btn">
        <button
          mat-icon-button
          color="primary"
          (click)="goBackToSettlementsPage()"
          data-test="settlements-back-btn"
        >
          <fa-icon [icon]="backIcon" size="2x"></fa-icon>
        </button>
        <odin-settlement-daily-report-download
          (newDailySettlement)="onNewDailySettlementReport()"
          (newDailySettlementCSV)="onNewDailySettlementReportCSV()"
        ></odin-settlement-daily-report-download>
      </div>
      <div
        *ngIf="error$ | async; else settlements_content"
        class="error-settlements"
      >
        Unable to load transactions
      </div>
    </odin-pull-to-refresh>
  </mat-drawer-content>
</mat-drawer-container>

<!-- back to top button; when scrolled down -->
<odin-back-to-top [windowScrolled]="scroll$ | async"></odin-back-to-top>

<ng-template #settlements_content>
  <button
    (click)="loadMoreTransactions()"
    data-test="pagination-loader"
    style="display: none"
  ></button>
  <div
    class="settlements-container"
    *ngIf="settledTransactions$ | async as transactions"
  >
    <ng-container *ngIf="transactions.length > 0">
      <!-- SCROLL LIST -->
      <odin-settlement-daily-list
        [transactions]="transactions"
        [allowSelection]="allowSelection$ | async"
        [hasNext]="hasNext$ | async"
        [hasPrev]="hasPrev$ | async"
        [loading]="loading$ | async"
        (scrollDirection)="loadTransactionsOnScroll($event)"
      >
        <!-- PAGINATION -->
        <ng-container>
          <ng-container *ngIf="loading$ | async">
            <div class="settlements-bottom">
              <odin-loading-spinner
                [active]="true"
                [diameter]="40"
                [colour]="colour"
              ></odin-loading-spinner>
            </div>
          </ng-container>
          <ng-container
            *ngIf="transactions.length === 0 && (loading$ | async) === false"
          >
            <p class="settlements-bottom">End of transactions.</p>
          </ng-container>
          <ng-container *ngIf="noMoreToLoad$ | async">
            <div class="">
              <div style="max-width: 300px" class="w-75 mx-auto p-1">
                <p class="mb-0 w-100 text-center">
                  No more transactions to load.
                </p>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </odin-settlement-daily-list>

      <!-- FIXED FOOTER -->
      <mat-divider></mat-divider>
      <div
        class="d-flex justify-content-between align-items-center flex-row py-3 px-4 w-100 main-panel gap-4"
        style="z-index: 10; box-shadow: 0 -.5rem 1rem rgba(0,0,0,.15)!important"
      >
        <span class="flex-grow-1 flex-shrink-0 flex-fill d-none d-sm-block">Totals</span>

        <div>
          <span data-test="transaction-cardholder-name" class="text-truncate">
            Gross: {{ totalGrossAmount | currency: transactions[0].currencyCountry }}
          </span>
        </div>

        <div>
          <span
            data-test="transaction-masked-card"
            class="transaction-amount-title d-flex align-items-center"
            matTooltip="{{ totalFees | number:'1.6-6' }}"
          >
            <mat-icon class="icon-display">info</mat-icon>
            <span style="vertical-align: middle;">Fees: {{
              totalFees
              | currency: transactions[0].currencyCountry:'symbol':'1.2-2'
            }}</span>
          </span>
        </div>
        
        <span
          class=""
          data-test="transaction-amount"
          >Paid: {{
            totalSettledAmount | currency: transactions[0].currencyCountry
          }}</span
        >
      </div>

      <div *ngIf="false" class="total-footer-container footer-item main-panel">
        <div
          class="settled-transaction-item"
          [attr.data-test]="'settlement-item-total'"
        >
          <span
            class="settled-transaction-info"
            data-test="settled-transaction-name"
          >
            Totals
          </span>
          <div class="settled-transaction-amounts">
            <div class="settled-transaction-amount-title-div">
              <span
                data-test="settlement-gross-title"
                class="settled-transaction-amount-title"
                >Gross</span
              >
              <span data-test="settled-transaction-gross">
                {{
                  totalGrossAmount | currency: transactions[0].currencyCountry
                }}
              </span>
            </div>
            <div class="settled-transaction-amount-title-div">
              <span
                data-test="settlement-fees-title"
                class="settled-transaction-amount-title"
                >Fees</span
              >
              <span data-test="settled-transaction-fees">
                {{
                  totalFees
                    | currency: transactions[0].currencyCountry:'symbol':'1.6-6'
                }}
              </span>
            </div>
            <div class="settled-transaction-amount-title-div">
              <span
                data-test="settlement-net-title"
                class="settled-transaction-amount-title"
                >Paid</span
              >
              <span data-test="settled-transaction-net">
                {{
                  totalSettledAmount | currency: transactions[0].currencyCountry
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
