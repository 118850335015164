/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormData } from '../models/form-data';
import { Size } from '../models/size.enum';
import { MonekColour } from '../models/monek-colour.enum';
import { Alignment } from '../models/alignment.enum';
import { InputType, OptionInputType } from '../models/input-type.enum';
import { FormValidation } from '../models/form-validation';
import { FormField } from '../models/form-field';
import { FormOption } from '../models/form-option';

export class FormBuilder<T> {
  private formData: FormData<T>;

  constructor(
    public theme: MonekColour,
    public formName: string = 'form_name_not_set',
  ) {
    this.formData = new FormData<T>(theme, this.formName);
  }

  public ToggleSubmitButton(enabled: boolean): FormBuilder<T> {
    this.formData.submitButton = enabled;
    return this;
  }

  public SubmitButtonConfig(
    text: string,
    size: Size,
    alignment: Alignment,
  ): FormBuilder<T> {
    this.formData.submitText = text;
    this.formData.submitSize = size;
    this.formData.submitAlignment = alignment;
    return this;
  }

  public AddFormField(
    id: string,
    viewName: string,
    type: InputType,
    defaultValue: string | number | boolean | Date | null,
    size: Size,
    validation: FormValidation = new FormValidation(),
    ownLine: boolean = false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any = {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (
      value: string | number | boolean | Date | null,
      data: any,
      dataCallback: (data: any) => void,
      valCallback: (val: string | number | boolean | Date | null) => void,
    ) => void = () => {},
    onBlur: (formField: FormField, value: any) => void = () => {},
  ): FormBuilder<T> {
    this.formData.AddRawField(
      id,
      viewName,
      type,
      defaultValue,
      size,
      validation,
      ownLine,
      data,
      onChange,
      onBlur,
    );
    return this;
  }

  public AddOptionField(
    id: string,
    viewName: string,
    type: OptionInputType,
    defaultValue: string | number | boolean | null,
    options: FormOption[],
    size: Size,
    validation: FormValidation = new FormValidation(),
    ownLine: boolean = false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any = {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (
      value: string | number | boolean | Date | null,
      data: any,
      dataCallback: (data: any) => void,
      valCallback: (val: string | number | boolean | Date | null) => void,
    ) => void = () => {},
    onBlur: (formField: FormField, value: any) => void = () => {},
  ): FormBuilder<T> {
    this.formData.AddOptionField(
      id,
      viewName,
      type,
      options,
      defaultValue,
      size,
      validation,
      ownLine,
      data,
      onChange,
      onBlur,
    );
    return this;
  }

  public AddSection(name: string): FormBuilder<T> {
    this.formData.AddSection(name);
    return this;
  }

  public AddDetail(name: string): FormBuilder<T> {
    this.formData.AddDetail(name);
    return this;
  }

  public OnChange(callback: (data: T, valid: boolean) => void): FormBuilder<T> {
    this.formData.onChange = callback;
    return this;
  }

  public Build(callback: (data: T) => void): FormData<T> {
    this.formData.submitCallback = callback;
    return this.formData;
  }
}
