import { Injectable } from '@angular/core';
import { CardType } from '../models/card-type.enum';
import {
  InputIconType,
  InputPrefixSuffixType,
} from '../models/input-prefix-suffix-type';

@Injectable({ providedIn: 'root' })
export class CardHelperService {
  public GetCardType(cardNumber: string): CardType {
    try {
      if (cardNumber[0] == '2') return CardType.MASTERCARD;

      if (cardNumber[0] == '3') {
        if (cardNumber[1] == '0') return CardType.DINERSINT;
        if (cardNumber[1] == '6') return CardType.DINERSINT;
        if (cardNumber[1] == '8') return CardType.DINERSINT;
        if (cardNumber[1] == '4') return CardType.AMEX;
        if (cardNumber[1] == '7') return CardType.AMEX;
        if (cardNumber[1] == '5') return CardType.JCB;
      }

      if (cardNumber[0] == '4') return CardType.VISA;

      if (cardNumber[0] == '5') {
        if (cardNumber[1] == '1') return CardType.MASTERCARD;
        if (cardNumber[1] == '2') return CardType.MASTERCARD;
        if (cardNumber[1] == '3') return CardType.MASTERCARD;
        if (cardNumber[1] == '4') return CardType.MASTERCARD;
        if (cardNumber[1] == '5') return CardType.MASTERCARD;
        return CardType.MAESTRO;
      }

      if (cardNumber[0] == '6') {
        if (cardNumber[1] == '5') return CardType.DISCOVER;
        return CardType.MAESTRO;
      }

      return CardType.NONE;
    } catch (ex: unknown) {
      return CardType.NONE;
    }
  }

  public GetCardIcon(cardType: CardType): InputPrefixSuffixType {
    switch (cardType) {
      case CardType.AMEX:
        return new InputPrefixSuffixType(
          InputIconType.IMAGE,
          '../assets/images/card-amex.svg'
        );

      case CardType.DINERSINT:
        return new InputPrefixSuffixType(
          InputIconType.IMAGE,
          '../assets/images/card-dinersint.svg'
        );

      case CardType.DISCOVER:
        return new InputPrefixSuffixType(
          InputIconType.IMAGE,
          '../assets/images/card-discover.svg'
        );

      case CardType.JCB:
        return new InputPrefixSuffixType(
          InputIconType.IMAGE,
          '../assets/images/card-jcb.svg'
        );

      case CardType.MAESTRO:
        return new InputPrefixSuffixType(
          InputIconType.IMAGE,
          '../assets/images/card-maestro.svg'
        );

      case CardType.MASTERCARD:
        return new InputPrefixSuffixType(
          InputIconType.IMAGE,
          '../assets/images/card-mastercard.svg'
        );

      case CardType.VISA:
        return new InputPrefixSuffixType(
          InputIconType.IMAGE,
          '../assets/images/card-visa.svg'
        );

      default:
        return new InputPrefixSuffixType(InputIconType.ICON, 'credit_card');
    }
  }
}
