import { createReducer, on, Action } from '@ngrx/store';
import { TransactionFiltersParams } from '../../transactions-layout/models';
import * as TransactionsActions from './transactions.actions';
import { initialState, TransactionState } from './transactions.init';

export const TRANSACTIONS_FEATURE_KEY = 'transactions';

export function reducer(state: TransactionState | undefined, action: Action) {
  return transactionsReducer(state, action);
}

const transactionsReducer = createReducer<TransactionState>(
  initialState,
  on(TransactionsActions.loadTransactions, (state) => {
    return {
      ...state,
      transactions: [],
      params: new TransactionFiltersParams(
        1,
        state.params.take,
        state.params.query,
        state.params.filters
      ),
      moreTransactions: [],
      error: '',
      loaded: true,
    };
  }),
  on(TransactionsActions.loadTransactionsSuccess, (state, action) => {
    return {
      ...state,
      transactions: action.transactions,
      error: '',
      loaded: false,
    };
  }),
  on(TransactionsActions.loadTransactionsFailure, (state, action) => {
    return {
      ...state,
      transactions: [],
      error: action.error,
      loaded: false,
      pagination: {
        hasNext: false,
        hasPrev: false,
        pageCount: 0,
        pageSize: state.pagination.pageSize,
        currentPage: 0,
        totalPages: 0,
        totalCount: 0,
      },
    };
  }),
  on(TransactionsActions.transactionsFilters, (state, action) => {
    return {
      ...state,
      params: new TransactionFiltersParams(
        1,
        state.params.take,
        state.params.query,
        action.filters
      ),
    };
  }),
  on(TransactionsActions.transactionsSearchValue, (state, action) => {
    return {
      ...state,
      params: new TransactionFiltersParams(
        1,
        state.params.take,
        action.searchValue,
        state.params.filters
      ),
    };
  }),
  on(TransactionsActions.transactionsParams, (state, action) => {
    return {
      ...state,
      params: action.params,
    };
  }),
  on(TransactionsActions.transactionsAllowSelection, (state, action) => {
    return {
      ...state,
      allowSelection: action.allowSelection,
    };
  }),
  on(TransactionsActions.loadMore, (state) => {
    return {
      ...state,
      loaded: true,
      params: new TransactionFiltersParams(
        state.pagination.hasNext
          ? state.pagination.currentPage + 1
          : state.pagination.currentPage,
        state.params.take,
        state.params.query,
        state.params.filters
      ),
    };
  }),
  on(TransactionsActions.loadMoreSuccess, (state, action) => {
    return {
      ...state,
      transactions: state.transactions.concat(action.transactions),
      moreTransactions: action.transactions,
      loaded: false,
    };
  }),
  on(TransactionsActions.savePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        hasNext: action.pagination.HasNext,
        hasPrev: action.pagination.HasPrev,
        pageCount: action.pagination.PageCount,
        pageSize: action.pagination.PageSize,
        currentPage: action.pagination.CurrentPage,
        totalPages: action.pagination.TotalPages,
        totalCount: action.pagination.TotalCount,
      },
    };
  }),
  on(TransactionsActions.saveMerchantRef, (state, action) => {
    return {
      ...state,
      merchantRef: action.merchantRef
    };
  }),
  on(TransactionsActions.downloadCsvReport, (state) => {
    return {
      ...state,
      csvReportLoading: true,
      csvReportError: ''
    };
  }),
  on(TransactionsActions.downloadCsvReportSuccess, (state) => {
    return {
      ...state,
      error: '',
      csvReportLoading: false,
    };
  }),
  on(TransactionsActions.downloadCsvReportFailure, (state, action) => {
    return {
      ...state,
      csvReportError: action.error?.status == 400 ? action.error?.error : 'Error occurred when downloading the file. Please try again.',
      csvReportLoading: false
    };
  }),
  on(TransactionsActions.clearCsvReportError, (state) => {
    return {
      ...state,
      csvReportError: ''
    };
  })
);
