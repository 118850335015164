import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';

@Component({
  selector: 'odin-transaction-outcome-page',
  templateUrl: './transaction-outcome-page.component.html',
  styleUrls: ['./transaction-outcome-page.component.css'],
})
export class TransactionOutcomePageComponent implements OnInit {
  public status: string;

  constructor(private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.status = this.route.snapshot.paramMap.get('status') || 'fail';
  }
}
