import { Component, OnDestroy } from '@angular/core';
import MerchantPCI from '../models/merchant-pci';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';
import { MerchantPCIService } from '../services/merchant-pci.service';
import { ConfirmationService } from '../services/confirmation-service';
import { MerchantService } from '../services/merchant.service';
import { MerchantChangeService } from '../services/merchant-change.service';
import { Subscription } from 'rxjs';

export class PCI_PM {
  constructor(
    public name: 'ecom' | 'vt' | 'ip' | 'multiple',
    public viewName: string,
    public icon: string,
  ) {}
}

export class PCIQuestion {
  constructor(
    public id: number,
    public availableFor: ('ecom' | 'vt' | 'ip' | 'multiple')[],
    public question: string,
    public answer: boolean = false,
    public stagedAnswer?: boolean,
  ) {}
}

@Component({
  selector: 'odin-pci-form',
  templateUrl: './pci-form.component.html',
  styleUrls: ['./pci-form.component.scss'],
})
export class PciFormComponent implements OnDestroy {
  private mce: Subscription | undefined = undefined;
  constructor(
    private merchantService: MerchantService,
    private merchantChangeService: MerchantChangeService,
    private merchantPCIService: MerchantPCIService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private router: Router,
  ) {
    this.mce = this.merchantChangeService.merchantChangeEvent.subscribe(() => {
      this.status = 'pre-req';
      this.pciFile = undefined;
      this.answeredQuestions = [];
      this.paymentMethod = undefined;
      this.currentQuestion = undefined;
    });
  }

  public questions: PCIQuestion[] = [
    new PCIQuestion(
      1,
      ['ecom', 'vt', 'ip'],
      '(2.2.2) Do you ensure that default passwords are changed, such as on servers, POS systems, and terminals?',
    ),
    new PCIQuestion(
      2,
      ['ecom', 'vt'],
      '(3.1.1) Do you have security policies and operational procedures that all staff members are aware of?',
    ),
    new PCIQuestion(
      3,
      ['ecom', 'vt', 'ip'],
      '(6.3.1) Do you actively monitor for vulnerabilities on your system?',
    ),
    new PCIQuestion(
      4,
      ['ecom'],
      '(6.4) Do you have a process in place to ensure that only PSP scripts are authorized, and do you maintain an inventory of scripts used?',
    ),
    new PCIQuestion(
      5,
      ['ecom', 'vt'],
      '(8.2) Are all users given individual accounts with strong credentials?',
    ),
    new PCIQuestion(
      6,
      ['ecom', 'vt', 'ip'],
      '(11.3) Do you regularly conduct vulnerability scans on your systems at least every quarter, using an approved scanning vendor (ASV)?',
    ),
    new PCIQuestion(
      7,
      ['ecom', 'vt', 'ip'],
      '(12) Do you have a process in place to ensure compliance with Monek (assuming this is a specific compliance standard), and do you maintain a record of your contract?',
    ),
    new PCIQuestion(
      8,
      ['ecom', 'vt', 'ip'],
      '(12) Do you have a documented incident response plan?',
    ),
    new PCIQuestion(
      9,
      ['vt', 'ip'],
      '(2.3) Are wireless networks updated to remove default passwords and any vendor defaults?',
    ),
    new PCIQuestion(
      10,
      ['vt'],
      '(5.1) Is anti-malware deployed on all systems and kept up to date?',
    ),
    new PCIQuestion(
      11,
      ['ip'],
      '(1) Is a network diagram maintained for all network connections?',
    ),
    new PCIQuestion(
      12,
      ['ip'],
      '(9.5) Do you regularly inspect your terminals and POS systems for tampering?',
    ),
  ];

  public paymentMethods: PCI_PM[] = [
    new PCI_PM('ecom', 'E-Commerce', 'shopping_cart'),
    new PCI_PM('vt', 'Virtual Terminal', 'receipt_long'),
    new PCI_PM('ip', 'In Person (e.g. Your PAX card machine)', 'storefront'),
    new PCI_PM('multiple', 'Multiple Solutions Used', 'filter_2'),
  ];
  public status:
    | 'pre-req'
    | 'incomplete'
    | 'in-progress'
    | 'complete'
    | 'failed' = 'pre-req';
  public paymentMethod: 'ecom' | 'vt' | 'ip' | 'multiple' | undefined =
    undefined;

  public currentQuestion: PCIQuestion | undefined;
  public answeredQuestions: PCIQuestion[] = [];
  public pciFile: File | undefined;

  ngOnDestroy(): void {
    this.status = 'pre-req';
    this.pciFile = undefined;
    this.answeredQuestions = [];
    this.paymentMethod = undefined;
    this.currentQuestion = undefined;
    if (this.mce !== undefined) this.mce.unsubscribe();
  }

  //

  public merchantName(): string {
    return this.merchantService.getCurrentMerchantName() || 'Current Location';
  }

  public StartMethod(): void {
    this.status = 'incomplete';
  }

  public Back(): void {
    this.status = 'pre-req';
  }

  public SelectPaymentMethod(method: 'ecom' | 'vt' | 'ip' | 'multiple'): void {
    this.paymentMethod = method;
  }

  public FinaliseMethod(): void {
    if (this.paymentMethod === 'multiple') {
      this.status = 'failed';
    } else {
      this.status = 'in-progress';
      this.NextQuestion();
    }
  }

  public ExitQuiz(): void {
    this.status = 'pre-req';
    this.questions.forEach((q: PCIQuestion) => (q.stagedAnswer = undefined));
    this.answeredQuestions = [];
    this.currentQuestion = undefined;
    this.paymentMethod = undefined;
  }

  public StageAnswer(question: PCIQuestion, affirmative: boolean): void {
    if (!affirmative) {
      this.confirmationService
        .ConfirmDecline(
          'You selected no',
          'By selecting no to this question we can not automatically complete your PCI document, however you can still download it manually by selecting continue. For advice to on how to comply with PCI please visit our website.',
          'Continue',
          'Go Back',
          'primary',
          'warn',
        )
        .subscribe((resp: boolean) => {
          if (resp) {
            question.stagedAnswer = affirmative;
          } else {
            // dont stage the answer
            question.stagedAnswer = undefined;
          }
        });
    } else {
      question.stagedAnswer = affirmative;
    }
  }

  public PreviousQuestion(question: PCIQuestion): void {
    if (question.id === 1) {
      this.questions.forEach((q: PCIQuestion) => (q.stagedAnswer = undefined));
      this.answeredQuestions = [];
      this.currentQuestion = undefined;
      this.paymentMethod = undefined;
      this.status = 'incomplete';
      return;
    }
    const availableQuestions = this.questions
      .filter((q) => {
        if (this.paymentMethod === undefined) return false;
        const forMethod = q.availableFor.indexOf(this.paymentMethod) > -1;
        if (this.currentQuestion === undefined) return forMethod;
        return this.currentQuestion.id > q.id && forMethod;
      })
      .sort((a, b) => b.id - a.id);

    if (availableQuestions.length > 0)
      this.currentQuestion = availableQuestions[0];
  }

  public AnswerQuestion(question: PCIQuestion, affirmative: boolean): void {
    const existing = this.answeredQuestions.findIndex(
      (q) => q.id == question.id,
    );
    if (existing === -1) {
      this.answeredQuestions.push({ ...question, answer: affirmative });
    } else {
      this.answeredQuestions[existing] = { ...question, answer: affirmative };
    }

    if (!affirmative) {
      this.End('failed');
    } else {
      this.NextQuestion();
    }
  }

  private NextQuestion(): void {
    const availableQuestions = this.questions
      .filter((q) => {
        if (this.paymentMethod === undefined) return false;
        const forMethod = q.availableFor.indexOf(this.paymentMethod) > -1;
        if (this.currentQuestion === undefined) return forMethod;
        return this.currentQuestion.id < q.id && forMethod;
      })
      .sort((a, b) => a.id - b.id);

    if (availableQuestions.length > 0)
      this.currentQuestion = availableQuestions[0];
    else this.End('complete');
  }

  private End(status: 'incomplete' | 'complete' | 'failed'): void {
    this.pciFile = undefined;
    this.status = status;
  }

  public DownloadEndPCIDoc(forceBlank: boolean = false): void {
    if (this.paymentMethod === undefined) return;
    let docType: 'BLANK' | 'HP' = 'HP';
    if (forceBlank) docType = 'BLANK';

    this.merchantPCIService.GetPCIDoc(this.paymentMethod, docType).subscribe(
      (resp: { url: string }) => {
        setTimeout(() => {
          window.open(resp.url, '_blank');
        });
      },
      () => {
        this.notificationService.SmallDialog(
          `Couldn't download file, please try again`,
          'Close',
          5000,
        );
      },
    );
  }

  public pciFileChange(event: Event): void {
    if (event.target === null) return; // cant get files
    const files = (event.target as HTMLInputElement).files;
    if (files === null || files.length == 0) return; // no files
    if (files.length > 1) return; // too many files (not supported on input but for safety)
    this.pciFile = files[0]; // select file
  }

  public UploadDoc(): void {
    if (this.pciFile === undefined) return;

    this.confirmationService
      .ConfirmDecline(
        'Please Confirm',
        `This is to confirm that the PCI DSS Attestation of Compliance (AOC) for your registered company, has been completed, signed off, and is valid. By uploading this document, you are certifying that all the information included in the AOC is accurate to the best of your knowledge. Please ensure that the AOC reflects the current state of your organization's compliance with the PCI DSS standards, and all relevant details have been appropriately documented. This includes the assessment of our information security policies, procedures, and technical controls to safeguard payment card data. By submitting this AOC, you acknowledge your responsibility for the accuracy of the information provided. Any discrepancies or changes to the AOC should be reported promptly to the relevant stakeholders. Thank you for your diligence in maintaining our commitment to PCI DSS compliance. Should you have any questions or concerns, please do not hesitate to contact support for further assistance.`,
        'I Confirm',
        'Deny',
        'primary',
        'warn',
      )
      .subscribe((res: boolean) => {
        if (!res) return;
        if (this.pciFile === undefined) return;
        this.merchantPCIService.UploadPCIForm(this.pciFile).subscribe(
          (pci: MerchantPCI) => {
            this.notificationService.SmallDialog(
              'PCI document uploaded',
              'Close',
              5000,
            );
            this.router.navigate(['/dashboard']);
          },
          (err) => {
            this.notificationService.SmallDialog(
              'PCI failed to upload, please try again.',
              'Close',
              5000,
            );
          },
        );
      });
  }
}
