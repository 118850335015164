export enum ValidationPattern {
  Numeric = '^[0-9]*$',
  Decimal = '^[0-9]+([.][0-9]+)?$',
  Alpha = '^[a-zA-Z]+$',
  AlphaNumeric = '^[a-zA-Z0-9]+$',
  CardInput = '^[0-9- ]+([- ][0-9]+)$',
  Email = '^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$',
  UK_PHONE = '^(((\\+44\\s?\\d{4}|\\(?0\\d{4}\\)?)\\s?\\d{3}\\s?\\d{3})|((\\+44\\s?\\d{3}|\\(?0\\d{3}\\)?)\\s?\\d{3}\\s?\\d{4})|((\\+44\\s?\\d{2}|\\(?0\\d{2}\\)?)\\s?\\d{4}\\s?\\d{4}))(\\s?\\#(\\d{4}|\\d{3}))?$',
}

enum ValidationType {
  Required,
  Pattern,
  MinLength,
  MaxLength,
  ExactLength,
  IsDate,
  MinValue,
  MaxValue,
  StartsWith,
}

class Validator {
  constructor(
    public type: ValidationType,
    public comparison: string | Date | number | boolean | string[],
    public validator: (
      input: string | Date | number | boolean | null,
      comparison: string | Date | number | boolean | string[],
    ) => boolean,
    public errorMessage: string,
  ) {}
}

export class ValidationOutcome {
  constructor(
    public validationOutcome: boolean,
    public errors: string[],
  ) {}
}

export class FormValidation {
  private static DEFAULT_ERROR_MESSAGE = 'Invalid Input';
  private static REQUIRED_ERROR_MESSAGE = 'Required';

  private validators: Validator[] = [];

  // Validation
  public Validate(
    input: string | Date | number | boolean | null,
  ): ValidationOutcome {
    let valid = true;
    const errors: string[] = [];
    this.validators.forEach((validator: Validator) => {
      const _v = validator.validator(input, validator.comparison);
      if (_v == false) {
        valid = _v;
        errors.push(
          validator.errorMessage.replace(
            '|DYNAMIC|',
            validator.comparison.toString(),
          ),
        );
      }
    });
    return new ValidationOutcome(valid, errors);
  }

  // Validators
  private StartsWithValidate(
    input: string | Date | number | boolean | null,
    comparison: string | Date | number | boolean | string[],
  ): boolean {
    if (input == null) return false;
    if (typeof input !== 'string') return false;
    if (comparison == '' || input == '') return false;

    if (Array.isArray(comparison)) {
      let isValid = false;
      comparison.forEach((comp: string) => {
        const valid = input.startsWith(comp);
        if (valid) isValid = true;
      });
      return isValid;
    } else {
      if (typeof comparison !== 'string') return false;
      return input.startsWith(comparison);
    }
  }

  private PatternValidate(
    input: string | Date | number | boolean | null,
    comparison: string | Date | number | boolean | string[],
  ): boolean {
    if (input == null) return false;
    if (typeof input !== 'string') input = input.toString();
    if (typeof comparison !== 'string') return false;
    if (comparison == '' || input == '') return true;
    const matchRes = input.match(comparison);
    return matchRes != null && matchRes.length > 0;
  }

  private MinLengthValidate(
    input: string | Date | number | boolean | null,
    comparison: string | Date | number | boolean | string[],
  ): boolean {
    if (input == null) return false;
    if (typeof input !== 'string') return false;
    if (typeof comparison !== 'number') return false;
    return input.length >= comparison;
  }

  private MaxLengthValidate(
    input: string | Date | number | boolean | null,
    comparison: string | Date | number | boolean | string[],
  ): boolean {
    if (input == null) return false;
    if (typeof input !== 'string') return false;
    if (typeof comparison !== 'number') return false;
    return input.length <= comparison;
  }

  private ExactLengthValidate(
    input: string | Date | number | boolean | null,
    comparison: string | Date | number | boolean | string[],
  ): boolean {
    if (input == null) return false;
    if (typeof input !== 'string') return false;
    return input.length == comparison;
  }

  private RequiredValidate(
    input: string | Date | number | boolean | null,
    comparison: string | Date | number | boolean | string[],
  ): boolean {
    if (input == null) return false;
    if (comparison == null) return false;
    input = input.toString();
    return input != '' && input != null;
  }

  private MinValueValidate(
    input: string | Date | number | boolean | null,
    comparison: string | Date | number | boolean | string[],
  ): boolean {
    if (input == null) return false;
    input = Number.parseFloat(input.toString());
    if (isNaN(input)) return false;
    if (typeof input !== 'number') return false;
    if (typeof comparison !== 'number') return false;
    if (input == null) return false;
    if (comparison == null) return false;
    if (input < comparison) return false;
    return true;
  }

  private MaxValueValidate(
    input: string | Date | number | boolean | null,
    comparison: string | Date | number | boolean | string[],
  ): boolean {
    if (input == null) return false;
    input = Number.parseFloat(input.toString());
    if (isNaN(input)) return false;
    if (typeof input !== 'number') return false;
    if (typeof comparison !== 'number') return false;
    if (input == null) return false;
    if (comparison == null) return false;
    if (input > comparison) return false;
    return true;
  }

  private IsDateValidate(
    input: string | Date | number | boolean | null,
    comparison: string | Date | number | boolean | string[],
  ): boolean {
    if (input == null) return false;
    if (typeof input !== 'number' && typeof input !== 'string') return false;
    if (input == null || input == '') return true;
    if (comparison == null || comparison == '') return true;
    if (new Date(input).toString() == 'Invalid Date') return false;
    return true;
  }

  // Errors
  public Errors(input: string): string[] {
    return this.Validate(input).errors;
  }

  // Builder
  public StartsWith(
    startText: string[] | string,
    error: string = FormValidation.DEFAULT_ERROR_MESSAGE,
  ): FormValidation {
    this.validators.push(
      new Validator(
        ValidationType.StartsWith,
        startText,
        this.StartsWithValidate,
        error,
      ),
    );
    return this;
  }

  public PatternMatch(
    pattern: string,
    error: string = FormValidation.DEFAULT_ERROR_MESSAGE,
  ): FormValidation {
    this.validators.push(
      new Validator(
        ValidationType.Pattern,
        pattern,
        this.PatternValidate,
        error,
      ),
    );
    return this;
  }

  public MinLength(
    minLength: number,
    error: string = FormValidation.DEFAULT_ERROR_MESSAGE,
  ): FormValidation {
    this.validators.push(
      new Validator(
        ValidationType.MinLength,
        minLength,
        this.MinLengthValidate,
        error,
      ),
    );
    return this;
  }

  public MaxLength(
    maxLength: number,
    error: string = FormValidation.DEFAULT_ERROR_MESSAGE,
  ): FormValidation {
    this.validators.push(
      new Validator(
        ValidationType.MaxLength,
        maxLength,
        this.MaxLengthValidate,
        error,
      ),
    );
    return this;
  }

  public ExactLength(
    exactLength: number,
    error: string = FormValidation.DEFAULT_ERROR_MESSAGE,
  ): FormValidation {
    this.validators.push(
      new Validator(
        ValidationType.ExactLength,
        exactLength,
        this.ExactLengthValidate,
        error,
      ),
    );
    return this;
  }

  public MinValue(
    val: number,
    error: string = FormValidation.DEFAULT_ERROR_MESSAGE,
  ): FormValidation {
    this.validators.push(
      new Validator(ValidationType.MinValue, val, this.MinValueValidate, error),
    );
    return this;
  }

  public MaxValue(
    val: number,
    error: string = FormValidation.DEFAULT_ERROR_MESSAGE,
  ): FormValidation {
    this.validators.push(
      new Validator(
        ValidationType.MaxLength,
        val,
        this.MaxValueValidate,
        error,
      ),
    );
    return this;
  }

  public IsDate(
    error: string = FormValidation.DEFAULT_ERROR_MESSAGE,
  ): FormValidation {
    this.validators.push(
      new Validator(ValidationType.IsDate, true, this.IsDateValidate, error),
    );
    return this;
  }

  public RequiredIf(
    _if: boolean,
    error: string = FormValidation.REQUIRED_ERROR_MESSAGE,
  ): FormValidation {
    if (_if) {
      this.validators.push(
        new Validator(
          ValidationType.Required,
          true,
          this.RequiredValidate,
          error,
        ),
      );
    }
    return this;
  }

  public Required(
    error: string = FormValidation.REQUIRED_ERROR_MESSAGE,
  ): FormValidation {
    this.validators.push(
      new Validator(
        ValidationType.Required,
        true,
        this.RequiredValidate,
        error,
      ),
    );
    return this;
  }
}
