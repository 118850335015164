<mat-card style="max-height: 500px">
  <mat-card-header>
    <mat-card-title>Exports as CSV</mat-card-title>
    <mat-card-subtitle>Your selected date range:</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="d-flex flex-column pt-2">
    <p>
      <span class="me-1">From:</span> <span>{{ csvMinDate | date: 'dd MMM yyyy' }}</span>
    </p>
    <p>
      <span class="me-1">To:</span> <span>{{ csvMaxDate | date: 'dd MMM yyyy' }}</span>
    </p>

    <div *ngIf="dateRangeError" class="alert alert-danger">
      {{ dateRangeError }}
    </div>
    <div *ngIf="error$ | async as error" class="alert alert-danger">
      {{ error }}
    </div>

    <div mat-dialog-actions align="end" *ngIf="(loading$ | async) === false">
      <button (click)="onDismiss()" color="default" mat-stroked-button>
        Close
      </button>
      <button
        (click)="onConfirm()"
        color="primary"
        mat-raised-button
        *ngIf="!dateRangeError"
      >
        Download
      </button>
    </div>

    <ng-container *ngIf="loading$ | async">
      <odin-loading-spinner
        [active]="true"
        [diameter]="40"
        [colour]="'primary'"
      ></odin-loading-spinner>
    </ng-container>
  </mat-card-content>
</mat-card>
