<div class="py-3 px-2 w-100">
  <!-- HEADER -->
  <div class="d-flex align-items-center justify-content-between">
    <h1 class="mb-0">Refund Transaction</h1>
    <div class="flex-fill"></div>
    <button mat-icon-button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- BODY -->
  <div *ngIf="refundForm">
    <p class="d-block w-100 mb-4">
      Please enter an amount you wish refund, note you can not exceed the
      remaining transaction value of
      <strong>{{ data.transaction.remainingValue | currency: data.transaction.currencyCountry }}</strong>
    </p>

    <div>
      <odin-dynamic-form [formData]="refundForm"></odin-dynamic-form>
      <span class="d-block mt-1 text-muted"
        >💡 Tip: If partial refunds have already been processed then the
        remaining value will be lower than the original transaction value.</span
      >
    </div>
  </div>

  <odin-loading-spinner
    [active]="processing"
    [overlay]="true"
  ></odin-loading-spinner>
</div>
