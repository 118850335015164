<form [formGroup]="transactionsFiltersForm" class="pb-0">
  <mat-form-field color="primary" appearance="outline">
    <mat-label>Start date</mat-label>
    <input
      matInput
      formControlName="date_min"
      [min]="min_date"
      [max]="max_date"
      [matDatepicker]="picker_start"
      (dateChange)="startDateChanged()"
      data-test="filters-date-min"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="picker_start"
    ></mat-datepicker-toggle>
    <mat-datepicker
      #picker_start
      data-test="date-picker-start"
    ></mat-datepicker>
    <mat-error
      *ngIf="transactionsFiltersForm.controls.date_min.errors"
      data-test="filters-date-min-error"
    >
      Invalid date
    </mat-error>
  </mat-form-field>

  <mat-form-field color="primary" appearance="outline">
    <mat-label>End date</mat-label>
    <input
      matInput
      formControlName="date_max"
      [min]="min_date"
      [max]="max_date"
      [matDatepicker]="picker_end"
      data-test="filters-date-max"
    />
    <mat-datepicker-toggle matSuffix [for]="picker_end"></mat-datepicker-toggle>
    <mat-datepicker #picker_end data-test="date-picker-end"></mat-datepicker>
    <mat-error
      *ngIf="transactionsFiltersForm.controls.date_max.errors"
      data-test="filters-date-max-error"
    >
      Invalid date
    </mat-error>
  </mat-form-field>

  <mat-form-field color="primary" style="display: none;" appearance="outline">
    <mat-label>Min amount</mat-label>
    <fa-icon
      matPrefix
      [icon]="faCoins"
      class="coins-icon"
      data-test="filters-amount-prefix"
    ></fa-icon>
    <input
      matInput
      formControlName="amount_min"
      type="number"
      data-test="filters-amount-min"
    />
    <mat-error
      *ngIf="transactionsFiltersForm.controls.amount_min.errors"
      data-test="filters-amount-min-error"
    >
      <span
        [innerHTML]="
          displayAmountError(transactionsFiltersForm.controls.amount_min.errors)
        "
      ></span>
    </mat-error>
  </mat-form-field>

  <mat-form-field color="primary" style="display: none;" appearance="outline">
    <mat-label>Max amount</mat-label>
    <fa-icon
      matPrefix
      [icon]="faCoins"
      class="coins-icon"
      data-test="filters-amount-prefix"
    ></fa-icon>
    <input
      matInput
      formControlName="amount_max"
      type="number"
      data-test="filters-amount-max"
    />
    <mat-error
      *ngIf="transactionsFiltersForm.controls.amount_max.errors"
      data-test="filters-amount-max-error"
    >
      <span
        [innerHTML]="
          displayAmountError(transactionsFiltersForm.controls.amount_max.errors)
        "
      ></span>
    </mat-error>
  </mat-form-field>

  <mat-form-field color="primary" style="display: none;" appearance="outline">
    <mat-label>Filter by channel</mat-label>
    <mat-select formControlName="channel" data-test="filters-channel">
      <mat-option *ngFor="let channel of channels" [value]="channel">{{
        channel
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field color="primary" style="display: none;">
    <mat-label>Filter by status</mat-label>
    <mat-select formControlName="status" data-test="filters-status">
      <mat-option *ngFor="let status of statuses" [value]="status">{{
        status
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="d-flex align-items-center justify-content-between">
  <button
    mat-stroked-button
    class="bg-white-always mon-border-primary border mon-text-primary"
    data-test="filters-clear-btn"
    (click)="clear()"
  >
    Clear Filters
  </button>
  <button
    mat-mini-fab
    class="bg-white-always mon-border-primary border mon-text-primary me-0"
    id="filters-submit-btn"
    data-test="filters-submit-btn"
    (click)="applyFilters(transactionsFiltersForm.value)"
    [disabled]="
      !transactionsFiltersForm.valid || transactionsFiltersForm.pristine
    "
  >
    <mat-icon>done</mat-icon>
  </button>
  </div>
</form>

<div class="transactions-report pt-0">
  <hr />
  <button mat-stroked-button class="bg-white-always mon-border-primary border mon-text-primary" (click)="csvReportDownload()" [disabled]="!transactionsFiltersForm.valid">
    <fa-icon [icon]="faDownload" class="csv-download" data-test="csv-download-filters"></fa-icon> CSV 
  </button>
</div>