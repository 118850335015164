import { EventEmitter, Injectable } from '@angular/core';
import { BUID } from '../models/BUID';
import { MerchantService } from './merchant.service';

@Injectable({ providedIn: 'root' })
export class MerchantChangeService {
  constructor(private merchantService: MerchantService) {}

  public merchantChangeEvent: EventEmitter<string> = new EventEmitter<string>();

  setActiveMerchant(merchant: BUID): void {
    const mid = this.merchantService.getCurrentMerchant();
    if (mid !== null && merchant.id == mid.id) return;
    localStorage.setItem('current-merchant-ref', JSON.stringify(merchant));
    this.merchantChangeEvent.emit(merchant.id);
  }

  public getMerchantRefs(): BUID[] {
    try {
      const merchantRef = localStorage.getItem('merchant-ref');
      if (merchantRef == null) return [];
      const merchantRefs = JSON.parse(merchantRef) as BUID[];
      return merchantRefs;
    } catch {
      return [];
    }
  }
}
