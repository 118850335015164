import { ISettlement, ITransactionSettled } from "../../transactions-layout/models";

/* Initial settlements State */
const PAGE = 1;
const PER_PAGE = 31;
const SETTLEMENTS_PER_DATE = 50;

export interface SettlementState {
    merchantRef: string;
    settlements: ISettlement[];
    moreSettlements: ISettlement[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
    loaded: boolean;
    allowSelection: boolean;
    page: number;
    take: number;
    scrollPosition: number;
    pagination: {
      hasNext: boolean; // is a next page?
      hasPrev: boolean; // is previous page?
      pageCount: number; // no of settlements from the last request
      pageSize: number; // maximum no of settlements per page
      currentPage: number; // current page number
      totalPages: number; // total no of pages
      totalCount: number; // total no of settlements matching the filters
    },
    settledTransactions: {
      transactions: ITransactionSettled[];
      moreTransactions: ITransactionSettled[];
      settledDate: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: any;
      page: number;
      take: number;
      pagination: {
        hasNext: boolean; // is a next page?
        hasPrev: boolean; // is previous page?
        pageCount: number; // no of settlements from the last request
        pageSize: number; // maximum no of settlements per page
        currentPage: number; // current page number
        totalPages: number; // total no of pages
        totalCount: number; // total no of settlements matching the filters
      }
    },
    dailySettlementReportsLoading: boolean;
    monthlySettlementReportsLoading: boolean;
  }
  
  export const initialState: SettlementState = {
    merchantRef: '',
    settlements: [],
    moreSettlements: [],
    error: '',
    loaded: false,
    allowSelection: true,
    page: PAGE,
    take: PER_PAGE,
    scrollPosition: 0,
    pagination: {
      hasNext: false,
      hasPrev: false,
      pageCount: 0,
      pageSize: PER_PAGE,
      currentPage: 0,
      totalPages: 0,
      totalCount: 0,
    },
    settledTransactions: {
      transactions: [],
      moreTransactions: [],
      error: '',
      settledDate: '',
      page: PAGE,
      take: SETTLEMENTS_PER_DATE,
      pagination: {
        hasNext: false,
        hasPrev: false,
        pageCount: 0,
        pageSize: SETTLEMENTS_PER_DATE,
        currentPage: 0,
        totalPages: 0,
        totalCount: 0,
      }
    },
    dailySettlementReportsLoading: false,
    monthlySettlementReportsLoading: false,
  };
  