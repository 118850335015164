<!-- add security gif here -->
<div
  class="w-100 h-100 d-flex align-items-center justify-content-center main-panel"
>
  <div class="text-center">
    <div class="2">
      <odin-loading-spinner
        [active]="true"
        [showContent]="true"
        [diameter]="100"
        [strokeWidth]="5"
        [colour]="'primary'"
      >
        <mat-icon color="primary" class="display-3 w-100 h-100">lock</mat-icon>
      </odin-loading-spinner>
    </div>
    <p class="text h3 pt-2 mt-4">Securing Connection...</p>
  </div>
</div>
