<form (keydown.enter)="$event.preventDefault()" class="field-container">
  <div>
    <mat-form-field color="primary" class="monek-input flush" appearance="fill">
      <mat-icon matPrefix color="primary">search</mat-icon>
      <input
        [formControl]="searchControl"
        type="text"
        matInput
        maxlength="30"
        placeholder="{{ inputPlaceholder }}"
        data-test="search-input"
        (keyup)="checkSearchInputValue()"
      />
      <mat-icon
        color="warn"
        class="clear-search-input"
        matSuffix
        *ngIf="displayClearBtn"
        data-test="search-clear-btn"
        (click)="clearInput()"
        >close</mat-icon
      >
    </mat-form-field>
  </div>
</form>
