<div class="h-100 d-flex align-items-center justify-content-center">
  <div *ngIf="status === 'success'">
    <div
      class="d-flex align-items-center justify-content-center bg-light rounded-circle m-auto large-icon text-center mb-3"
    >
      <mat-icon class="d-block w-fit h-fit display-1 text-success"
        >done</mat-icon
      >
    </div>
    <h2 class="m-auto text-center">Your payment was successful!</h2>
  </div>

  <div *ngIf="status === 'fail'">
    <div
      class="d-flex align-items-center justify-content-center bg-light rounded-circle m-auto large-icon text-center mb-3"
    >
      <mat-icon class="d-block w-fit h-fit display-1 text-danger"
        >close</mat-icon
      >
    </div>
    <h2 class="m-auto text-center">Your payment was unsuccessful</h2>
    <p class="m-auto text-center">
      Select the original link again to try a successful payment
    </p>
  </div>
</div>
