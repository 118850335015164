import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HTTPCONSTANTS } from '../http/http-constants';
import {
  PEBLPaymentLink,
  PEBLPaymentRequest,
  PEBLPaymentResponse,
  PaymentLinkList,
  TransactionRecharge,
  TransactionRefund,
  TransactionReverse,
  VTPaymentRequest,
  VTPaymentResponse,
} from '@odin/odin-transactions';
import { MerchantService } from './merchant.service';

@Injectable({ providedIn: 'root' })
export class VTService {
  constructor(
    private http: HttpClient,
    private merchantService: MerchantService,
  ) {}

  public SubmitPaymentRequest(
    data: VTPaymentRequest,
  ): Observable<VTPaymentResponse> {
    data.CardExpiry = new Date(
      `${data.CardExpiryMonth}/28/20${data.CardExpiryYear}`,
    ).toISOString();

    const url = `${HTTPCONSTANTS.protocol}${
      HTTPCONSTANTS.odinBaseAddress
    }/transactions/v1/VT/${this.merchantService.getMerchantRef()}`;
    return this.http.post<VTPaymentResponse>(url, data);
  }

  public SubmitRefundRequest(
    data: TransactionRefund,
  ): Observable<VTPaymentResponse> {
    const url = `${HTTPCONSTANTS.protocol}${
      HTTPCONSTANTS.odinBaseAddress
    }/transactions/v1/VT/${this.merchantService.getMerchantRef()}/refund`;
    return this.http.post<VTPaymentResponse>(url, data);
  }

  public SubmitRechargeRequest(
    data: TransactionRecharge,
  ): Observable<VTPaymentResponse> {
    const url = `${HTTPCONSTANTS.protocol}${
      HTTPCONSTANTS.odinBaseAddress
    }/transactions/v1/VT/${this.merchantService.getMerchantRef()}/recharge`;
    return this.http.post<VTPaymentResponse>(url, data);
  }

  public SubmitReverseRequest(
    data: TransactionReverse,
  ): Observable<VTPaymentResponse> {
    const url = `${HTTPCONSTANTS.protocol}${
      HTTPCONSTANTS.odinBaseAddress
    }/transactions/v1/VT/${this.merchantService.getMerchantRef()}/reverse`;
    return this.http.post<VTPaymentResponse>(url, data);
  }

  public CreatePeblRequest(
    data: PEBLPaymentRequest,
  ): Observable<PEBLPaymentResponse> {
    const url = `${HTTPCONSTANTS.protocol}${
      HTTPCONSTANTS.baseAddress
    }/payment/${this.merchantService.getMerchantRef()}`;
    return this.http.post<PEBLPaymentResponse>(url, data);
  }

  public GetPeblLinks(
    filters: { startDate?: Date; endDate?: Date; status?: string },
    lastEvaluatedKey?: string,
  ): Observable<PaymentLinkList> {
    const filterList = [];
    if (filters.startDate != undefined)
      filterList.push(`minDate=${filters.startDate.toISOString()}`);
    if (filters.endDate != undefined) {
      filterList.push(`maxDate=${filters.endDate.toISOString()}`);
      if (filters.startDate == undefined)
        filterList.push(`minDate=${new Date('1990-01-01').toISOString()}`);
    } else {
      // end date required if start date is set
      if (filters.startDate != undefined) {
        const endDate = new Date();
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        filterList.push(`maxDate=${endDate.toISOString()}`);
      }
    }
    if (filters.status != undefined)
      filterList.push(`status=${filters.status}`);
    const filterQuery = filterList.join('&');
    const url = `${HTTPCONSTANTS.protocol}${
      HTTPCONSTANTS.baseAddress
    }/payment/${this.merchantService.getMerchantRef()}${
      lastEvaluatedKey === undefined || lastEvaluatedKey === ''
        ? `?${filterQuery}`
        : `?lastEvaluatedKey=${lastEvaluatedKey}&${filterQuery}`
    }`;
    return this.http.get<PaymentLinkList>(url);
  }

  public CancelPaymentLink(
    paymentLink: PEBLPaymentLink,
    reason: string,
  ): Observable<{ message: string }> {
    const url = `${HTTPCONSTANTS.protocol}${
      HTTPCONSTANTS.baseAddress
    }/payment/${this.merchantService.getMerchantRef()}?paymentReference=${
      paymentLink.paymentReference
    }&cancellationReason=${reason}`;
    return this.http.delete<{ message: string }>(url);
  }
}
