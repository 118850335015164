  <!-- Receipt print template -->
  <div *ngIf="receiptDetail" class="printable-screen py-4" #printTemplate>
    <h1 class="w-100 text-center fs-3 mb-4 text-decoration-underline">{{ receiptDetail.transactionName }}</h1>
    <h2 class="mb-1 pt-4">Thank you for your purchase</h2>
    <h3 class="mb-0">
      Receipt for transaction: <b>{{ receiptDetail.traceId }}</b>
    </h3>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Header</th>
          <th>Detail</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Cardholder Name</td>
          <td>{{ receiptDetail.custName }}</td>
        </tr>
        <tr>
          <td>Payment Description</td>
          <td>
            <i>{{ receiptDetail.detail }}</i>
          </td>
        </tr>
        <tr>
          <td>Date Completed</td>
          <td>{{ receiptDetail.time | date: 'short' }}</td>
        </tr>
        <tr *ngIf="receiptDetail.authCode">
          <td>Authorisation Code</td>
          <td>{{ receiptDetail.authCode }}</td>
        </tr>
        <tr>
          <td>Total Amount</td>
          <td>
            <b>{{ receiptDetail.amount | currency: receiptDetail.currencyCode }}</b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>