import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import { FormData } from '../../models/form-data';
import { FormFieldType } from '../../models/form-field';
import { Alignment } from '../../models/alignment.enum';

@Component({
  selector: 'odin-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public formData?: FormData<any>;
  @Input() public disabledInput: boolean = false;
  @Input() public submit: EventEmitter<void>;
  @Input() public refresh: EventEmitter<void>;

  public sectionType = FormFieldType.Section;
  public formValid = false;
  public errorCount = 0;

  ngOnInit(): void {
    this.ValueChanged();
    if (this.submit) this.submit.subscribe(() => this.OnSubmit());
    if (this.refresh) this.refresh.subscribe(() => this.Refresh());
  }

  public isFormField(fft: FormFieldType): boolean {
    if (fft === FormFieldType.Detail) return false;
    if (fft === FormFieldType.Section) return false;
    return true;
  }

  public ValueChanged(): void {
    if (!this.formData) return;
    const validationResponse = this.formData.Validate();
    this.formValid = validationResponse.valid;
    this.formData.onChange(this.formData.toObj(), this.formValid);
  }

  public OnSubmit() {
    if (!this.formData) return;
    this.formData.submitCallback(this.formData.toObj());
  }

  public Refresh() {
    this.disabledInput = true;
    setTimeout(() => {
      this.disabledInput = false;
      if (!this.formData) return;
      const validationResponse = this.formData.Validate();
      this.formValid = validationResponse.valid;
    }, 50);
  }

  public SubmitAlignment(): string {
    if (this.formData === undefined) return 'auto';
    if (this.formData.submitAlignment == Alignment.Center) return 'auto';
    if (this.formData.submitAlignment == Alignment.Left) return '0 auto auto';
    if (this.formData.submitAlignment == Alignment.Right) return 'auto auto 0';
    return 'auto';
  }
}
