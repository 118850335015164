import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TransactionState } from './transactions.init';
import { transactionQuery } from './transactions.selectors';
import {
  clearCsvReportError,
  downloadCsvReport,
  loadMore,
  loadTransactions,
  saveMerchantRef,
  savePagination,
  transactionsFilters,
  transactionsParams,
  transactionsSearchValue
} from './transactions.actions';
import {
  CsvReportRequest,
  TransactionFiltersInitial,
  TransactionFiltersParams,
} from '../../transactions-layout/models';

@Injectable({ providedIn: 'root' })
export class TransactionsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */

  getMerchantRef$ = this.store.pipe(
    select(transactionQuery.getMerchantRef)
  );

  getTransactionsLoader$ = this.store.pipe(
    select(transactionQuery.getTransactionsLoader)
  );

  loadTransactions$ = this.store.pipe(select(transactionQuery.getTransactions));

  loadMoreTransactions$ = this.store.pipe(
    select(transactionQuery.getMoreTransactions)
  );

  getParams$ = this.store.pipe(select(transactionQuery.getTransactionsParams));

  getError$ = this.store.pipe(select(transactionQuery.getTransactionsError));

  getAllowSelection$ = this.store.pipe(
    select(transactionQuery.getTransactionsAllowSelection)
  );

  getHasNext$ = this.store.pipe(select(transactionQuery.getHasNext));

  getHasPrev$ = this.store.pipe(select(transactionQuery.getHasPrev));

  noMoreToLoad$ = this.store.pipe(select(transactionQuery.noMoreToLoad));

  getCsvReportsError$ = this.store.pipe(select(transactionQuery.getCsvReportsError));

  getCsvReportsLoader$ = this.store.pipe(select(transactionQuery.getCsvReportsLoader));

  constructor(private readonly store: Store<TransactionState>) {
  }

  updateMerchantRef(mid: string, refreshData: boolean) {
    this.store.dispatch(saveMerchantRef({ merchantRef: mid }));

    // refresh transactions only if user is on transactions page
    if (refreshData) this.store.dispatch(loadTransactions());
  }

  saveFilters(filters: TransactionFiltersInitial) {
    this.store.dispatch(transactionsFilters({ filters }));
  }

  saveSearchValue(searchValue: string) {
    this.store.dispatch(transactionsSearchValue({ searchValue }));
  }

  saveParams(params: TransactionFiltersParams) {
    this.store.dispatch(transactionsParams({ params }));
  }

  // dispatch an action that calls the service
  getTransactions() {
    this.store.dispatch(loadTransactions());
  }

  loadMoreTransactions() {
    this.store.dispatch(loadMore());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  savePaginationValues(pagination: any) {
    this.store.dispatch(savePagination({ pagination }));
  }

  downloadCsvReport(csvReportRequest: CsvReportRequest) {
    this.store.dispatch(downloadCsvReport({ csvReportRequest }))
  }

  clearCsvReportError() {
    this.store.dispatch(clearCsvReportError());
  }
}
