import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettlementState } from './settlements.init';
import { SETTLEMENTS_FEATURE_KEY } from './settlements.reducer';

export const getSettlementsState = createFeatureSelector<SettlementState>(
  SETTLEMENTS_FEATURE_KEY
);

const getSettlementsError = createSelector(
  getSettlementsState,
  (state: SettlementState) => state.error
);
const getSettlementsLoader = createSelector(
  getSettlementsState,
  (state: SettlementState) => state.loaded
);

const getSettlements = createSelector(
  getSettlementsState,
  (state: SettlementState) => {
    return state.settlements;
  }
);

const getMoreSettlements = createSelector(
  getSettlementsState,
  getSettlementsLoader,
  (state: SettlementState, isLoading) => {
    return isLoading ? [] : state.moreSettlements;
  }
);

const getSettlementsAllowSelection = createSelector(
  getSettlementsState,
  (state: SettlementState) => state.allowSelection
);

const getHasNext = createSelector(
  getSettlementsState,
  (state: SettlementState) => state.pagination.hasNext
);

const getHasPrev = createSelector(
  getSettlementsState,
  (state: SettlementState) => state.pagination.hasPrev
);

const noMoreToLoad = createSelector(
  getSettlementsState,
  (state: SettlementState) =>
    state.settlements.length !== 0 && !state.pagination.hasNext && !state.loaded
);

const getQueryParams = createSelector(
  getSettlementsState,
  (state: SettlementState) => {
    return { page: state.page, take: state.take };
  }
);

const getSettledTransactions = createSelector(
  getSettlementsState,
  (state: SettlementState) => {
    return state.settledTransactions.transactions;
  }
);

const getMoreSettledTransactions = createSelector(
  getSettlementsState,
  getSettlementsLoader,
  (state: SettlementState, isLoading) => {
    return isLoading ? [] : state.settledTransactions.moreTransactions;
  }
);

const getSettledTransactionsError = createSelector(
  getSettlementsState,
  (state: SettlementState) => state.settledTransactions.error
);

const getSettledTransactionsHasNext = createSelector(
  getSettlementsState,
  (state: SettlementState) => state.settledTransactions.pagination.hasNext
);

const geSettledTransactionsHasPrev = createSelector(
  getSettlementsState,
  (state: SettlementState) => state.settledTransactions.pagination.hasPrev
);

const noMoreSettledTransactionsToLoad = createSelector(
  getSettlementsState,
  (state: SettlementState) =>
    state.settledTransactions.transactions.length !== 0 &&
    !state.settledTransactions.pagination.hasNext &&
    !state.loaded
);

const getSettledDate = createSelector(
  getSettlementsState,
  (state: SettlementState) => state.settledTransactions.settledDate
);

const getSettledTransactionsParams = createSelector(
  getSettlementsState,
  (state: SettlementState) => {
    return {
      date: state.settledTransactions.settledDate,
      page: state.settledTransactions.page,
      take: state.settledTransactions.take,
    };
  }
);

const getMerchantRef = createSelector(
  getSettlementsState,
  (state: SettlementState) => state.merchantRef
);

const getMonthlySettlementReportsLoader = createSelector(
  getSettlementsState,
  (state: SettlementState) => state.monthlySettlementReportsLoading
);

const getDailySettlementReportsLoader = createSelector(
  getSettlementsState,
  (state: SettlementState) => state.dailySettlementReportsLoading
);

const getScrollPosition = createSelector(
  getSettlementsState,
  (state: SettlementState) => state.scrollPosition
);

export const settlementsQuery = {
  getSettlementsError,
  getSettlementsLoader,
  getSettlements,
  getMoreSettlements,
  getSettlementsAllowSelection,
  getHasNext,
  getHasPrev,
  noMoreToLoad,
  getQueryParams,
  getSettledTransactions,
  getMoreSettledTransactions,
  getSettledTransactionsError,
  getSettledTransactionsHasNext,
  geSettledTransactionsHasPrev,
  noMoreSettledTransactionsToLoad,
  getSettledDate,
  getSettledTransactionsParams,
  getMerchantRef,
  getMonthlySettlementReportsLoader,
  getDailySettlementReportsLoader,
  getScrollPosition
};
