import { ChartBuildData } from '../models/chart-build-data';
import { ChartData } from '../models/chart-data';
import { ChartSize, ChartType } from '../models/chart-options';

export class ChartBuilder {
  private chartData: ChartBuildData = new ChartBuildData();

  constructor(chartName: string) {
    this.chartData.name = chartName;
  }

  public SetChartType(chartType: ChartType): ChartBuilder {
    this.chartData.chartType = chartType;
    return this;
  }

  public SetChartTypeOptions(chartTypes: ChartType[]): ChartBuilder {
    if (chartTypes != null && chartTypes.length > 0)
      this.chartData.chartType = chartTypes[0];
    this.chartData.chartTypeOptions = chartTypes;
    return this;
  }

  public SetChartSize(chartsize: ChartSize): ChartBuilder {
    this.chartData.size = chartsize;
    return this;
  }

  public SetChartHeight(height: number): ChartBuilder {
    this.chartData.height = height;
    return this;
  }

  public SetDimensions(x: number, y: number): ChartBuilder {
    const viewData: number[] = [x, y];
    this.chartData.viewData = viewData;
    return this;
  }

  public SetShowLegend(showLegend: boolean): ChartBuilder {
    this.chartData.showLegend = showLegend;
    return this;
  }

  public SetData(data: ChartData): ChartBuilder {
    const formattedData = data.getData(this.chartData.chartType);
    this.chartData.data = formattedData;
    return this;
  }

  public SetAxisLabels(x: string, y: string): ChartBuilder {
    this.chartData.xAxisLabel = x;
    this.chartData.yAxisLabel = y;
    return this;
  }

  public Build(): ChartBuildData {
    return this.chartData;
  }
}
