import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  ConfirmationModalComponent,
  ConfirmationModalDetail,
} from '../confirmation-modal/confirmation-modal.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
  constructor(private dialog: MatDialog) {}

  public ConfirmDecline(
    title: string,
    body: string,
    confirmText: string,
    declineText: string,
    confirmColour: 'primary' | 'accent' | 'warn',
    declineColour: 'primary' | 'accent' | 'warn',
  ): Observable<boolean> {
    const _ = this.dialog.open(ConfirmationModalComponent, {
      maxWidth: 450,
      data: new ConfirmationModalDetail(
        title,
        body,
        confirmText,
        declineText,
        confirmColour,
        declineColour,
      ),
    });
    return _.afterClosed();
  }
}
