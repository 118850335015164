import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'odin-settlements-toggle',
  templateUrl: './settlements-toggle.component.html',
  styleUrls: ['./settlements-toggle.component.scss'],
})
export class SettlementsToggleComponent implements OnInit {
  @Output() updateSettlementSelection = new EventEmitter<string>();
  settlementSelection!: FormControl;

  ngOnInit(): void {
    this.settlementSelection = new FormControl('dailySettlementSelected',
    [
      Validators.required,
    ]
    )
    this.changeSettlementType(this.settlementSelection.value)
  }

  public changeSettlementType(settlementSelection: string) {
    this.updateSettlementSelection.emit(settlementSelection)
  } 
}
