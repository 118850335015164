<div class="p-1 max-width">
  <mat-card>
    <mat-card-content>
      <h2 (click)="secretFetchToken($event)">Theme Name</h2>
      <div>
        <mat-radio-group
          aria-label="Select a theme"
          [(ngModel)]="themeName"
          color="primary"
        >
          <mat-radio-button value="light">Light</mat-radio-button>
          <mat-radio-button value="dark">Dark</mat-radio-button>
          <!-- <mat-radio-button value="pink">Pink</mat-radio-button> -->
        </mat-radio-group>
      </div>
    </mat-card-content>
  </mat-card>
</div>
