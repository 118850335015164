import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OdinSettingsPageComponent } from './odin-settings-page/odin-settings-page.component';

export const routes: Routes = [
  { path: '', component: OdinSettingsPageComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OdinSettingsRoutingModule {}
