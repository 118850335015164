<div class="login-form-container w-100 h-100 main-panel">
  <div class="w-100 mx-auto d-flex align-items-center justify-content-center flex-column gap-3">
    <div style="max-width: 300px" class="mx-auto d-block w-100">
      <div class="login-details-logo-container w-100">
        <img src="/assets/images/logo.png" style="max-width: 60px" class="w-100 d-block mx-auto" />
        <p class="text monek-font">MONEK</p>
      </div>
      <form class="w-100 mx-auto">
        <button class="w-100 form-item mon-bg-primary mon-text-primary" data-test="login-button" mat-flat-button
          (click)="Login()">
          <span class="mon-text-primary">LOGIN</span>
        </button>

      </form>
    </div>
    
    <div *ngIf="false" class="mt-4 border rounded-3 mon-border-warning mon-bg-warning overflow-hidden p-2">
      <p style="max-width: 450px;" class="d-block w-100 mx-auto px-3 mb-0 mon-text-warning text-center">We are aware
        of
        an issue with our authentication provider which is causing delivery failures on some of our login texts. We are
        working with our networking partner to resolve this issue ASAP.</p>
      <p style="max-width: 450px;" class="d-block w-100 mx-auto px-3 mt-2 mb-0 mon-text-warning text-center">In the meantime if
        you get this issue please contact support here: <a class="text-decoration-underline mon-text-primary"
          href="tel:0345 269 6645">0345 269 6645</a></p>
    </div>

    <odin-loading-spinner [active]="loggingIn" [overlay]="true"></odin-loading-spinner>
  </div>
</div>