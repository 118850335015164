import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SettlementsActionTypes } from './settlements.actions';
import { SettlementsFacade } from './settlements.facade';
import * as SettlementsAction from './settlements.actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { SettlementsService } from '../../transactions-layout/services';
import { SettlementReportsService } from '../../transactions-layout/services/settlement-reports.service';
import { MerchantService, NotificationService } from '@odin/odin-core';
import { AccessGuardService } from '@odin/odin-authentication';

@Injectable({ providedIn: 'root' })
export class SettlementsEffects {
  loadSettlements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettlementsActionTypes.LoadSettlements),
      withLatestFrom(
        this.settlementsFacade.getQueryParams$,
        this.settlementsFacade.getMerchantRef$,
      ),
      mergeMap(([, params, mid]) =>
        this.settlementsService
          .getSettlements(mid, params.page, params.take)
          .pipe(
            map((settlements) => {
              return SettlementsAction.loadSettlementsSuccess({ settlements });
            }),
            catchError((error) =>
              of(SettlementsAction.loadSettlementsFailure({ error })),
            ),
          ),
      ),
    ),
  );

  loadMoreSettlements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettlementsActionTypes.LoadMore),
      withLatestFrom(
        this.settlementsFacade.getQueryParams$,
        this.settlementsFacade.getMerchantRef$,
      ),
      mergeMap(([, params, mid]) =>
        this.settlementsService
          .getSettlements(mid, params.page, params.take)
          .pipe(
            map((settlements) => {
              return SettlementsAction.loadMoreSuccess({ settlements });
            }),
            catchError((error) =>
              of(SettlementsAction.loadSettlementsFailure({ error })),
            ),
          ),
      ),
    ),
  );

  loadSettledTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettlementsActionTypes.LoadSettledTransactions),
      withLatestFrom(
        this.settlementsFacade.getSettledTransactionsParams$,
        this.settlementsFacade.getMerchantRef$,
      ),
      mergeMap(([, params, mid]) =>
        this.settlementsService
          .getSettledTransactions(mid, params.date, params.page, params.take)
          .pipe(
            map((transactions) => {
              return SettlementsAction.loadSettledTransactionsSuccess({
                transactions,
              });
            }),
            catchError((error) =>
              of(SettlementsAction.loadSettledTransactionsFailure({ error })),
            ),
          ),
      ),
    ),
  );

  loadMoreSettledTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettlementsActionTypes.LoadMoreSettledTransactions),
      withLatestFrom(
        this.settlementsFacade.getSettledTransactionsParams$,
        this.settlementsFacade.getMerchantRef$,
      ),
      mergeMap(([, params, mid]) =>
        this.settlementsService
          .getSettledTransactions(mid, params.date, params.page, params.take)
          .pipe(
            map((transactions) => {
              return SettlementsAction.loadMoreSettledTransactionsSuccess({
                transactions,
              });
            }),
            catchError((error) =>
              of(
                SettlementsAction.loadMoreSettledTransactionsFailure({ error }),
              ),
            ),
          ),
      ),
    ),
  );

  getDailySettlementReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettlementsActionTypes.GetDailySettlementReport),
      withLatestFrom(this.settlementsFacade.getMerchantRef$),
      mergeMap(([action, mid]) =>
        this.settlementReportsService
          .getDailySettlementReport(action['date'], mid)
          .pipe(
            map((link) => {
              setTimeout(() => {
                window.open(link.url, '_blank');
              });
              return SettlementsAction.getDailySettlementReportSuccess();
            }),
            catchError((error) => this.displayDailyErrorMessage(error)),
          ),
      ),
    ),
  );

  getMonthlySettlementReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettlementsActionTypes.GetMonthlySettlementReport),
      withLatestFrom(this.settlementsFacade.getMerchantRef$),
      mergeMap(([action, mid]) =>
        this.settlementReportsService
          .getMonthlySettlementReport(action['date'], mid)
          .pipe(
            map((link) => {
              setTimeout(() => {
                window.open(link.url, '_blank');
              });
              return SettlementsAction.getMonthlySettlementReportSuccess();
            }),
            catchError((error) => this.displayMonthlyErrorMessage(error)),
          ),
      ),
    ),
  );

  getMonthlySettlementReportCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettlementsActionTypes.GetMonthlySettlementReportCSV),
      withLatestFrom(this.settlementsFacade.getMerchantRef$),
      mergeMap(([action, mid]) =>
        this.settlementReportsService
          .getMonthlySettlementReportCSV(action['date'], mid)
          .pipe(
            map((link) => {
              setTimeout(() => {
                window.open(link.url, '_blank');
              });
              return SettlementsAction.getMonthlySettlementReportSuccess();
            }),
            catchError((error) => this.displayMonthlyErrorMessage(error)),
          ),
      ),
    ),
  );

  getDailySettlementReportCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettlementsActionTypes.GetDailySettlementReportCSV),
      withLatestFrom(this.settlementsFacade.getMerchantRef$),
      mergeMap(([action, mid]) =>
        this.settlementReportsService
          .getDailySettlementReportCSV(action['date'], mid)
          .pipe(
            map((link) => {
              setTimeout(() => {
                window.open(link.url, '_blank');
              });
              return SettlementsAction.getDailySettlementReportSuccess();
            }),
            catchError((error) => this.displayDailyErrorMessage(error)),
          ),
      ),
    ),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private displayDailyErrorMessage(error: any) {
    this.notificationService.SmallDialog('Unable to download settlement file.');
    return of(SettlementsAction.getDailySettlementReportFailure({ error }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private displayMonthlyErrorMessage(error: any) {
    this.notificationService.SmallDialog('Unable to download settlement file.');
    return of(SettlementsAction.getMonthlySettlementReportFailure({ error }));
  }

  constructor(
    private readonly actions$: Actions,
    private readonly settlementsFacade: SettlementsFacade,
    private settlementsService: SettlementsService,
    private merchantService: MerchantService,
    private settlementReportsService: SettlementReportsService,
    private notificationService: NotificationService,
  ) {
    this.settlementsFacade.updateMerchantRef(
      this.merchantService.getMerchantRef(),
      false,
    );
  }
}
