import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTPCONSTANTS } from '@odin/odin-core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { ISettlement, ITransactionSettled } from '../models';

@Injectable()
export class SettlementsService {
  private readonly baseUrl: string = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.odinBaseAddress}`;
  private selectedSummary!: ISettlement;

  constructor(private readonly http: HttpClient) {}

  setSelectedSummary(summary: ISettlement): void {
    this.selectedSummary = summary;
  }

  getSelectedSummary(): ISettlement {
    return this.selectedSummary;
  }

  getSettlements(
    merchantRef: string,
    page: number,
    take: number,
  ): Observable<ISettlement[]> {
    const today = moment().format('YYYY-MM-DD');

    const url = this.constructUrlWithQueryString(
      merchantRef,
      today,
      page,
      take,
      'daily',
    );

    return this.http.get<ISettlement[]>(url);
  }

  getSettledTransactions(
    merchantRef: string,
    date: string,
    page: number,
    take: number,
  ): Observable<ITransactionSettled[]> {
    const url = this.constructUrlWithQueryString(
      merchantRef,
      date,
      page,
      take,
      'date',
    );

    return this.http.get<ITransactionSettled[]>(url);
  }

  getTotalSummaryForDay(
    merchantRef: string,
    date: Date,
  ): Observable<ISettlement[]> {
    const dateString = moment(date).format('YYYY-MM-DD');
    const url = this.constructUrlWithQueryString(
      merchantRef,
      dateString,
      0,
      1,
      'daily',
    );
    return this.http.get<ISettlement[]>(url);
  }

  private constructUrlWithQueryString(
    merchantRef: string,
    date: string,
    page: number,
    take: number,
    type: string,
  ): string {
    let url = this.constructUrl(merchantRef);

    const queryString = `page=${page}&take=${take}`;

    return (url += `/${type}/${date}?${queryString}`);
  }

  private constructUrl(merchantRef: string): string {
    return `${this.baseUrl}/transactions/v1/settlements/${merchantRef}`;
  }
}
