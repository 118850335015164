<div class="py-3 pb-3 pb-md-0 px-3 px-md-0 w-fit w-100">
  <mat-card appearance="outlined" class="main-panel py-2 px-3 border">
    <!-- COMPLIANT UI -->
    <div
      *ngIf="!loading && compliant"
      class="d-block d-md-flex justify-content-between align-items-center gap-3"
    >
      <div
        class="mx-auto mx-md-0 d-flex align-items-center justify-content-center gap-2 fs-6"
      >
        <mat-icon class="text-success flex-shrink-0">verified_user</mat-icon
        ><span class="text-center">{{ merchantName() }} is PCI Compliant</span>
      </div>
      <div class="d-none">
        <span>Expires on ***</span>
      </div>
      <div class="flex-fill d-none d-md-block"></div>
      <div
        class="d-flex align-items-center justify-content-between gap-2 mt-2 mt-md-0"
      >
        <button
          (click)="DownloadPCI()"
          class="w-100 d-block mt-2 mt-md-0"
          [color]="'primary'"
          mat-stroked-button
        >
          <div class="d-flex align-items-center justify-content-center gap-2">
            <span>Download</span>
            <mat-icon>file_download</mat-icon>
          </div>
        </button>
      </div>
      <div class="text-center mt-2 mt-md-0">
        <img
          class="w-100 mx-auto text-center d-block"
          style="max-width: 75px"
          src="/assets/images/pci-dss.png"
        />
      </div>
    </div>

    <!-- NON COMPLIANT UI -->
    <div
      *ngIf="!loading && !compliant"
      class="d-block d-md-flex justify-content-between align-items-center gap-3"
    >
      <div
        class="mx-auto mx-md-0 d-flex align-items-center justify-content-center gap-2 fs-6"
      >
        <mat-icon class="text-danger flex-shrink-0">warning</mat-icon
        ><span class="text-center"
          >{{ merchantName() }} is currently not registered for PCI, please
          select manage.</span
        >
      </div>
      <div class="flex-fill d-none d-md-block"></div>
      <div
        class="d-flex align-items-center justify-content-between gap-2 mt-2 mt-md-0"
      >
        <button
          (click)="ReviewPCI()"
          class="w-100 d-block mt-2 mt-md-0"
          [color]="'primary'"
          mat-stroked-button
        >
          <div class="d-flex align-items-center justify-content-center gap-2">
            <span>Manage</span>
            <mat-icon>edit</mat-icon>
          </div>
        </button>
      </div>
      <div class="text-center mt-2 mt-md-0">
        <img
          class="w-100 mx-auto text-center d-block"
          style="max-width: 75px"
          src="/assets/images/pci-dss.png"
        />
      </div>
    </div>

    <!-- LOADING -->
    <odin-loading-spinner
      [active]="loading"
      [overlay]="false"
    ></odin-loading-spinner>
  </mat-card>
</div>
