<div>
  <h1 mat-dialog-title class="mb-0">Alert</h1>
  <div mat-dialog-content>
    <p>{{ data.reason }}</p>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end">
    <button
      class="mt-3 mon-bg-primary mon-border-primary border mon-text-primary"
      mat-flat-button
      (click)="closeDialog()"
    >
      Close
    </button>
  </div>
</div>
