import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AccessGuardService } from './access-guard.service';
import { NotificationService } from '@odin/odin-core';

@Injectable()
export class PEBLGuardService implements CanActivate {
  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private accessService: AccessGuardService,
  ) {}

  private getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map((v) => v.url.map((segment) => segment.toString()).join('/'))
      .join('/');
  }

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.accessService.hasGrant('txn:CreatePebl')) return true;

    this.router.navigate(['/dashboard']);
    this.notificationService.SmallDialog(
      'User does not have access to Pay By Link. Please contact an administrator',
      'Close',
    );
    return false;
  }
}
