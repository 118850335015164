import { Component, Input } from '@angular/core';
import { IAnnoucement } from '../../models/annoucement-data';

@Component({
  selector: 'odin-announcement-display',
  templateUrl: './announcement-display.component.html',
  styleUrls: ['./announcement-display.component.scss'],
})
export class AnnouncementDisplayComponent {
  @Input() messages: IAnnoucement[] = [];

}
