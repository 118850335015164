<div>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="monek-input flush">
    <mat-label>Account</mat-label>
    <mat-select [value]="currentMerchant()" (selectionChange)="optionChanged($event)">
      <mat-option *ngFor="let merchant of allMerchants" [value]="merchant">
        {{ merchant.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
