<div data-test="small-screen-warning" class="small-screen-warning">
  <p>
    Please be aware, this application may not perform properly on this screen
    size. Please use a larger device for the best experience.
  </p>
</div>

<mat-sidenav-container
  autosize
  class="monek-page-container"
  *ngIf="authenticatedView; else NonAuthenticatedView"
>
  <mat-sidenav #sidenav [mode]="sidebarMode" [opened]="burgerMenuOpen">
    <div class="monek-sidenav">
      <div class="sidebar-header">
        <div class="monek-logo d-flex justify-center align-items-center">
          <img class="monek-logo-image me-1" src="/assets/images/logo.png" />
          <span class="monek-font monek-logo-text">MONEK</span>
        </div>
      </div>
      <div class="monek-navigation">
        <ng-container
          *ngFor="let navRoute of navigationService.navigationRoutes"
        >
          <div
            *ngIf="navRoute.enabled && navRoute.hasVisiblesubRoutes()"
            [attr.data-test]="navRoute.safeRoute"
            class="monek-nav-button contrast-hover"
            [class.odin-active]="navRoute.active"
            [class.fw-bold]="navRoute.hasActiveSubRoute()"
            (click)="navigate(navRoute)"
          >
            <div class="monek-nav-button-icon">
              <fa-icon
                [icon]="
                  navRoute.hasSubRoutes()
                    ? navRoute?.advanced?.opened || navRoute.hasActiveSubRoute()
                      ? ddOpen
                      : ddClosed
                    : navRoute.icon
                "
                size="lg"
              ></fa-icon>
            </div>
            <p>{{ navRoute.viewName }}</p>
          </div>

          <ng-container
            *ngIf="
              navRoute.hasSubRoutes() &&
              (navRoute?.advanced?.opened || navRoute.hasActiveSubRoute())
            "
          >
            <div>
              <ng-container
                *ngFor="let subRoute of navRoute?.advanced?.subRoutes"
              >
                <div
                  *ngIf="subRoute.enabled"
                  [attr.data-test]="subRoute.safeRoute"
                  class="monek-nav-button contrast-hover"
                  [class.odin-active]="subRoute.active"
                  (click)="navigate(subRoute)"
                >
                  <div
                    class="d-flex justify-content-center align-items-center w-100"
                  >
                    <div class="monek-nav-button-icon">
                      <!-- <fa-icon [icon]="ddOption"></fa-icon> -->
                    </div>
                    <div
                      class="d-flex justify-content-start align-items-center flex-fill"
                      style="margin-left: 16px"
                    >
                      <div class="monek-nav-button-icon">
                        <fa-icon [icon]="subRoute.icon" size="lg"></fa-icon>
                      </div>
                      <p class="pe-0">{{ subRoute.viewName }}</p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="monek-navigation not-desktop">
        <div class="monek-nav-button no-interact">
          <odin-merchant-picker></odin-merchant-picker>
        </div>
      </div>

      <!-- Remove to center nav -->
      <div style="flex: 1 1 auto"></div>

      <div class="py-3 monek-sidenav-info dark-panel">
        <p>{{ versionNumber | slice: 0 : 11 }}</p>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="monek-page-content app-page-container">
    <div class="site-container">
      <div class="mobile-header main-panel">
        <div class="header-actions">
          <div id="burger-open" class="not-desktop">
            <button mat-icon-button (click)="sidenav.open()">
              <fa-icon [icon]="menuIcon"></fa-icon>
            </button>
          </div>
          <div class="ms-1" id="page-title">
            <p>{{ navigationService.currentRoute.viewName }}</p>
          </div>
        </div>
        <div class="flex-space"></div>
        <div class="header-actions">
          <odin-merchant-picker class="desktop-only"></odin-merchant-picker>
          <!-- user icon -->
          <div id="user-details" *ngIf="user_information$ | async as user">
            <img
              src="{{ user.user_picture }}"
              [matMenuTriggerFor]="userMenu"
              [matMenuTriggerData]="{ data: user }"
            />
          </div>
        </div>
      </div>
      <div class="app-page-container app-content-container page-height">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<ng-template #NonAuthenticatedView>
  <div class="app-page-container not-logged-in app-content-container">
    <router-outlet></router-outlet>
  </div>
</ng-template>

<mat-menu #userMenu="matMenu">
  <ng-template matMenuContent let-aliasMenuItems="data">
    <p mat-menu-item>
      <span>{{ aliasMenuItems.user_full_name }}</span>
    </p>
    <p mat-menu-item>
      <span>{{ aliasMenuItems.user_email }}</span>
    </p>
  </ng-template>
</mat-menu>
