<div class="h-100 flex-grow-1">
  <cdk-virtual-scroll-viewport [itemSize]="rowSize" class="h-100">
    <div *cdkVirtualFor="let month of listOfMonths; let i = index">
      <div
        
        [attr.data-test]="'settlement-item-' + i"
        class="main-panel px-3 py-3 d-flex flex-row justify-content-between align-items-center gap-2"
      >
        <div class="align-self-stretch flex-shrink-0 rounded">
          <div class="d-flex align-items-center justify-content-center h-100">
            <mat-icon>calendar_month</mat-icon>
          </div>
        </div>

        <span
          data-test="transaction-created-date"
          class="flex-grow-1 flex-shrink-0 d-block flex-fill"
          >{{ month | date:'MMMM YYYY' }}</span
        >
        
        <ng-container *ngIf="loading$ | async" >
          <odin-loading-spinner
            *ngIf="selectedMonth === month"
            class="loading-spinner"
            [active]="true"
            [diameter]="20"
            [strokeWidth]="2"
            [colour]="'primary'"
          ></odin-loading-spinner>
        </ng-container>

        <button mat-button [matMenuTriggerFor]="menu" [disabled]="loading$ | async">Download</button>
        <mat-menu #menu="matMenu" xPosition="before">

          <button mat-menu-item class="download-pdf-settlement" (click)="selectedDate(month)">
            <mat-icon matListItemIcon class="flex-shrink-0" id="download-pdf-report-icon">picture_as_pdf</mat-icon>
            <div class="download-pdf-report">Download PDF</div>
          </button>
          
        <button mat-menu-item color="primary" class="download-csv-settlement" (click)="selectedDateCSV(month)">
          <mat-icon matListItemIcon class="download-csv-report-icon flex-shrink-0" id="download-csv-report-icon">download</mat-icon>
          <div class="download-csv-report">Download CSV</div>
        </button>
        </mat-menu>


      </div>
      <mat-divider></mat-divider>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </cdk-virtual-scroll-viewport>
</div>