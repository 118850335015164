<div class="p-1 max-width page-height overflow-auto">
  <mat-card>
    <mat-card-content>
      <ng-container *ngIf="page === 'payment'">
        <ng-container *ngTemplateOutlet="paymentForm"></ng-container>
      </ng-container>

      <ng-container *ngIf="page === 'success'">
        <ng-container *ngTemplateOutlet="paymentSuccess"></ng-container>
      </ng-container>

      <ng-container *ngIf="page === 'failed'">
        <ng-container *ngTemplateOutlet="paymentFail"></ng-container>
      </ng-container>

      <odin-loading-spinner
        [active]="processing"
        [overlay]="true"
      ></odin-loading-spinner>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #paymentForm>
  <odin-dynamic-form [disabledInput]="!GrantChecker()" [formData]="virtualTerminalForm"></odin-dynamic-form>
</ng-template>

<ng-template #paymentSuccess>

  <h2>Payment Status</h2>
  <div class="text-center">
    <div class="position-relative p-3 w-fit m-auto">
      <div
        class="d-flex align-items-center justify-content-center rounded-circle m-auto large-icon text-center"
      >
        <mat-icon style="color: green" class="d-block w-fit h-fit display-1"
          >verified</mat-icon
        >
      </div>
    </div>
    <p class="fw-bold">Your payment was successful!</p>
    <p *ngIf="paymentResponse">
      Authorisation Code: {{ paymentResponse.message }}
    </p>
    <p>Click the button below to print a receipt for this transaction.</p>
    <div class="mt-1 d-flex justify-content-center align-items-center gap-3">
      <button *ngIf="false" mat-mini-fab color="primary">
        <mat-icon>mail</mat-icon>
      </button>

      <button mat-mini-fab class="mon-bg-primary mon-border-primary border mon-text-primary" (click)="PrintReceipt()">
        <mat-icon>print</mat-icon>
      </button>

      <button *ngIf="false" mat-mini-fab color="primary">
        <mat-icon>sms</mat-icon>
      </button>
    </div>

    <div class="mt-3">
      <button mat-raised-button color="accent" (click)="ResetPage(true)">
        Make New Payment
      </button>
    </div>
  </div>

  <!-- Receipt print template -->
  <div *ngIf="lastRequest" #printTemplate>
    <odin-print-receipt [receiptDetail]="receiptDetail"></odin-print-receipt>
  </div>
</ng-template>

<ng-template #paymentFail>
  <h2>Payment Status</h2>
  <div class="text-center">
    <div class="position-relative p-3 w-fit m-auto">
      <div
        class="d-flex align-items-center justify-content-center rounded-circle m-auto large-icon text-center"
      >
        <mat-icon class="d-block w-fit h-fit display-1" style="color: red"
          >cancel</mat-icon
        >
      </div>
    </div>
    <p class="fw-bold">Your payment was unsuccessful!</p>
    <p *ngIf="paymentResponse">Reason: {{ paymentResponse.message }}</p>
    <p *ngIf="paymentResponse">
      Transaction Request ID: {{ paymentResponse.traceId }}
    </p>
    <div
      class="mt-3 mx-auto d-block justify-content-between align-items-center gap-2 d-md-flex"
      style="max-width: 600px"
    >
      <button
        [disabled]="processing || retryCount >= 3"
        mat-raised-button
        color="accent"
        (click)="RetryPayment()"
        class="w-100 my-1 mx-auto"
      >
        {{ retryCount < 3 ? 'Retry Payment' : 'Too many retries' }}
      </button>
      <button
        *ngIf="retryCount < 3"
        [disabled]="processing || retryCount >= 3"
        mat-raised-button
        color="accent"
        (click)="ResetPage(false)"
        class="w-100 my-1 mx-auto"
      >
        {{ retryCount < 3 ? 'Update Payment Details' : 'Too many retries' }}
      </button>
      <button
        [disabled]="processing"
        mat-flat-button
        color="primary"
        (click)="ResetPage(true)"
        class="w-100 my-1 mx-auto"
      >
        Make New Payment
      </button>
    </div>
  </div>
</ng-template>
