import { SettingsService } from '@odin/odin-settings';
import { UserProfile } from '../models/user-profile';
import { BUID } from '../models/BUID';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MerchantService {
  constructor(private settingsService: SettingsService) {}

  public getUserprofile(): UserProfile | null {
    const profileSetting = this.settingsService.GetSetting('profile');
    if (profileSetting === null) return null;
    const profile = JSON.parse(profileSetting.value) as UserProfile;
    return profile;
  }

  public getCurrentMerchant(): BUID | null {
    const merchantRef = localStorage.getItem('current-merchant-ref');
    if (merchantRef == null) return null;
    const authoMerchant = JSON.parse(merchantRef) as BUID;
    return authoMerchant;
  }

  public getCurrentMerchantName(): string | null {
    const merchantRef = localStorage.getItem('current-merchant-ref');
    if (merchantRef == null) return null;
    const authoMerchant = JSON.parse(merchantRef) as BUID;
    return authoMerchant.name;
  }

  public getMerchantRef(): string {
    const merchantRef = localStorage.getItem('current-merchant-ref');
    if (merchantRef == null) return '';
    const authoMerchant = JSON.parse(merchantRef) as BUID;
    return authoMerchant ? authoMerchant.id : '';
  }
}
