<mat-drawer-container [hasBackdrop]="tabletQuery.matches" class="page-height">
  <mat-drawer
    #drawer
    [mode]="tabletQuery.matches ? 'over' : 'side'"
    [position]="'end'"
    [opened]="!tabletQuery.matches"
    [disableClose]="!tabletQuery.matches"
  >
    <!-- Actions in sidenav -->
    <div
      *ngIf="transactionFilters$ | async as transactionFilters"
      class="h-100"
    >
      <odin-transactions-filters-form
        [transactionFiltersInitial]="transactionFilters"
        (transactionFiltersChanged)="filterTransactions($event)"
        (csvReport)="csvReportsDownload($event)"
      >
      </odin-transactions-filters-form>
    </div>
  </mat-drawer>
  <mat-drawer-content
    class="ml-0 bg-panel page-height d-flex justify-content-between align-items-center flex-column p-md-2"
  >
    <!-- Transaction list header -->
    <mat-divider></mat-divider>
    <div
      class="d-flex justify-content-between align-items-center flex-row py-3 px-4 w-100 shadow main-panel"
      style="z-index: 10"
    >
      <button
        mat-icon-button
        class="bg-white-always mon-border-primary border mon-text-primary"
        (click)="refreshTransactions()"
        data-test="transactions-refresh-btn"
      >
        <mat-icon>refresh</mat-icon>
      </button>

      <div class="flex-fill">
        <odin-search-bar
          *ngIf="false"
          [inputValue]="(searchInputValue$ | async) || ''"
          [inputPlaceholder]="searchControlPlaceholder"
          (outputValue)="searchTransactions($event)"
        >
        </odin-search-bar>
      </div>

      <button
        class=""
        mat-icon-button
        class="bg-white-always mon-border-primary border mon-text-primary"
        (click)="drawer.open()"
        [disabled]="!tabletQuery.matches"
        data-test="transactions-filters-btn"
      >
        <mat-icon>filter_alt</mat-icon>
      </button>
    </div>

    <!-- Transaction list -->
    <div class="flex-fill w-100 overflow-hidden">
      <div
        *ngIf="error$ | async; else transactions_content"
        class="error-transactions"
      >
        Unable to load transactions
        <!-- TODO: Better error message? -->
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<ng-template #transactions_content>
  <div
    class="h-100"
    *ngIf="transactions$ | async as transactions"
    @fadeAnimation
  >
    <ng-container *ngIf="transactions.length > 0">
      <odin-transactions-list
        class="d-block h-100"
        [refresh]="refreshEvent"
        [transactions]="transactions"
        [allowSelection]="allowSelection$ | async"
        [hasNext]="hasNext$ | async"
        [hasPrev]="hasPrev$ | async"
        [loading]="loading$ | async"
        (scrollDirection)="loadTransactionsOnScroll($event)"
      >
        <ng-container *ngIf="noMoreToLoad$ | async">
          <div>
            <div style="max-width: 300px" class="w-50 mx-auto p-1">
              <p class="mb-0 w-100 text-center">
                No more transactions to load.
              </p>
            </div>
          </div>
        </ng-container>
      </odin-transactions-list>
    </ng-container>
    <ng-container *ngIf="transactions.length === 0 && (loading$ | async) === false">
      <p class="w-100 text-center">No transactions found!</p>
    </ng-container>
    <ng-container *ngIf="loading$ | async">
      <odin-loading-spinner
        class="mt-2"
        [active]="true"
        [diameter]="40"
        [colour]="colour"
      ></odin-loading-spinner>
    </ng-container>
  </div>
</ng-template>
