import { CognitoUserSession } from 'amazon-cognito-identity-js';

export class SignInSession {
  public id_token: string = '';
  public access_token: string = '';
  public refresh_token: string = '';
  public expires_in: number = 0;
  public token_type: string = '';

  public FromCognito(userSession: CognitoUserSession): SignInSession {
    this.id_token = userSession.getIdToken().getJwtToken();
    this.access_token = userSession.getAccessToken().getJwtToken();
    this.refresh_token = userSession.getRefreshToken().getToken();
    this.token_type = 'Bearer';
    return this;
  }
}
