import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent } from '../auth-callback/auth-callback.component';
import { LoginPageComponent } from '../login-page/login-page.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/authentication/login' },
  { path: 'login', component: LoginPageComponent },
  { path: 'auth-callback', component: AuthCallbackComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OdinAuthenticationRoutingModule {}
