import {
  LineChartData,
  LineChartNode,
  LineChartSeriesItem,
} from './line-chart-data';
import { BarChartData, BarChartSeriesItem } from './bar-chart-data';
import {
  MonekChartData,
  MonekChartDataPoint,
  MonekChartNode,
} from './monek-chart-data';
import { ChartType } from './chart-options';

export class ChartData {
  private lineData: LineChartData = new LineChartData();
  private barData: BarChartData = new BarChartData();

  constructor(private monekData: MonekChartData) {
    this.generateData(this.monekData);
  }

  private generateData(monekData: MonekChartData): void {
    this.generateLineData(monekData);
    this.generateBarData(monekData);
  }

  private generateLineData(monekData: MonekChartData): void {
    const _lineData: LineChartData = new LineChartData();
    monekData.data.forEach((chartNode: MonekChartNode) => {
      const _lineChartSeries: LineChartSeriesItem[] = [];
      chartNode.series.forEach((chartNodeValue: MonekChartDataPoint) => {
        _lineChartSeries.push(
          new LineChartSeriesItem(chartNodeValue.name, chartNodeValue.value),
        );
      });
      _lineData.data.push(new LineChartNode(chartNode.key, _lineChartSeries));
    });

    this.lineData = _lineData;
  }

  private generateBarData(monekData: MonekChartData): void {
    const _barData: BarChartData = new BarChartData();
    monekData.data.forEach((chartNode: MonekChartNode) => {
      let endValue = 0;
      chartNode.series.forEach((chartNodeValue: MonekChartDataPoint) => {
        endValue += chartNodeValue.value;
      });
      _barData.data.push(new BarChartSeriesItem(chartNode.key, endValue));
    });
    this.barData = _barData;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getData(chartType: ChartType): any {
    if (
      chartType == ChartType.LineChart ||
      chartType == ChartType.NormalizedAreaLineChart ||
      chartType == ChartType.StackedAreaChart ||
      chartType == ChartType.AreaLineChart ||
      chartType == ChartType.StackedVerticalBarChart ||
      chartType == ChartType.GroupedVerticalBarChart
    ) {
      return this.lineData.data;
    } else {
      return this.barData.data;
    }
  }
}
